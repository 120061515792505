.grid {
  flex-wrap: wrap;
  margin-top: -.5rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.grid > .col, .grid > [class*="col"] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.grid-nogutter > .col, .grid-nogutter > [class*="col-"] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: .5rem;
}

.col-fixed {
  flex: none;
  padding: .5rem;
}

.col-1 {
  width: 8.3333%;
  flex: none;
  padding: .5rem;
}

.col-2 {
  width: 16.6667%;
  flex: none;
  padding: .5rem;
}

.col-3 {
  width: 25%;
  flex: none;
  padding: .5rem;
}

.col-4 {
  width: 33.3333%;
  flex: none;
  padding: .5rem;
}

.col-5 {
  width: 41.6667%;
  flex: none;
  padding: .5rem;
}

.col-6 {
  width: 50%;
  flex: none;
  padding: .5rem;
}

.col-7 {
  width: 58.3333%;
  flex: none;
  padding: .5rem;
}

.col-8 {
  width: 66.6667%;
  flex: none;
  padding: .5rem;
}

.col-9 {
  width: 75%;
  flex: none;
  padding: .5rem;
}

.col-10 {
  width: 83.3333%;
  flex: none;
  padding: .5rem;
}

.col-11 {
  width: 91.6667%;
  flex: none;
  padding: .5rem;
}

.col-12 {
  width: 100%;
  flex: none;
  padding: .5rem;
}

@media screen and (min-width: 576px) {
  .sm\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .sm\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .sm\:col-1 {
    width: 8.3333%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-2 {
    width: 16.6667%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-3 {
    width: 25%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-4 {
    width: 33.3333%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-5 {
    width: 41.6667%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-6 {
    width: 50%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-7 {
    width: 58.3333%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-8 {
    width: 66.6667%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-9 {
    width: 75%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-10 {
    width: 83.3333%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-11 {
    width: 91.6667%;
    flex: none;
    padding: .5rem;
  }

  .sm\:col-12 {
    width: 100%;
    flex: none;
    padding: .5rem;
  }
}

@media screen and (min-width: 768px) {
  .md\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .md\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .md\:col-1 {
    width: 8.3333%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-2 {
    width: 16.6667%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-3 {
    width: 25%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-4 {
    width: 33.3333%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-5 {
    width: 41.6667%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-6 {
    width: 50%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-7 {
    width: 58.3333%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-8 {
    width: 66.6667%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-9 {
    width: 75%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-10 {
    width: 83.3333%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-11 {
    width: 91.6667%;
    flex: none;
    padding: .5rem;
  }

  .md\:col-12 {
    width: 100%;
    flex: none;
    padding: .5rem;
  }
}

@media screen and (min-width: 992px) {
  .lg\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .lg\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .lg\:col-1 {
    width: 8.3333%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-2 {
    width: 16.6667%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-3 {
    width: 25%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-4 {
    width: 33.3333%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-5 {
    width: 41.6667%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-6 {
    width: 50%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-7 {
    width: 58.3333%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-8 {
    width: 66.6667%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-9 {
    width: 75%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-10 {
    width: 83.3333%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-11 {
    width: 91.6667%;
    flex: none;
    padding: .5rem;
  }

  .lg\:col-12 {
    width: 100%;
    flex: none;
    padding: .5rem;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .xl\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .xl\:col-1 {
    width: 8.3333%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-2 {
    width: 16.6667%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-3 {
    width: 25%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-4 {
    width: 33.3333%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-5 {
    width: 41.6667%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-6 {
    width: 50%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-7 {
    width: 58.3333%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-8 {
    width: 66.6667%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-9 {
    width: 75%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-10 {
    width: 83.3333%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-11 {
    width: 91.6667%;
    flex: none;
    padding: .5rem;
  }

  .xl\:col-12 {
    width: 100%;
    flex: none;
    padding: .5rem;
  }
}

.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }

  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }

  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }

  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }

  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }

  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .md\:col-offset-3 {
    margin-left: 25% !important;
  }

  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .md\:col-offset-6 {
    margin-left: 50% !important;
  }

  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .md\:col-offset-9 {
    margin-left: 75% !important;
  }

  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }

  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }

  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }

  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }

  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }

  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }

  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }

  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }

  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}

.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus, .hover\:text-0:hover, .active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus, .hover\:text-50:hover, .active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus, .hover\:text-100:hover, .active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus, .hover\:text-200:hover, .active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus, .hover\:text-300:hover, .active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus, .hover\:text-400:hover, .active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus, .hover\:text-500:hover, .active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus, .hover\:text-600:hover, .active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus, .hover\:text-700:hover, .active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus, .hover\:text-800:hover, .active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus, .hover\:text-900:hover, .active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus, .hover\:surface-0:hover, .active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus, .hover\:surface-50:hover, .active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus, .hover\:surface-100:hover, .active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus, .hover\:surface-200:hover, .active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus, .hover\:surface-300:hover, .active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus, .hover\:surface-400:hover, .active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus, .hover\:surface-500:hover, .active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus, .hover\:surface-600:hover, .active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus, .hover\:surface-700:hover, .active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus, .hover\:surface-800:hover, .active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus, .hover\:surface-900:hover, .active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus, .hover\:border-0:hover, .active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus, .hover\:border-50:hover, .active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus, .hover\:border-100:hover, .active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus, .hover\:border-200:hover, .active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus, .hover\:border-300:hover, .active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus, .hover\:border-400:hover, .active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus, .hover\:border-500:hover, .active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus, .hover\:border-600:hover, .active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus, .hover\:border-700:hover, .active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus, .hover\:border-800:hover, .active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus, .hover\:border-900:hover, .active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: #0000 !important;
  }
}

.border-transparent {
  border-color: #0000 !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:border-transparent {
    border-color: #0000 !important;
  }
}

.text-blue-50 {
  color: var(--blue-50) !important;
}

.text-blue-100 {
  color: var(--blue-100) !important;
}

.text-blue-200 {
  color: var(--blue-200) !important;
}

.text-blue-300 {
  color: var(--blue-300) !important;
}

.text-blue-400 {
  color: var(--blue-400) !important;
}

.text-blue-500 {
  color: var(--blue-500) !important;
}

.text-blue-600 {
  color: var(--blue-600) !important;
}

.text-blue-700 {
  color: var(--blue-700) !important;
}

.text-blue-800 {
  color: var(--blue-800) !important;
}

.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}

.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}

.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}

.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}

.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}

.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}

.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}

.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}

.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}

.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}

.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}

.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}

.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}

.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}

.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}

.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}

.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}

.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}

.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}

.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}

.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}

.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}

.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}

.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}

.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}

.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}

.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}

.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}

.text-green-100 {
  color: var(--green-100) !important;
}

.text-green-200 {
  color: var(--green-200) !important;
}

.text-green-300 {
  color: var(--green-300) !important;
}

.text-green-400 {
  color: var(--green-400) !important;
}

.text-green-500 {
  color: var(--green-500) !important;
}

.text-green-600 {
  color: var(--green-600) !important;
}

.text-green-700 {
  color: var(--green-700) !important;
}

.text-green-800 {
  color: var(--green-800) !important;
}

.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}

.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}

.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}

.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}

.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}

.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}

.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}

.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}

.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}

.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}

.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}

.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}

.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}

.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}

.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}

.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}

.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}

.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}

.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}

.active\:text-green-100:active {
  color: var(--green-100) !important;
}

.active\:text-green-200:active {
  color: var(--green-200) !important;
}

.active\:text-green-300:active {
  color: var(--green-300) !important;
}

.active\:text-green-400:active {
  color: var(--green-400) !important;
}

.active\:text-green-500:active {
  color: var(--green-500) !important;
}

.active\:text-green-600:active {
  color: var(--green-600) !important;
}

.active\:text-green-700:active {
  color: var(--green-700) !important;
}

.active\:text-green-800:active {
  color: var(--green-800) !important;
}

.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}

.text-yellow-100 {
  color: var(--yellow-100) !important;
}

.text-yellow-200 {
  color: var(--yellow-200) !important;
}

.text-yellow-300 {
  color: var(--yellow-300) !important;
}

.text-yellow-400 {
  color: var(--yellow-400) !important;
}

.text-yellow-500 {
  color: var(--yellow-500) !important;
}

.text-yellow-600 {
  color: var(--yellow-600) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.text-yellow-800 {
  color: var(--yellow-800) !important;
}

.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}

.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}

.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}

.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}

.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}

.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}

.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}

.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}

.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}

.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}

.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}

.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}

.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}

.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}

.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}

.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}

.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}

.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}

.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}

.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}

.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}

.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}

.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}

.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}

.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}

.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}

.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}

.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}

.text-cyan-100 {
  color: var(--cyan-100) !important;
}

.text-cyan-200 {
  color: var(--cyan-200) !important;
}

.text-cyan-300 {
  color: var(--cyan-300) !important;
}

.text-cyan-400 {
  color: var(--cyan-400) !important;
}

.text-cyan-500 {
  color: var(--cyan-500) !important;
}

.text-cyan-600 {
  color: var(--cyan-600) !important;
}

.text-cyan-700 {
  color: var(--cyan-700) !important;
}

.text-cyan-800 {
  color: var(--cyan-800) !important;
}

.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}

.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}

.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}

.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}

.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}

.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}

.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}

.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}

.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}

.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}

.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}

.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}

.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}

.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}

.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}

.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}

.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}

.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}

.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}

.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}

.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}

.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}

.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}

.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}

.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}

.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}

.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}

.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}

.text-pink-100 {
  color: var(--pink-100) !important;
}

.text-pink-200 {
  color: var(--pink-200) !important;
}

.text-pink-300 {
  color: var(--pink-300) !important;
}

.text-pink-400 {
  color: var(--pink-400) !important;
}

.text-pink-500 {
  color: var(--pink-500) !important;
}

.text-pink-600 {
  color: var(--pink-600) !important;
}

.text-pink-700 {
  color: var(--pink-700) !important;
}

.text-pink-800 {
  color: var(--pink-800) !important;
}

.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}

.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}

.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}

.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}

.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}

.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}

.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}

.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}

.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}

.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}

.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}

.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}

.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}

.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}

.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}

.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}

.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}

.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}

.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}

.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}

.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}

.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}

.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}

.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}

.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}

.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}

.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}

.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}

.text-indigo-100 {
  color: var(--indigo-100) !important;
}

.text-indigo-200 {
  color: var(--indigo-200) !important;
}

.text-indigo-300 {
  color: var(--indigo-300) !important;
}

.text-indigo-400 {
  color: var(--indigo-400) !important;
}

.text-indigo-500 {
  color: var(--indigo-500) !important;
}

.text-indigo-600 {
  color: var(--indigo-600) !important;
}

.text-indigo-700 {
  color: var(--indigo-700) !important;
}

.text-indigo-800 {
  color: var(--indigo-800) !important;
}

.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}

.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}

.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}

.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}

.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}

.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}

.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}

.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}

.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}

.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}

.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}

.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}

.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}

.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}

.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}

.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}

.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}

.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}

.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}

.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}

.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}

.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}

.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}

.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}

.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}

.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}

.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}

.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}

.text-teal-100 {
  color: var(--teal-100) !important;
}

.text-teal-200 {
  color: var(--teal-200) !important;
}

.text-teal-300 {
  color: var(--teal-300) !important;
}

.text-teal-400 {
  color: var(--teal-400) !important;
}

.text-teal-500 {
  color: var(--teal-500) !important;
}

.text-teal-600 {
  color: var(--teal-600) !important;
}

.text-teal-700 {
  color: var(--teal-700) !important;
}

.text-teal-800 {
  color: var(--teal-800) !important;
}

.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}

.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}

.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}

.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}

.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}

.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}

.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}

.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}

.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}

.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}

.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}

.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}

.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}

.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}

.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}

.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}

.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}

.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}

.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}

.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}

.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}

.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}

.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}

.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}

.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}

.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}

.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}

.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}

.text-orange-100 {
  color: var(--orange-100) !important;
}

.text-orange-200 {
  color: var(--orange-200) !important;
}

.text-orange-300 {
  color: var(--orange-300) !important;
}

.text-orange-400 {
  color: var(--orange-400) !important;
}

.text-orange-500 {
  color: var(--orange-500) !important;
}

.text-orange-600 {
  color: var(--orange-600) !important;
}

.text-orange-700 {
  color: var(--orange-700) !important;
}

.text-orange-800 {
  color: var(--orange-800) !important;
}

.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}

.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}

.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}

.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}

.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}

.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}

.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}

.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}

.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}

.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}

.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}

.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}

.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}

.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}

.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}

.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}

.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}

.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}

.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}

.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}

.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}

.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}

.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}

.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}

.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}

.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}

.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}

.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}

.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}

.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}

.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}

.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}

.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}

.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}

.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}

.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}

.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}

.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}

.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}

.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}

.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}

.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}

.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}

.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}

.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}

.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}

.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}

.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}

.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}

.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}

.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}

.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}

.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}

.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}

.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}

.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}

.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}

.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}

.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}

.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}

.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}

.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}

.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}

.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}

.text-purple-100 {
  color: var(--purple-100) !important;
}

.text-purple-200 {
  color: var(--purple-200) !important;
}

.text-purple-300 {
  color: var(--purple-300) !important;
}

.text-purple-400 {
  color: var(--purple-400) !important;
}

.text-purple-500 {
  color: var(--purple-500) !important;
}

.text-purple-600 {
  color: var(--purple-600) !important;
}

.text-purple-700 {
  color: var(--purple-700) !important;
}

.text-purple-800 {
  color: var(--purple-800) !important;
}

.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}

.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}

.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}

.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}

.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}

.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}

.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}

.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}

.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}

.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}

.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}

.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}

.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}

.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}

.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}

.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}

.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}

.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}

.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}

.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}

.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}

.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}

.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}

.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}

.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}

.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}

.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}

.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}

.text-gray-100 {
  color: var(--gray-100) !important;
}

.text-gray-200 {
  color: var(--gray-200) !important;
}

.text-gray-300 {
  color: var(--gray-300) !important;
}

.text-gray-400 {
  color: var(--gray-400) !important;
}

.text-gray-500 {
  color: var(--gray-500) !important;
}

.text-gray-600 {
  color: var(--gray-600) !important;
}

.text-gray-700 {
  color: var(--gray-700) !important;
}

.text-gray-800 {
  color: var(--gray-800) !important;
}

.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}

.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}

.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}

.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}

.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}

.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}

.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}

.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}

.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}

.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}

.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}

.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}

.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}

.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}

.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}

.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}

.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}

.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}

.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}

.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}

.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}

.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}

.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}

.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}

.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}

.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}

.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}

.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}

.text-red-100 {
  color: var(--red-100) !important;
}

.text-red-200 {
  color: var(--red-200) !important;
}

.text-red-300 {
  color: var(--red-300) !important;
}

.text-red-400 {
  color: var(--red-400) !important;
}

.text-red-500 {
  color: var(--red-500) !important;
}

.text-red-600 {
  color: var(--red-600) !important;
}

.text-red-700 {
  color: var(--red-700) !important;
}

.text-red-800 {
  color: var(--red-800) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.focus\:text-red-50:focus {
  color: var(--red-50) !important;
}

.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}

.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}

.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}

.focus\:text-red-400:focus {
  color: var(--red-400) !important;
}

.focus\:text-red-500:focus {
  color: var(--red-500) !important;
}

.focus\:text-red-600:focus {
  color: var(--red-600) !important;
}

.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}

.focus\:text-red-800:focus {
  color: var(--red-800) !important;
}

.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-50:hover {
  color: var(--red-50) !important;
}

.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}

.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}

.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}

.hover\:text-red-400:hover {
  color: var(--red-400) !important;
}

.hover\:text-red-500:hover {
  color: var(--red-500) !important;
}

.hover\:text-red-600:hover {
  color: var(--red-600) !important;
}

.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}

.hover\:text-red-800:hover {
  color: var(--red-800) !important;
}

.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-50:active {
  color: var(--red-50) !important;
}

.active\:text-red-100:active {
  color: var(--red-100) !important;
}

.active\:text-red-200:active {
  color: var(--red-200) !important;
}

.active\:text-red-300:active {
  color: var(--red-300) !important;
}

.active\:text-red-400:active {
  color: var(--red-400) !important;
}

.active\:text-red-500:active {
  color: var(--red-500) !important;
}

.active\:text-red-600:active {
  color: var(--red-600) !important;
}

.active\:text-red-700:active {
  color: var(--red-700) !important;
}

.active\:text-red-800:active {
  color: var(--red-800) !important;
}

.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}

.text-primary-100 {
  color: var(--primary-100) !important;
}

.text-primary-200 {
  color: var(--primary-200) !important;
}

.text-primary-300 {
  color: var(--primary-300) !important;
}

.text-primary-400 {
  color: var(--primary-400) !important;
}

.text-primary-500 {
  color: var(--primary-500) !important;
}

.text-primary-600 {
  color: var(--primary-600) !important;
}

.text-primary-700 {
  color: var(--primary-700) !important;
}

.text-primary-800 {
  color: var(--primary-800) !important;
}

.text-primary-900 {
  color: var(--primary-900) !important;
}

.focus\:text-primary-50:focus {
  color: var(--primary-50) !important;
}

.focus\:text-primary-100:focus {
  color: var(--primary-100) !important;
}

.focus\:text-primary-200:focus {
  color: var(--primary-200) !important;
}

.focus\:text-primary-300:focus {
  color: var(--primary-300) !important;
}

.focus\:text-primary-400:focus {
  color: var(--primary-400) !important;
}

.focus\:text-primary-500:focus {
  color: var(--primary-500) !important;
}

.focus\:text-primary-600:focus {
  color: var(--primary-600) !important;
}

.focus\:text-primary-700:focus {
  color: var(--primary-700) !important;
}

.focus\:text-primary-800:focus {
  color: var(--primary-800) !important;
}

.focus\:text-primary-900:focus {
  color: var(--primary-900) !important;
}

.hover\:text-primary-50:hover {
  color: var(--primary-50) !important;
}

.hover\:text-primary-100:hover {
  color: var(--primary-100) !important;
}

.hover\:text-primary-200:hover {
  color: var(--primary-200) !important;
}

.hover\:text-primary-300:hover {
  color: var(--primary-300) !important;
}

.hover\:text-primary-400:hover {
  color: var(--primary-400) !important;
}

.hover\:text-primary-500:hover {
  color: var(--primary-500) !important;
}

.hover\:text-primary-600:hover {
  color: var(--primary-600) !important;
}

.hover\:text-primary-700:hover {
  color: var(--primary-700) !important;
}

.hover\:text-primary-800:hover {
  color: var(--primary-800) !important;
}

.hover\:text-primary-900:hover {
  color: var(--primary-900) !important;
}

.active\:text-primary-50:active {
  color: var(--primary-50) !important;
}

.active\:text-primary-100:active {
  color: var(--primary-100) !important;
}

.active\:text-primary-200:active {
  color: var(--primary-200) !important;
}

.active\:text-primary-300:active {
  color: var(--primary-300) !important;
}

.active\:text-primary-400:active {
  color: var(--primary-400) !important;
}

.active\:text-primary-500:active {
  color: var(--primary-500) !important;
}

.active\:text-primary-600:active {
  color: var(--primary-600) !important;
}

.active\:text-primary-700:active {
  color: var(--primary-700) !important;
}

.active\:text-primary-800:active {
  color: var(--primary-800) !important;
}

.active\:text-primary-900:active {
  color: var(--primary-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}

.bg-blue-100 {
  background-color: var(--blue-100) !important;
}

.bg-blue-200 {
  background-color: var(--blue-200) !important;
}

.bg-blue-300 {
  background-color: var(--blue-300) !important;
}

.bg-blue-400 {
  background-color: var(--blue-400) !important;
}

.bg-blue-500 {
  background-color: var(--blue-500) !important;
}

.bg-blue-600 {
  background-color: var(--blue-600) !important;
}

.bg-blue-700 {
  background-color: var(--blue-700) !important;
}

.bg-blue-800 {
  background-color: var(--blue-800) !important;
}

.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}

.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}

.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}

.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}

.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}

.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}

.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}

.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}

.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}

.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}

.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}

.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}

.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}

.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}

.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}

.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}

.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}

.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}

.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}

.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}

.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}

.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}

.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}

.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}

.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}

.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}

.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}

.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.bg-green-200 {
  background-color: var(--green-200) !important;
}

.bg-green-300 {
  background-color: var(--green-300) !important;
}

.bg-green-400 {
  background-color: var(--green-400) !important;
}

.bg-green-500 {
  background-color: var(--green-500) !important;
}

.bg-green-600 {
  background-color: var(--green-600) !important;
}

.bg-green-700 {
  background-color: var(--green-700) !important;
}

.bg-green-800 {
  background-color: var(--green-800) !important;
}

.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}

.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}

.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}

.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}

.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}

.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}

.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}

.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}

.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}

.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}

.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}

.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}

.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}

.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}

.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}

.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}

.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}

.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}

.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}

.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}

.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}

.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}

.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}

.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}

.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}

.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}

.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}

.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}

.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}

.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}

.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}

.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}

.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}

.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}

.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}

.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}

.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}

.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}

.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}

.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}

.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}

.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}

.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}

.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}

.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}

.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}

.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}

.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}

.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}

.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}

.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}

.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}

.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}

.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}

.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}

.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}

.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}

.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}

.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}

.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}

.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}

.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}

.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}

.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}

.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}

.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}

.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}

.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}

.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}

.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}

.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}

.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}

.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}

.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}

.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}

.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}

.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}

.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}

.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}

.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}

.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}

.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}

.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}

.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}

.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}

.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}

.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}

.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}

.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}

.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}

.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}

.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}

.bg-pink-100 {
  background-color: var(--pink-100) !important;
}

.bg-pink-200 {
  background-color: var(--pink-200) !important;
}

.bg-pink-300 {
  background-color: var(--pink-300) !important;
}

.bg-pink-400 {
  background-color: var(--pink-400) !important;
}

.bg-pink-500 {
  background-color: var(--pink-500) !important;
}

.bg-pink-600 {
  background-color: var(--pink-600) !important;
}

.bg-pink-700 {
  background-color: var(--pink-700) !important;
}

.bg-pink-800 {
  background-color: var(--pink-800) !important;
}

.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}

.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}

.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}

.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}

.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}

.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}

.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}

.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}

.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}

.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}

.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}

.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}

.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}

.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}

.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}

.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}

.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}

.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}

.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}

.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}

.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}

.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}

.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}

.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}

.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}

.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}

.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}

.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}

.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}

.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}

.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}

.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}

.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}

.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}

.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}

.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}

.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}

.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}

.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}

.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}

.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}

.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}

.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}

.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}

.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}

.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}

.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}

.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}

.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}

.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}

.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}

.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}

.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}

.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}

.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}

.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}

.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}

.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}

.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}

.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}

.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}

.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}

.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}

.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}

.bg-teal-100 {
  background-color: var(--teal-100) !important;
}

.bg-teal-200 {
  background-color: var(--teal-200) !important;
}

.bg-teal-300 {
  background-color: var(--teal-300) !important;
}

.bg-teal-400 {
  background-color: var(--teal-400) !important;
}

.bg-teal-500 {
  background-color: var(--teal-500) !important;
}

.bg-teal-600 {
  background-color: var(--teal-600) !important;
}

.bg-teal-700 {
  background-color: var(--teal-700) !important;
}

.bg-teal-800 {
  background-color: var(--teal-800) !important;
}

.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}

.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}

.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}

.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}

.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}

.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}

.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}

.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}

.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}

.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}

.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}

.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}

.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}

.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}

.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}

.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}

.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}

.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}

.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}

.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}

.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}

.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}

.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}

.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}

.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}

.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}

.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}

.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}

.bg-orange-100 {
  background-color: var(--orange-100) !important;
}

.bg-orange-200 {
  background-color: var(--orange-200) !important;
}

.bg-orange-300 {
  background-color: var(--orange-300) !important;
}

.bg-orange-400 {
  background-color: var(--orange-400) !important;
}

.bg-orange-500 {
  background-color: var(--orange-500) !important;
}

.bg-orange-600 {
  background-color: var(--orange-600) !important;
}

.bg-orange-700 {
  background-color: var(--orange-700) !important;
}

.bg-orange-800 {
  background-color: var(--orange-800) !important;
}

.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}

.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}

.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}

.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}

.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}

.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}

.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}

.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}

.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}

.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}

.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}

.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}

.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}

.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}

.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}

.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}

.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}

.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}

.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}

.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}

.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}

.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}

.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}

.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}

.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}

.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}

.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}

.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}

.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}

.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}

.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}

.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}

.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}

.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}

.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}

.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}

.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}

.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}

.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}

.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}

.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}

.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}

.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}

.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}

.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}

.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}

.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}

.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}

.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}

.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}

.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}

.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}

.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}

.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}

.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}

.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}

.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}

.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}

.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}

.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}

.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}

.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}

.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}

.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}

.bg-purple-100 {
  background-color: var(--purple-100) !important;
}

.bg-purple-200 {
  background-color: var(--purple-200) !important;
}

.bg-purple-300 {
  background-color: var(--purple-300) !important;
}

.bg-purple-400 {
  background-color: var(--purple-400) !important;
}

.bg-purple-500 {
  background-color: var(--purple-500) !important;
}

.bg-purple-600 {
  background-color: var(--purple-600) !important;
}

.bg-purple-700 {
  background-color: var(--purple-700) !important;
}

.bg-purple-800 {
  background-color: var(--purple-800) !important;
}

.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}

.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}

.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}

.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}

.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}

.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}

.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}

.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}

.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}

.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}

.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}

.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}

.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}

.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}

.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}

.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}

.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}

.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}

.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}

.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}

.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}

.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}

.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}

.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}

.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}

.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}

.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}

.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}

.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}

.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}

.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}

.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}

.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}

.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}

.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}

.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}

.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}

.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}

.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}

.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}

.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}

.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}

.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}

.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}

.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}

.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}

.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}

.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}

.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}

.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}

.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}

.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}

.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}

.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}

.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.bg-red-400 {
  background-color: var(--red-400) !important;
}

.bg-red-500 {
  background-color: var(--red-500) !important;
}

.bg-red-600 {
  background-color: var(--red-600) !important;
}

.bg-red-700 {
  background-color: var(--red-700) !important;
}

.bg-red-800 {
  background-color: var(--red-800) !important;
}

.bg-red-900 {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-50:focus {
  background-color: var(--red-50) !important;
}

.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}

.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}

.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}

.focus\:bg-red-400:focus {
  background-color: var(--red-400) !important;
}

.focus\:bg-red-500:focus {
  background-color: var(--red-500) !important;
}

.focus\:bg-red-600:focus {
  background-color: var(--red-600) !important;
}

.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}

.focus\:bg-red-800:focus {
  background-color: var(--red-800) !important;
}

.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-50:hover {
  background-color: var(--red-50) !important;
}

.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}

.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}

.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}

.hover\:bg-red-400:hover {
  background-color: var(--red-400) !important;
}

.hover\:bg-red-500:hover {
  background-color: var(--red-500) !important;
}

.hover\:bg-red-600:hover {
  background-color: var(--red-600) !important;
}

.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}

.hover\:bg-red-800:hover {
  background-color: var(--red-800) !important;
}

.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-50:active {
  background-color: var(--red-50) !important;
}

.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}

.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}

.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}

.active\:bg-red-400:active {
  background-color: var(--red-400) !important;
}

.active\:bg-red-500:active {
  background-color: var(--red-500) !important;
}

.active\:bg-red-600:active {
  background-color: var(--red-600) !important;
}

.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}

.active\:bg-red-800:active {
  background-color: var(--red-800) !important;
}

.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.bg-primary-50 {
  background-color: var(--primary-50) !important;
}

.bg-primary-100 {
  background-color: var(--primary-100) !important;
}

.bg-primary-200 {
  background-color: var(--primary-200) !important;
}

.bg-primary-300 {
  background-color: var(--primary-300) !important;
}

.bg-primary-400 {
  background-color: var(--primary-400) !important;
}

.bg-primary-500 {
  background-color: var(--primary-500) !important;
}

.bg-primary-600 {
  background-color: var(--primary-600) !important;
}

.bg-primary-700 {
  background-color: var(--primary-700) !important;
}

.bg-primary-800 {
  background-color: var(--primary-800) !important;
}

.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.focus\:bg-primary-50:focus {
  background-color: var(--primary-50) !important;
}

.focus\:bg-primary-100:focus {
  background-color: var(--primary-100) !important;
}

.focus\:bg-primary-200:focus {
  background-color: var(--primary-200) !important;
}

.focus\:bg-primary-300:focus {
  background-color: var(--primary-300) !important;
}

.focus\:bg-primary-400:focus {
  background-color: var(--primary-400) !important;
}

.focus\:bg-primary-500:focus {
  background-color: var(--primary-500) !important;
}

.focus\:bg-primary-600:focus {
  background-color: var(--primary-600) !important;
}

.focus\:bg-primary-700:focus {
  background-color: var(--primary-700) !important;
}

.focus\:bg-primary-800:focus {
  background-color: var(--primary-800) !important;
}

.focus\:bg-primary-900:focus {
  background-color: var(--primary-900) !important;
}

.hover\:bg-primary-50:hover {
  background-color: var(--primary-50) !important;
}

.hover\:bg-primary-100:hover {
  background-color: var(--primary-100) !important;
}

.hover\:bg-primary-200:hover {
  background-color: var(--primary-200) !important;
}

.hover\:bg-primary-300:hover {
  background-color: var(--primary-300) !important;
}

.hover\:bg-primary-400:hover {
  background-color: var(--primary-400) !important;
}

.hover\:bg-primary-500:hover {
  background-color: var(--primary-500) !important;
}

.hover\:bg-primary-600:hover {
  background-color: var(--primary-600) !important;
}

.hover\:bg-primary-700:hover {
  background-color: var(--primary-700) !important;
}

.hover\:bg-primary-800:hover {
  background-color: var(--primary-800) !important;
}

.hover\:bg-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.active\:bg-primary-50:active {
  background-color: var(--primary-50) !important;
}

.active\:bg-primary-100:active {
  background-color: var(--primary-100) !important;
}

.active\:bg-primary-200:active {
  background-color: var(--primary-200) !important;
}

.active\:bg-primary-300:active {
  background-color: var(--primary-300) !important;
}

.active\:bg-primary-400:active {
  background-color: var(--primary-400) !important;
}

.active\:bg-primary-500:active {
  background-color: var(--primary-500) !important;
}

.active\:bg-primary-600:active {
  background-color: var(--primary-600) !important;
}

.active\:bg-primary-700:active {
  background-color: var(--primary-700) !important;
}

.active\:bg-primary-800:active {
  background-color: var(--primary-800) !important;
}

.active\:bg-primary-900:active {
  background-color: var(--primary-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}

.border-blue-100 {
  border-color: var(--blue-100) !important;
}

.border-blue-200 {
  border-color: var(--blue-200) !important;
}

.border-blue-300 {
  border-color: var(--blue-300) !important;
}

.border-blue-400 {
  border-color: var(--blue-400) !important;
}

.border-blue-500 {
  border-color: var(--blue-500) !important;
}

.border-blue-600 {
  border-color: var(--blue-600) !important;
}

.border-blue-700 {
  border-color: var(--blue-700) !important;
}

.border-blue-800 {
  border-color: var(--blue-800) !important;
}

.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}

.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}

.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}

.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}

.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}

.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}

.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}

.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}

.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}

.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}

.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}

.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}

.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}

.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}

.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}

.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}

.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}

.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}

.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}

.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}

.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}

.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}

.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}

.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}

.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}

.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}

.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}

.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}

.border-green-100 {
  border-color: var(--green-100) !important;
}

.border-green-200 {
  border-color: var(--green-200) !important;
}

.border-green-300 {
  border-color: var(--green-300) !important;
}

.border-green-400 {
  border-color: var(--green-400) !important;
}

.border-green-500 {
  border-color: var(--green-500) !important;
}

.border-green-600 {
  border-color: var(--green-600) !important;
}

.border-green-700 {
  border-color: var(--green-700) !important;
}

.border-green-800 {
  border-color: var(--green-800) !important;
}

.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}

.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}

.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}

.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}

.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}

.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}

.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}

.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}

.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}

.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}

.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}

.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}

.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}

.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}

.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}

.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}

.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}

.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}

.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}

.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}

.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}

.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}

.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}

.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}

.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}

.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}

.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}

.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}

.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}

.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}

.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}

.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}

.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}

.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}

.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}

.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}

.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}

.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}

.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}

.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}

.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}

.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}

.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}

.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}

.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}

.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}

.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}

.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}

.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}

.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}

.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}

.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}

.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}

.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}

.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}

.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}

.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}

.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}

.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}

.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}

.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}

.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}

.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}

.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}

.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}

.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}

.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}

.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}

.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}

.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}

.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}

.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}

.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}

.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}

.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}

.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}

.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}

.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}

.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}

.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}

.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}

.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}

.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}

.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}

.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}

.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}

.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}

.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}

.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}

.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}

.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}

.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}

.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}

.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}

.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}

.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}

.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}

.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}

.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}

.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}

.border-pink-100 {
  border-color: var(--pink-100) !important;
}

.border-pink-200 {
  border-color: var(--pink-200) !important;
}

.border-pink-300 {
  border-color: var(--pink-300) !important;
}

.border-pink-400 {
  border-color: var(--pink-400) !important;
}

.border-pink-500 {
  border-color: var(--pink-500) !important;
}

.border-pink-600 {
  border-color: var(--pink-600) !important;
}

.border-pink-700 {
  border-color: var(--pink-700) !important;
}

.border-pink-800 {
  border-color: var(--pink-800) !important;
}

.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}

.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}

.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}

.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}

.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}

.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}

.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}

.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}

.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}

.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}

.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}

.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}

.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}

.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}

.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}

.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}

.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}

.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}

.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}

.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}

.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}

.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}

.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}

.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}

.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}

.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}

.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}

.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}

.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}

.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}

.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}

.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}

.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}

.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}

.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}

.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}

.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}

.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}

.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}

.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}

.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}

.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}

.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}

.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}

.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}

.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}

.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}

.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}

.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}

.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}

.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}

.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}

.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}

.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}

.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}

.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}

.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}

.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}

.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}

.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}

.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}

.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}

.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}

.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}

.border-teal-100 {
  border-color: var(--teal-100) !important;
}

.border-teal-200 {
  border-color: var(--teal-200) !important;
}

.border-teal-300 {
  border-color: var(--teal-300) !important;
}

.border-teal-400 {
  border-color: var(--teal-400) !important;
}

.border-teal-500 {
  border-color: var(--teal-500) !important;
}

.border-teal-600 {
  border-color: var(--teal-600) !important;
}

.border-teal-700 {
  border-color: var(--teal-700) !important;
}

.border-teal-800 {
  border-color: var(--teal-800) !important;
}

.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}

.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}

.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}

.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}

.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}

.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}

.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}

.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}

.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}

.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}

.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}

.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}

.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}

.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}

.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}

.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}

.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}

.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}

.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}

.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}

.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}

.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}

.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}

.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}

.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}

.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}

.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}

.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}

.border-orange-100 {
  border-color: var(--orange-100) !important;
}

.border-orange-200 {
  border-color: var(--orange-200) !important;
}

.border-orange-300 {
  border-color: var(--orange-300) !important;
}

.border-orange-400 {
  border-color: var(--orange-400) !important;
}

.border-orange-500 {
  border-color: var(--orange-500) !important;
}

.border-orange-600 {
  border-color: var(--orange-600) !important;
}

.border-orange-700 {
  border-color: var(--orange-700) !important;
}

.border-orange-800 {
  border-color: var(--orange-800) !important;
}

.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}

.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}

.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}

.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}

.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}

.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}

.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}

.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}

.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}

.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}

.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}

.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}

.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}

.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}

.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}

.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}

.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}

.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}

.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}

.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}

.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}

.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}

.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}

.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}

.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}

.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}

.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}

.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}

.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}

.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}

.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}

.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}

.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}

.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}

.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}

.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}

.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}

.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}

.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}

.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}

.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}

.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}

.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}

.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}

.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}

.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}

.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}

.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}

.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}

.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}

.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}

.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}

.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}

.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}

.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}

.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}

.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}

.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}

.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}

.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}

.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}

.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}

.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}

.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}

.border-purple-100 {
  border-color: var(--purple-100) !important;
}

.border-purple-200 {
  border-color: var(--purple-200) !important;
}

.border-purple-300 {
  border-color: var(--purple-300) !important;
}

.border-purple-400 {
  border-color: var(--purple-400) !important;
}

.border-purple-500 {
  border-color: var(--purple-500) !important;
}

.border-purple-600 {
  border-color: var(--purple-600) !important;
}

.border-purple-700 {
  border-color: var(--purple-700) !important;
}

.border-purple-800 {
  border-color: var(--purple-800) !important;
}

.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}

.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}

.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}

.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}

.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}

.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}

.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}

.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}

.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}

.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}

.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}

.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}

.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}

.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}

.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}

.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}

.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}

.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}

.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}

.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}

.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}

.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}

.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}

.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}

.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}

.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}

.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}

.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}

.border-gray-100 {
  border-color: var(--gray-100) !important;
}

.border-gray-200 {
  border-color: var(--gray-200) !important;
}

.border-gray-300 {
  border-color: var(--gray-300) !important;
}

.border-gray-400 {
  border-color: var(--gray-400) !important;
}

.border-gray-500 {
  border-color: var(--gray-500) !important;
}

.border-gray-600 {
  border-color: var(--gray-600) !important;
}

.border-gray-700 {
  border-color: var(--gray-700) !important;
}

.border-gray-800 {
  border-color: var(--gray-800) !important;
}

.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}

.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}

.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}

.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}

.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}

.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}

.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}

.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}

.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}

.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}

.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}

.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}

.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}

.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}

.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}

.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}

.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}

.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}

.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}

.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}

.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}

.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}

.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}

.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}

.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}

.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}

.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}

.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.border-red-50 {
  border-color: var(--red-50) !important;
}

.border-red-100 {
  border-color: var(--red-100) !important;
}

.border-red-200 {
  border-color: var(--red-200) !important;
}

.border-red-300 {
  border-color: var(--red-300) !important;
}

.border-red-400 {
  border-color: var(--red-400) !important;
}

.border-red-500 {
  border-color: var(--red-500) !important;
}

.border-red-600 {
  border-color: var(--red-600) !important;
}

.border-red-700 {
  border-color: var(--red-700) !important;
}

.border-red-800 {
  border-color: var(--red-800) !important;
}

.border-red-900 {
  border-color: var(--red-900) !important;
}

.focus\:border-red-50:focus {
  border-color: var(--red-50) !important;
}

.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}

.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}

.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}

.focus\:border-red-400:focus {
  border-color: var(--red-400) !important;
}

.focus\:border-red-500:focus {
  border-color: var(--red-500) !important;
}

.focus\:border-red-600:focus {
  border-color: var(--red-600) !important;
}

.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}

.focus\:border-red-800:focus {
  border-color: var(--red-800) !important;
}

.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-50:hover {
  border-color: var(--red-50) !important;
}

.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}

.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}

.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}

.hover\:border-red-400:hover {
  border-color: var(--red-400) !important;
}

.hover\:border-red-500:hover {
  border-color: var(--red-500) !important;
}

.hover\:border-red-600:hover {
  border-color: var(--red-600) !important;
}

.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}

.hover\:border-red-800:hover {
  border-color: var(--red-800) !important;
}

.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-50:active {
  border-color: var(--red-50) !important;
}

.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}

.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}

.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}

.active\:border-red-400:active {
  border-color: var(--red-400) !important;
}

.active\:border-red-500:active {
  border-color: var(--red-500) !important;
}

.active\:border-red-600:active {
  border-color: var(--red-600) !important;
}

.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}

.active\:border-red-800:active {
  border-color: var(--red-800) !important;
}

.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}

.border-primary-100 {
  border-color: var(--primary-100) !important;
}

.border-primary-200 {
  border-color: var(--primary-200) !important;
}

.border-primary-300 {
  border-color: var(--primary-300) !important;
}

.border-primary-400 {
  border-color: var(--primary-400) !important;
}

.border-primary-500 {
  border-color: var(--primary-500) !important;
}

.border-primary-600 {
  border-color: var(--primary-600) !important;
}

.border-primary-700 {
  border-color: var(--primary-700) !important;
}

.border-primary-800 {
  border-color: var(--primary-800) !important;
}

.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.focus\:border-primary-50:focus {
  border-color: var(--primary-50) !important;
}

.focus\:border-primary-100:focus {
  border-color: var(--primary-100) !important;
}

.focus\:border-primary-200:focus {
  border-color: var(--primary-200) !important;
}

.focus\:border-primary-300:focus {
  border-color: var(--primary-300) !important;
}

.focus\:border-primary-400:focus {
  border-color: var(--primary-400) !important;
}

.focus\:border-primary-500:focus {
  border-color: var(--primary-500) !important;
}

.focus\:border-primary-600:focus {
  border-color: var(--primary-600) !important;
}

.focus\:border-primary-700:focus {
  border-color: var(--primary-700) !important;
}

.focus\:border-primary-800:focus {
  border-color: var(--primary-800) !important;
}

.focus\:border-primary-900:focus {
  border-color: var(--primary-900) !important;
}

.hover\:border-primary-50:hover {
  border-color: var(--primary-50) !important;
}

.hover\:border-primary-100:hover {
  border-color: var(--primary-100) !important;
}

.hover\:border-primary-200:hover {
  border-color: var(--primary-200) !important;
}

.hover\:border-primary-300:hover {
  border-color: var(--primary-300) !important;
}

.hover\:border-primary-400:hover {
  border-color: var(--primary-400) !important;
}

.hover\:border-primary-500:hover {
  border-color: var(--primary-500) !important;
}

.hover\:border-primary-600:hover {
  border-color: var(--primary-600) !important;
}

.hover\:border-primary-700:hover {
  border-color: var(--primary-700) !important;
}

.hover\:border-primary-800:hover {
  border-color: var(--primary-800) !important;
}

.hover\:border-primary-900:hover {
  border-color: var(--primary-900) !important;
}

.active\:border-primary-50:active {
  border-color: var(--primary-50) !important;
}

.active\:border-primary-100:active {
  border-color: var(--primary-100) !important;
}

.active\:border-primary-200:active {
  border-color: var(--primary-200) !important;
}

.active\:border-primary-300:active {
  border-color: var(--primary-300) !important;
}

.active\:border-primary-400:active {
  border-color: var(--primary-400) !important;
}

.active\:border-primary-500:active {
  border-color: var(--primary-500) !important;
}

.active\:border-primary-600:active {
  border-color: var(--primary-600) !important;
}

.active\:border-primary-700:active {
  border-color: var(--primary-700) !important;
}

.active\:border-primary-800:active {
  border-color: var(--primary-800) !important;
}

.active\:border-primary-900:active {
  border-color: var(--primary-900) !important;
}

.bg-white-alpha-10 {
  background-color: #ffffff1a !important;
}

.bg-white-alpha-20 {
  background-color: #fff3 !important;
}

.bg-white-alpha-30 {
  background-color: #ffffff4d !important;
}

.bg-white-alpha-40 {
  background-color: #fff6 !important;
}

.bg-white-alpha-50 {
  background-color: #ffffff80 !important;
}

.bg-white-alpha-60 {
  background-color: #fff9 !important;
}

.bg-white-alpha-70 {
  background-color: #ffffffb3 !important;
}

.bg-white-alpha-80 {
  background-color: #fffc !important;
}

.bg-white-alpha-90 {
  background-color: #ffffffe6 !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: #ffffff1a !important;
}

.hover\:bg-white-alpha-20:hover {
  background-color: #fff3 !important;
}

.hover\:bg-white-alpha-30:hover {
  background-color: #ffffff4d !important;
}

.hover\:bg-white-alpha-40:hover {
  background-color: #fff6 !important;
}

.hover\:bg-white-alpha-50:hover {
  background-color: #ffffff80 !important;
}

.hover\:bg-white-alpha-60:hover {
  background-color: #fff9 !important;
}

.hover\:bg-white-alpha-70:hover {
  background-color: #ffffffb3 !important;
}

.hover\:bg-white-alpha-80:hover {
  background-color: #fffc !important;
}

.hover\:bg-white-alpha-90:hover {
  background-color: #ffffffe6 !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: #ffffff1a !important;
}

.focus\:bg-white-alpha-20:focus {
  background-color: #fff3 !important;
}

.focus\:bg-white-alpha-30:focus {
  background-color: #ffffff4d !important;
}

.focus\:bg-white-alpha-40:focus {
  background-color: #fff6 !important;
}

.focus\:bg-white-alpha-50:focus {
  background-color: #ffffff80 !important;
}

.focus\:bg-white-alpha-60:focus {
  background-color: #fff9 !important;
}

.focus\:bg-white-alpha-70:focus {
  background-color: #ffffffb3 !important;
}

.focus\:bg-white-alpha-80:focus {
  background-color: #fffc !important;
}

.focus\:bg-white-alpha-90:focus {
  background-color: #ffffffe6 !important;
}

.active\:bg-white-alpha-10:active {
  background-color: #ffffff1a !important;
}

.active\:bg-white-alpha-20:active {
  background-color: #fff3 !important;
}

.active\:bg-white-alpha-30:active {
  background-color: #ffffff4d !important;
}

.active\:bg-white-alpha-40:active {
  background-color: #fff6 !important;
}

.active\:bg-white-alpha-50:active {
  background-color: #ffffff80 !important;
}

.active\:bg-white-alpha-60:active {
  background-color: #fff9 !important;
}

.active\:bg-white-alpha-70:active {
  background-color: #ffffffb3 !important;
}

.active\:bg-white-alpha-80:active {
  background-color: #fffc !important;
}

.active\:bg-white-alpha-90:active {
  background-color: #ffffffe6 !important;
}

.bg-black-alpha-10 {
  background-color: #0000001a !important;
}

.bg-black-alpha-20 {
  background-color: #0003 !important;
}

.bg-black-alpha-30 {
  background-color: #0000004d !important;
}

.bg-black-alpha-40 {
  background-color: #0006 !important;
}

.bg-black-alpha-50 {
  background-color: #00000080 !important;
}

.bg-black-alpha-60 {
  background-color: #0009 !important;
}

.bg-black-alpha-70 {
  background-color: #000000b3 !important;
}

.bg-black-alpha-80 {
  background-color: #000c !important;
}

.bg-black-alpha-90 {
  background-color: #000000e6 !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: #0000001a !important;
}

.hover\:bg-black-alpha-20:hover {
  background-color: #0003 !important;
}

.hover\:bg-black-alpha-30:hover {
  background-color: #0000004d !important;
}

.hover\:bg-black-alpha-40:hover {
  background-color: #0006 !important;
}

.hover\:bg-black-alpha-50:hover {
  background-color: #00000080 !important;
}

.hover\:bg-black-alpha-60:hover {
  background-color: #0009 !important;
}

.hover\:bg-black-alpha-70:hover {
  background-color: #000000b3 !important;
}

.hover\:bg-black-alpha-80:hover {
  background-color: #000c !important;
}

.hover\:bg-black-alpha-90:hover {
  background-color: #000000e6 !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: #0000001a !important;
}

.focus\:bg-black-alpha-20:focus {
  background-color: #0003 !important;
}

.focus\:bg-black-alpha-30:focus {
  background-color: #0000004d !important;
}

.focus\:bg-black-alpha-40:focus {
  background-color: #0006 !important;
}

.focus\:bg-black-alpha-50:focus {
  background-color: #00000080 !important;
}

.focus\:bg-black-alpha-60:focus {
  background-color: #0009 !important;
}

.focus\:bg-black-alpha-70:focus {
  background-color: #000000b3 !important;
}

.focus\:bg-black-alpha-80:focus {
  background-color: #000c !important;
}

.focus\:bg-black-alpha-90:focus {
  background-color: #000000e6 !important;
}

.active\:bg-black-alpha-10:active {
  background-color: #0000001a !important;
}

.active\:bg-black-alpha-20:active {
  background-color: #0003 !important;
}

.active\:bg-black-alpha-30:active {
  background-color: #0000004d !important;
}

.active\:bg-black-alpha-40:active {
  background-color: #0006 !important;
}

.active\:bg-black-alpha-50:active {
  background-color: #00000080 !important;
}

.active\:bg-black-alpha-60:active {
  background-color: #0009 !important;
}

.active\:bg-black-alpha-70:active {
  background-color: #000000b3 !important;
}

.active\:bg-black-alpha-80:active {
  background-color: #000c !important;
}

.active\:bg-black-alpha-90:active {
  background-color: #000000e6 !important;
}

.border-white-alpha-10 {
  border-color: #ffffff1a !important;
}

.border-white-alpha-20 {
  border-color: #fff3 !important;
}

.border-white-alpha-30 {
  border-color: #ffffff4d !important;
}

.border-white-alpha-40 {
  border-color: #fff6 !important;
}

.border-white-alpha-50 {
  border-color: #ffffff80 !important;
}

.border-white-alpha-60 {
  border-color: #fff9 !important;
}

.border-white-alpha-70 {
  border-color: #ffffffb3 !important;
}

.border-white-alpha-80 {
  border-color: #fffc !important;
}

.border-white-alpha-90 {
  border-color: #ffffffe6 !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: #ffffff1a !important;
}

.hover\:border-white-alpha-20:hover {
  border-color: #fff3 !important;
}

.hover\:border-white-alpha-30:hover {
  border-color: #ffffff4d !important;
}

.hover\:border-white-alpha-40:hover {
  border-color: #fff6 !important;
}

.hover\:border-white-alpha-50:hover {
  border-color: #ffffff80 !important;
}

.hover\:border-white-alpha-60:hover {
  border-color: #fff9 !important;
}

.hover\:border-white-alpha-70:hover {
  border-color: #ffffffb3 !important;
}

.hover\:border-white-alpha-80:hover {
  border-color: #fffc !important;
}

.hover\:border-white-alpha-90:hover {
  border-color: #ffffffe6 !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: #ffffff1a !important;
}

.focus\:border-white-alpha-20:focus {
  border-color: #fff3 !important;
}

.focus\:border-white-alpha-30:focus {
  border-color: #ffffff4d !important;
}

.focus\:border-white-alpha-40:focus {
  border-color: #fff6 !important;
}

.focus\:border-white-alpha-50:focus {
  border-color: #ffffff80 !important;
}

.focus\:border-white-alpha-60:focus {
  border-color: #fff9 !important;
}

.focus\:border-white-alpha-70:focus {
  border-color: #ffffffb3 !important;
}

.focus\:border-white-alpha-80:focus {
  border-color: #fffc !important;
}

.focus\:border-white-alpha-90:focus {
  border-color: #ffffffe6 !important;
}

.active\:border-white-alpha-10:active {
  border-color: #ffffff1a !important;
}

.active\:border-white-alpha-20:active {
  border-color: #fff3 !important;
}

.active\:border-white-alpha-30:active {
  border-color: #ffffff4d !important;
}

.active\:border-white-alpha-40:active {
  border-color: #fff6 !important;
}

.active\:border-white-alpha-50:active {
  border-color: #ffffff80 !important;
}

.active\:border-white-alpha-60:active {
  border-color: #fff9 !important;
}

.active\:border-white-alpha-70:active {
  border-color: #ffffffb3 !important;
}

.active\:border-white-alpha-80:active {
  border-color: #fffc !important;
}

.active\:border-white-alpha-90:active {
  border-color: #ffffffe6 !important;
}

.border-black-alpha-10 {
  border-color: #0000001a !important;
}

.border-black-alpha-20 {
  border-color: #0003 !important;
}

.border-black-alpha-30 {
  border-color: #0000004d !important;
}

.border-black-alpha-40 {
  border-color: #0006 !important;
}

.border-black-alpha-50 {
  border-color: #00000080 !important;
}

.border-black-alpha-60 {
  border-color: #0009 !important;
}

.border-black-alpha-70 {
  border-color: #000000b3 !important;
}

.border-black-alpha-80 {
  border-color: #000c !important;
}

.border-black-alpha-90 {
  border-color: #000000e6 !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: #0000001a !important;
}

.hover\:border-black-alpha-20:hover {
  border-color: #0003 !important;
}

.hover\:border-black-alpha-30:hover {
  border-color: #0000004d !important;
}

.hover\:border-black-alpha-40:hover {
  border-color: #0006 !important;
}

.hover\:border-black-alpha-50:hover {
  border-color: #00000080 !important;
}

.hover\:border-black-alpha-60:hover {
  border-color: #0009 !important;
}

.hover\:border-black-alpha-70:hover {
  border-color: #000000b3 !important;
}

.hover\:border-black-alpha-80:hover {
  border-color: #000c !important;
}

.hover\:border-black-alpha-90:hover {
  border-color: #000000e6 !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: #0000001a !important;
}

.focus\:border-black-alpha-20:focus {
  border-color: #0003 !important;
}

.focus\:border-black-alpha-30:focus {
  border-color: #0000004d !important;
}

.focus\:border-black-alpha-40:focus {
  border-color: #0006 !important;
}

.focus\:border-black-alpha-50:focus {
  border-color: #00000080 !important;
}

.focus\:border-black-alpha-60:focus {
  border-color: #0009 !important;
}

.focus\:border-black-alpha-70:focus {
  border-color: #000000b3 !important;
}

.focus\:border-black-alpha-80:focus {
  border-color: #000c !important;
}

.focus\:border-black-alpha-90:focus {
  border-color: #000000e6 !important;
}

.active\:border-black-alpha-10:active {
  border-color: #0000001a !important;
}

.active\:border-black-alpha-20:active {
  border-color: #0003 !important;
}

.active\:border-black-alpha-30:active {
  border-color: #0000004d !important;
}

.active\:border-black-alpha-40:active {
  border-color: #0006 !important;
}

.active\:border-black-alpha-50:active {
  border-color: #00000080 !important;
}

.active\:border-black-alpha-60:active {
  border-color: #0009 !important;
}

.active\:border-black-alpha-70:active {
  border-color: #000000b3 !important;
}

.active\:border-black-alpha-80:active {
  border-color: #000c !important;
}

.active\:border-black-alpha-90:active {
  border-color: #000000e6 !important;
}

.text-white-alpha-10 {
  color: #ffffff1a !important;
}

.text-white-alpha-20 {
  color: #fff3 !important;
}

.text-white-alpha-30 {
  color: #ffffff4d !important;
}

.text-white-alpha-40 {
  color: #fff6 !important;
}

.text-white-alpha-50 {
  color: #ffffff80 !important;
}

.text-white-alpha-60 {
  color: #fff9 !important;
}

.text-white-alpha-70 {
  color: #ffffffb3 !important;
}

.text-white-alpha-80 {
  color: #fffc !important;
}

.text-white-alpha-90 {
  color: #ffffffe6 !important;
}

.hover\:text-white-alpha-10:hover {
  color: #ffffff1a !important;
}

.hover\:text-white-alpha-20:hover {
  color: #fff3 !important;
}

.hover\:text-white-alpha-30:hover {
  color: #ffffff4d !important;
}

.hover\:text-white-alpha-40:hover {
  color: #fff6 !important;
}

.hover\:text-white-alpha-50:hover {
  color: #ffffff80 !important;
}

.hover\:text-white-alpha-60:hover {
  color: #fff9 !important;
}

.hover\:text-white-alpha-70:hover {
  color: #ffffffb3 !important;
}

.hover\:text-white-alpha-80:hover {
  color: #fffc !important;
}

.hover\:text-white-alpha-90:hover {
  color: #ffffffe6 !important;
}

.focus\:text-white-alpha-10:focus {
  color: #ffffff1a !important;
}

.focus\:text-white-alpha-20:focus {
  color: #fff3 !important;
}

.focus\:text-white-alpha-30:focus {
  color: #ffffff4d !important;
}

.focus\:text-white-alpha-40:focus {
  color: #fff6 !important;
}

.focus\:text-white-alpha-50:focus {
  color: #ffffff80 !important;
}

.focus\:text-white-alpha-60:focus {
  color: #fff9 !important;
}

.focus\:text-white-alpha-70:focus {
  color: #ffffffb3 !important;
}

.focus\:text-white-alpha-80:focus {
  color: #fffc !important;
}

.focus\:text-white-alpha-90:focus {
  color: #ffffffe6 !important;
}

.active\:text-white-alpha-10:active {
  color: #ffffff1a !important;
}

.active\:text-white-alpha-20:active {
  color: #fff3 !important;
}

.active\:text-white-alpha-30:active {
  color: #ffffff4d !important;
}

.active\:text-white-alpha-40:active {
  color: #fff6 !important;
}

.active\:text-white-alpha-50:active {
  color: #ffffff80 !important;
}

.active\:text-white-alpha-60:active {
  color: #fff9 !important;
}

.active\:text-white-alpha-70:active {
  color: #ffffffb3 !important;
}

.active\:text-white-alpha-80:active {
  color: #fffc !important;
}

.active\:text-white-alpha-90:active {
  color: #ffffffe6 !important;
}

.text-black-alpha-10 {
  color: #0000001a !important;
}

.text-black-alpha-20 {
  color: #0003 !important;
}

.text-black-alpha-30 {
  color: #0000004d !important;
}

.text-black-alpha-40 {
  color: #0006 !important;
}

.text-black-alpha-50 {
  color: #00000080 !important;
}

.text-black-alpha-60 {
  color: #0009 !important;
}

.text-black-alpha-70 {
  color: #000000b3 !important;
}

.text-black-alpha-80 {
  color: #000c !important;
}

.text-black-alpha-90 {
  color: #000000e6 !important;
}

.hover\:text-black-alpha-10:hover {
  color: #0000001a !important;
}

.hover\:text-black-alpha-20:hover {
  color: #0003 !important;
}

.hover\:text-black-alpha-30:hover {
  color: #0000004d !important;
}

.hover\:text-black-alpha-40:hover {
  color: #0006 !important;
}

.hover\:text-black-alpha-50:hover {
  color: #00000080 !important;
}

.hover\:text-black-alpha-60:hover {
  color: #0009 !important;
}

.hover\:text-black-alpha-70:hover {
  color: #000000b3 !important;
}

.hover\:text-black-alpha-80:hover {
  color: #000c !important;
}

.hover\:text-black-alpha-90:hover {
  color: #000000e6 !important;
}

.focus\:text-black-alpha-10:focus {
  color: #0000001a !important;
}

.focus\:text-black-alpha-20:focus {
  color: #0003 !important;
}

.focus\:text-black-alpha-30:focus {
  color: #0000004d !important;
}

.focus\:text-black-alpha-40:focus {
  color: #0006 !important;
}

.focus\:text-black-alpha-50:focus {
  color: #00000080 !important;
}

.focus\:text-black-alpha-60:focus {
  color: #0009 !important;
}

.focus\:text-black-alpha-70:focus {
  color: #000000b3 !important;
}

.focus\:text-black-alpha-80:focus {
  color: #000c !important;
}

.focus\:text-black-alpha-90:focus {
  color: #000000e6 !important;
}

.active\:text-black-alpha-10:active {
  color: #0000001a !important;
}

.active\:text-black-alpha-20:active {
  color: #0003 !important;
}

.active\:text-black-alpha-30:active {
  color: #0000004d !important;
}

.active\:text-black-alpha-40:active {
  color: #0006 !important;
}

.active\:text-black-alpha-50:active {
  color: #00000080 !important;
}

.active\:text-black-alpha-60:active {
  color: #0009 !important;
}

.active\:text-black-alpha-70:active {
  color: #000000b3 !important;
}

.active\:text-black-alpha-80:active {
  color: #000c !important;
}

.active\:text-black-alpha-90:active {
  color: #000000e6 !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus, .hover\:text-primary:hover, .active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus, .hover\:bg-primary:hover, .active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus, .hover\:bg-primary-reverse:hover, .active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus, .hover\:bg-white:hover, .active\:bg-white:active {
  background-color: #fff !important;
}

.focus\:border-primary:focus, .hover\:border-primary:hover, .active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus, .hover\:text-white:hover, .active\:text-white:active {
  color: #fff !important;
}

.focus\:border-white:focus, .hover\:border-white:hover, .active\:border-white:active {
  border-color: #fff !important;
}

.focus\:text-color:focus, .hover\:text-color:hover, .active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus, .hover\:text-color-secondary:hover, .active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus, .hover\:surface-ground:hover, .active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus, .hover\:surface-section:hover, .active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus, .hover\:surface-card:hover, .active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus, .hover\:surface-overlay:hover, .active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus, .hover\:surface-hover:hover, .active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus, .hover\:surface-border:hover, .active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }

  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .sm\:bg-white {
    background-color: #fff !important;
  }

  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .sm\:text-white {
    color: #fff !important;
  }

  .sm\:border-white {
    border-color: #fff !important;
  }

  .sm\:text-color {
    color: var(--text-color) !important;
  }

  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .sm\:focus\:text-primary:focus, .sm\:hover\:text-primary:hover, .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .sm\:focus\:bg-primary:focus, .sm\:hover\:bg-primary:hover, .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .sm\:focus\:bg-primary-reverse:focus, .sm\:hover\:bg-primary-reverse:hover, .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .sm\:focus\:bg-white:focus, .sm\:hover\:bg-white:hover, .sm\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .sm\:focus\:border-primary:focus, .sm\:hover\:border-primary:hover, .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .sm\:focus\:text-white:focus, .sm\:hover\:text-white:hover, .sm\:active\:text-white:active {
    color: #fff !important;
  }

  .sm\:focus\:border-white:focus, .sm\:hover\:border-white:hover, .sm\:active\:border-white:active {
    border-color: #fff !important;
  }

  .sm\:focus\:text-color:focus, .sm\:hover\:text-color:hover, .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .sm\:focus\:text-color-secondary:focus, .sm\:hover\:text-color-secondary:hover, .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .sm\:focus\:surface-ground:focus, .sm\:hover\:surface-ground:hover, .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .sm\:focus\:surface-section:focus, .sm\:hover\:surface-section:hover, .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .sm\:focus\:surface-card:focus, .sm\:hover\:surface-card:hover, .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .sm\:focus\:surface-overlay:focus, .sm\:hover\:surface-overlay:hover, .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .sm\:focus\:surface-hover:focus, .sm\:hover\:surface-hover:hover, .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .sm\:focus\:surface-border:focus, .sm\:hover\:surface-border:hover, .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }

  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .md\:bg-white {
    background-color: #fff !important;
  }

  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .md\:text-white {
    color: #fff !important;
  }

  .md\:border-white {
    border-color: #fff !important;
  }

  .md\:text-color {
    color: var(--text-color) !important;
  }

  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .md\:focus\:text-primary:focus, .md\:hover\:text-primary:hover, .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .md\:focus\:bg-primary:focus, .md\:hover\:bg-primary:hover, .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .md\:focus\:bg-primary-reverse:focus, .md\:hover\:bg-primary-reverse:hover, .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .md\:focus\:bg-white:focus, .md\:hover\:bg-white:hover, .md\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .md\:focus\:border-primary:focus, .md\:hover\:border-primary:hover, .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .md\:focus\:text-white:focus, .md\:hover\:text-white:hover, .md\:active\:text-white:active {
    color: #fff !important;
  }

  .md\:focus\:border-white:focus, .md\:hover\:border-white:hover, .md\:active\:border-white:active {
    border-color: #fff !important;
  }

  .md\:focus\:text-color:focus, .md\:hover\:text-color:hover, .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .md\:focus\:text-color-secondary:focus, .md\:hover\:text-color-secondary:hover, .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .md\:focus\:surface-ground:focus, .md\:hover\:surface-ground:hover, .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .md\:focus\:surface-section:focus, .md\:hover\:surface-section:hover, .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .md\:focus\:surface-card:focus, .md\:hover\:surface-card:hover, .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .md\:focus\:surface-overlay:focus, .md\:hover\:surface-overlay:hover, .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .md\:focus\:surface-hover:focus, .md\:hover\:surface-hover:hover, .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .md\:focus\:surface-border:focus, .md\:hover\:surface-border:hover, .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }

  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .lg\:bg-white {
    background-color: #fff !important;
  }

  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .lg\:text-white {
    color: #fff !important;
  }

  .lg\:border-white {
    border-color: #fff !important;
  }

  .lg\:text-color {
    color: var(--text-color) !important;
  }

  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .lg\:focus\:text-primary:focus, .lg\:hover\:text-primary:hover, .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .lg\:focus\:bg-primary:focus, .lg\:hover\:bg-primary:hover, .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .lg\:focus\:bg-primary-reverse:focus, .lg\:hover\:bg-primary-reverse:hover, .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .lg\:focus\:bg-white:focus, .lg\:hover\:bg-white:hover, .lg\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .lg\:focus\:border-primary:focus, .lg\:hover\:border-primary:hover, .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .lg\:focus\:text-white:focus, .lg\:hover\:text-white:hover, .lg\:active\:text-white:active {
    color: #fff !important;
  }

  .lg\:focus\:border-white:focus, .lg\:hover\:border-white:hover, .lg\:active\:border-white:active {
    border-color: #fff !important;
  }

  .lg\:focus\:text-color:focus, .lg\:hover\:text-color:hover, .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .lg\:focus\:text-color-secondary:focus, .lg\:hover\:text-color-secondary:hover, .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .lg\:focus\:surface-ground:focus, .lg\:hover\:surface-ground:hover, .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .lg\:focus\:surface-section:focus, .lg\:hover\:surface-section:hover, .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .lg\:focus\:surface-card:focus, .lg\:hover\:surface-card:hover, .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .lg\:focus\:surface-overlay:focus, .lg\:hover\:surface-overlay:hover, .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .lg\:focus\:surface-hover:focus, .lg\:hover\:surface-hover:hover, .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .lg\:focus\:surface-border:focus, .lg\:hover\:surface-border:hover, .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }

  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .xl\:bg-white {
    background-color: #fff !important;
  }

  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .xl\:text-white {
    color: #fff !important;
  }

  .xl\:border-white {
    border-color: #fff !important;
  }

  .xl\:text-color {
    color: var(--text-color) !important;
  }

  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .xl\:focus\:text-primary:focus, .xl\:hover\:text-primary:hover, .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .xl\:focus\:bg-primary:focus, .xl\:hover\:bg-primary:hover, .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .xl\:focus\:bg-primary-reverse:focus, .xl\:hover\:bg-primary-reverse:hover, .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .xl\:focus\:bg-white:focus, .xl\:hover\:bg-white:hover, .xl\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .xl\:focus\:border-primary:focus, .xl\:hover\:border-primary:hover, .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .xl\:focus\:text-white:focus, .xl\:hover\:text-white:hover, .xl\:active\:text-white:active {
    color: #fff !important;
  }

  .xl\:focus\:border-white:focus, .xl\:hover\:border-white:hover, .xl\:active\:border-white:active {
    border-color: #fff !important;
  }

  .xl\:focus\:text-color:focus, .xl\:hover\:text-color:hover, .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .xl\:focus\:text-color-secondary:focus, .xl\:hover\:text-color-secondary:hover, .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .xl\:focus\:surface-ground:focus, .xl\:hover\:surface-ground:hover, .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .xl\:focus\:surface-section:focus, .xl\:hover\:surface-section:hover, .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .xl\:focus\:surface-card:focus, .xl\:hover\:surface-card:hover, .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .xl\:focus\:surface-overlay:focus, .xl\:hover\:surface-overlay:hover, .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .xl\:focus\:surface-hover:focus, .xl\:hover\:surface-hover:hover, .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .xl\:focus\:surface-border:focus, .xl\:hover\:surface-border:hover, .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

.field {
  margin-bottom: 1rem;
}

.field > label {
  margin-bottom: .5rem;
  display: inline-block;
}

.field.grid > label {
  align-items: center;
  display: flex;
}

.field > small {
  margin-top: .25rem;
}

.field.grid, .formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed, .formgrid.grid .col-fixed, .field.grid .col, .formgrid.grid .col, .field.grid .col-1, .formgrid.grid .col-1, .field.grid .col-2, .formgrid.grid .col-2, .field.grid .col-3, .formgrid.grid .col-3, .field.grid .col-4, .formgrid.grid .col-4, .field.grid .col-5, .formgrid.grid .col-5, .field.grid .col-6, .formgrid.grid .col-6, .field.grid .col-7, .formgrid.grid .col-7, .field.grid .col-8, .formgrid.grid .col-8, .field.grid .col-9, .formgrid.grid .col-9, .field.grid .col-10, .formgrid.grid .col-10, .field.grid .col-11, .formgrid.grid .col-11, .field.grid .col-12, .formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
}

.formgroup-inline .field, .formgroup-inline .field-checkbox, .formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label, .formgroup-inline .field-checkbox > label, .formgroup-inline .field-radiobutton > label {
  margin-bottom: 0;
  margin-right: .5rem;
}

.field-checkbox, .field-radiobutton {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.field-checkbox > label, .field-radiobutton > label {
  margin-left: .5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:inline {
    display: inline !important;
  }

  .sm\:inline-block {
    display: inline-block !important;
  }

  .sm\:flex {
    display: flex !important;
  }

  .sm\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:hidden {
    display: none !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:inline {
    display: inline !important;
  }

  .lg\:inline-block {
    display: inline-block !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:hidden {
    display: none !important;
  }

  .xl\:block {
    display: block !important;
  }

  .xl\:inline {
    display: inline !important;
  }

  .xl\:inline-block {
    display: inline-block !important;
  }

  .xl\:flex {
    display: flex !important;
  }

  .xl\:inline-flex {
    display: inline-flex !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-justify {
    text-align: justify !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-justify {
    text-align: justify !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:text-right {
    text-align: right !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-justify {
    text-align: justify !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-justify {
    text-align: justify !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus, .hover\:underline:hover, .active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus, .hover\:line-through:hover, .active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus, .hover\:no-underline:hover, .active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }

  .sm\:font-normal {
    font-weight: 400 !important;
  }

  .sm\:font-medium {
    font-weight: 500 !important;
  }

  .sm\:font-semibold {
    font-weight: 600 !important;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:font-light {
    font-weight: 300 !important;
  }

  .md\:font-normal {
    font-weight: 400 !important;
  }

  .md\:font-medium {
    font-weight: 500 !important;
  }

  .md\:font-semibold {
    font-weight: 600 !important;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }

  .lg\:font-normal {
    font-weight: 400 !important;
  }

  .lg\:font-medium {
    font-weight: 500 !important;
  }

  .lg\:font-semibold {
    font-weight: 600 !important;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }

  .xl\:font-normal {
    font-weight: 400 !important;
  }

  .xl\:font-medium {
    font-weight: 500 !important;
  }

  .xl\:font-semibold {
    font-weight: 600 !important;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }
}

.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: .75rem !important;
}

.text-sm {
  font-size: .875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-xs {
    font-size: .75rem !important;
  }

  .sm\:text-sm {
    font-size: .875rem !important;
  }

  .sm\:text-base {
    font-size: 1rem !important;
  }

  .sm\:text-lg {
    font-size: 1.125rem !important;
  }

  .sm\:text-xl {
    font-size: 1.25rem !important;
  }

  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }

  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }

  .sm\:text-4xl {
    font-size: 2rem !important;
  }

  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }

  .sm\:text-6xl {
    font-size: 3rem !important;
  }

  .sm\:text-7xl {
    font-size: 4rem !important;
  }

  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:text-xs {
    font-size: .75rem !important;
  }

  .md\:text-sm {
    font-size: .875rem !important;
  }

  .md\:text-base {
    font-size: 1rem !important;
  }

  .md\:text-lg {
    font-size: 1.125rem !important;
  }

  .md\:text-xl {
    font-size: 1.25rem !important;
  }

  .md\:text-2xl {
    font-size: 1.5rem !important;
  }

  .md\:text-3xl {
    font-size: 1.75rem !important;
  }

  .md\:text-4xl {
    font-size: 2rem !important;
  }

  .md\:text-5xl {
    font-size: 2.5rem !important;
  }

  .md\:text-6xl {
    font-size: 3rem !important;
  }

  .md\:text-7xl {
    font-size: 4rem !important;
  }

  .md\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:text-xs {
    font-size: .75rem !important;
  }

  .lg\:text-sm {
    font-size: .875rem !important;
  }

  .lg\:text-base {
    font-size: 1rem !important;
  }

  .lg\:text-lg {
    font-size: 1.125rem !important;
  }

  .lg\:text-xl {
    font-size: 1.25rem !important;
  }

  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }

  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }

  .lg\:text-4xl {
    font-size: 2rem !important;
  }

  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }

  .lg\:text-6xl {
    font-size: 3rem !important;
  }

  .lg\:text-7xl {
    font-size: 4rem !important;
  }

  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:text-xs {
    font-size: .75rem !important;
  }

  .xl\:text-sm {
    font-size: .875rem !important;
  }

  .xl\:text-base {
    font-size: 1rem !important;
  }

  .xl\:text-lg {
    font-size: 1.125rem !important;
  }

  .xl\:text-xl {
    font-size: 1.25rem !important;
  }

  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }

  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }

  .xl\:text-4xl {
    font-size: 2rem !important;
  }

  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }

  .xl\:text-6xl {
    font-size: 3rem !important;
  }

  .xl\:text-7xl {
    font-size: 4rem !important;
  }

  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (min-width: 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .sm\:vertical-align-top {
    vertical-align: top !important;
  }

  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .md\:vertical-align-top {
    vertical-align: top !important;
  }

  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .lg\:vertical-align-top {
    vertical-align: top !important;
  }

  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .xl\:vertical-align-top {
    vertical-align: top !important;
  }

  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sm\:flex-column {
    flex-direction: column !important;
  }

  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .md\:flex-column {
    flex-direction: column !important;
  }

  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lg\:flex-column {
    flex-direction: column !important;
  }

  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xl\:flex-column {
    flex-direction: column !important;
  }

  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }

  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }

  .sm\:justify-content-center {
    justify-content: center !important;
  }

  .sm\:justify-content-between {
    justify-content: space-between !important;
  }

  .sm\:justify-content-around {
    justify-content: space-around !important;
  }

  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }

  .md\:justify-content-end {
    justify-content: flex-end !important;
  }

  .md\:justify-content-center {
    justify-content: center !important;
  }

  .md\:justify-content-between {
    justify-content: space-between !important;
  }

  .md\:justify-content-around {
    justify-content: space-around !important;
  }

  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }

  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }

  .lg\:justify-content-center {
    justify-content: center !important;
  }

  .lg\:justify-content-between {
    justify-content: space-between !important;
  }

  .lg\:justify-content-around {
    justify-content: space-around !important;
  }

  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-content-center {
    justify-content: center !important;
  }

  .xl\:justify-content-between {
    justify-content: space-between !important;
  }

  .xl\:justify-content-around {
    justify-content: space-around !important;
  }

  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }

  .sm\:align-content-end {
    align-content: flex-end !important;
  }

  .sm\:align-content-center {
    align-content: center !important;
  }

  .sm\:align-content-between {
    align-content: space-between !important;
  }

  .sm\:align-content-around {
    align-content: space-around !important;
  }

  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }

  .md\:align-content-end {
    align-content: flex-end !important;
  }

  .md\:align-content-center {
    align-content: center !important;
  }

  .md\:align-content-between {
    align-content: space-between !important;
  }

  .md\:align-content-around {
    align-content: space-around !important;
  }

  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }

  .lg\:align-content-end {
    align-content: flex-end !important;
  }

  .lg\:align-content-center {
    align-content: center !important;
  }

  .lg\:align-content-between {
    align-content: space-between !important;
  }

  .lg\:align-content-around {
    align-content: space-around !important;
  }

  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }

  .xl\:align-content-end {
    align-content: flex-end !important;
  }

  .xl\:align-content-center {
    align-content: center !important;
  }

  .xl\:align-content-between {
    align-content: space-between !important;
  }

  .xl\:align-content-around {
    align-content: space-around !important;
  }

  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }

  .sm\:align-items-start {
    align-items: flex-start !important;
  }

  .sm\:align-items-center {
    align-items: center !important;
  }

  .sm\:align-items-end {
    align-items: flex-end !important;
  }

  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }

  .md\:align-items-start {
    align-items: flex-start !important;
  }

  .md\:align-items-center {
    align-items: center !important;
  }

  .md\:align-items-end {
    align-items: flex-end !important;
  }

  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }

  .lg\:align-items-start {
    align-items: flex-start !important;
  }

  .lg\:align-items-center {
    align-items: center !important;
  }

  .lg\:align-items-end {
    align-items: flex-end !important;
  }

  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }

  .xl\:align-items-start {
    align-items: flex-start !important;
  }

  .xl\:align-items-center {
    align-items: center !important;
  }

  .xl\:align-items-end {
    align-items: flex-end !important;
  }

  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }

  .sm\:align-self-start {
    align-self: flex-start !important;
  }

  .sm\:align-self-end {
    align-self: flex-end !important;
  }

  .sm\:align-self-center {
    align-self: center !important;
  }

  .sm\:align-self-stretch {
    align-self: stretch !important;
  }

  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }

  .md\:align-self-start {
    align-self: flex-start !important;
  }

  .md\:align-self-end {
    align-self: flex-end !important;
  }

  .md\:align-self-center {
    align-self: center !important;
  }

  .md\:align-self-stretch {
    align-self: stretch !important;
  }

  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }

  .lg\:align-self-start {
    align-self: flex-start !important;
  }

  .lg\:align-self-end {
    align-self: flex-end !important;
  }

  .lg\:align-self-center {
    align-self: center !important;
  }

  .lg\:align-self-stretch {
    align-self: stretch !important;
  }

  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }

  .xl\:align-self-start {
    align-self: flex-start !important;
  }

  .xl\:align-self-end {
    align-self: flex-end !important;
  }

  .xl\:align-self-center {
    align-self: center !important;
  }

  .xl\:align-self-stretch {
    align-self: stretch !important;
  }

  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}

.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }

  .sm\:flex-order-1 {
    order: 1 !important;
  }

  .sm\:flex-order-2 {
    order: 2 !important;
  }

  .sm\:flex-order-3 {
    order: 3 !important;
  }

  .sm\:flex-order-4 {
    order: 4 !important;
  }

  .sm\:flex-order-5 {
    order: 5 !important;
  }

  .sm\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }

  .md\:flex-order-1 {
    order: 1 !important;
  }

  .md\:flex-order-2 {
    order: 2 !important;
  }

  .md\:flex-order-3 {
    order: 3 !important;
  }

  .md\:flex-order-4 {
    order: 4 !important;
  }

  .md\:flex-order-5 {
    order: 5 !important;
  }

  .md\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }

  .lg\:flex-order-1 {
    order: 1 !important;
  }

  .lg\:flex-order-2 {
    order: 2 !important;
  }

  .lg\:flex-order-3 {
    order: 3 !important;
  }

  .lg\:flex-order-4 {
    order: 4 !important;
  }

  .lg\:flex-order-5 {
    order: 5 !important;
  }

  .lg\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }

  .xl\:flex-order-1 {
    order: 1 !important;
  }

  .xl\:flex-order-2 {
    order: 2 !important;
  }

  .xl\:flex-order-3 {
    order: 3 !important;
  }

  .xl\:flex-order-4 {
    order: 4 !important;
  }

  .xl\:flex-order-5 {
    order: 5 !important;
  }

  .xl\:flex-order-6 {
    order: 6 !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

.flex-auto {
  flex: auto !important;
}

.flex-initial {
  flex: 0 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    flex: 1 !important;
  }

  .sm\:flex-auto {
    flex: auto !important;
  }

  .sm\:flex-initial {
    flex: 0 auto !important;
  }

  .sm\:flex-none {
    flex: none !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-1 {
    flex: 1 !important;
  }

  .md\:flex-auto {
    flex: auto !important;
  }

  .md\:flex-initial {
    flex: 0 auto !important;
  }

  .md\:flex-none {
    flex: none !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-1 {
    flex: 1 !important;
  }

  .lg\:flex-auto {
    flex: auto !important;
  }

  .lg\:flex-initial {
    flex: 0 auto !important;
  }

  .lg\:flex-none {
    flex: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-1 {
    flex: 1 !important;
  }

  .xl\:flex-auto {
    flex: auto !important;
  }

  .xl\:flex-initial {
    flex: 0 auto !important;
  }

  .xl\:flex-none {
    flex: none !important;
  }
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 3rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-6 {
  column-gap: 3rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0 !important;
  }

  .sm\:gap-1 {
    gap: .25rem !important;
  }

  .sm\:gap-2 {
    gap: .5rem !important;
  }

  .sm\:gap-3 {
    gap: 1rem !important;
  }

  .sm\:gap-4 {
    gap: 1.5rem !important;
  }

  .sm\:gap-5 {
    gap: 2rem !important;
  }

  .sm\:gap-6 {
    gap: 3rem !important;
  }

  .sm\:gap-7 {
    gap: 4rem !important;
  }

  .sm\:gap-8 {
    gap: 5rem !important;
  }

  .sm\:row-gap-0 {
    row-gap: 0 !important;
  }

  .sm\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .sm\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .sm\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .sm\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .sm\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .sm\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .sm\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .sm\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .sm\:column-gap-0 {
    column-gap: 0 !important;
  }

  .sm\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .sm\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .sm\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .sm\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .sm\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .sm\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .sm\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .sm\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:gap-0 {
    gap: 0 !important;
  }

  .md\:gap-1 {
    gap: .25rem !important;
  }

  .md\:gap-2 {
    gap: .5rem !important;
  }

  .md\:gap-3 {
    gap: 1rem !important;
  }

  .md\:gap-4 {
    gap: 1.5rem !important;
  }

  .md\:gap-5 {
    gap: 2rem !important;
  }

  .md\:gap-6 {
    gap: 3rem !important;
  }

  .md\:gap-7 {
    gap: 4rem !important;
  }

  .md\:gap-8 {
    gap: 5rem !important;
  }

  .md\:row-gap-0 {
    row-gap: 0 !important;
  }

  .md\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .md\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .md\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .md\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .md\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .md\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .md\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .md\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .md\:column-gap-0 {
    column-gap: 0 !important;
  }

  .md\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .md\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .md\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .md\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .md\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .md\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .md\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .md\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:gap-0 {
    gap: 0 !important;
  }

  .lg\:gap-1 {
    gap: .25rem !important;
  }

  .lg\:gap-2 {
    gap: .5rem !important;
  }

  .lg\:gap-3 {
    gap: 1rem !important;
  }

  .lg\:gap-4 {
    gap: 1.5rem !important;
  }

  .lg\:gap-5 {
    gap: 2rem !important;
  }

  .lg\:gap-6 {
    gap: 3rem !important;
  }

  .lg\:gap-7 {
    gap: 4rem !important;
  }

  .lg\:gap-8 {
    gap: 5rem !important;
  }

  .lg\:row-gap-0 {
    row-gap: 0 !important;
  }

  .lg\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .lg\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .lg\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .lg\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .lg\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .lg\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .lg\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .lg\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .lg\:column-gap-0 {
    column-gap: 0 !important;
  }

  .lg\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .lg\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .lg\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .lg\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .lg\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .lg\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .lg\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .lg\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:gap-0 {
    gap: 0 !important;
  }

  .xl\:gap-1 {
    gap: .25rem !important;
  }

  .xl\:gap-2 {
    gap: .5rem !important;
  }

  .xl\:gap-3 {
    gap: 1rem !important;
  }

  .xl\:gap-4 {
    gap: 1.5rem !important;
  }

  .xl\:gap-5 {
    gap: 2rem !important;
  }

  .xl\:gap-6 {
    gap: 3rem !important;
  }

  .xl\:gap-7 {
    gap: 4rem !important;
  }

  .xl\:gap-8 {
    gap: 5rem !important;
  }

  .xl\:row-gap-0 {
    row-gap: 0 !important;
  }

  .xl\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .xl\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .xl\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .xl\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .xl\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .xl\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .xl\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .xl\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .xl\:column-gap-0 {
    column-gap: 0 !important;
  }

  .xl\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .xl\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .xl\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .xl\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .xl\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .xl\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .xl\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .xl\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0 !important;
  }

  .sm\:p-1 {
    padding: .25rem !important;
  }

  .sm\:p-2 {
    padding: .5rem !important;
  }

  .sm\:p-3 {
    padding: 1rem !important;
  }

  .sm\:p-4 {
    padding: 1.5rem !important;
  }

  .sm\:p-5 {
    padding: 2rem !important;
  }

  .sm\:p-6 {
    padding: 3rem !important;
  }

  .sm\:p-7 {
    padding: 4rem !important;
  }

  .sm\:p-8 {
    padding: 5rem !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pt-1 {
    padding-top: .25rem !important;
  }

  .sm\:pt-2 {
    padding-top: .5rem !important;
  }

  .sm\:pt-3 {
    padding-top: 1rem !important;
  }

  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .sm\:pt-5 {
    padding-top: 2rem !important;
  }

  .sm\:pt-6 {
    padding-top: 3rem !important;
  }

  .sm\:pt-7 {
    padding-top: 4rem !important;
  }

  .sm\:pt-8 {
    padding-top: 5rem !important;
  }

  .sm\:pr-0 {
    padding-right: 0 !important;
  }

  .sm\:pr-1 {
    padding-right: .25rem !important;
  }

  .sm\:pr-2 {
    padding-right: .5rem !important;
  }

  .sm\:pr-3 {
    padding-right: 1rem !important;
  }

  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .sm\:pr-5 {
    padding-right: 2rem !important;
  }

  .sm\:pr-6 {
    padding-right: 3rem !important;
  }

  .sm\:pr-7 {
    padding-right: 4rem !important;
  }

  .sm\:pr-8 {
    padding-right: 5rem !important;
  }

  .sm\:pl-0 {
    padding-left: 0 !important;
  }

  .sm\:pl-1 {
    padding-left: .25rem !important;
  }

  .sm\:pl-2 {
    padding-left: .5rem !important;
  }

  .sm\:pl-3 {
    padding-left: 1rem !important;
  }

  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .sm\:pl-5 {
    padding-left: 2rem !important;
  }

  .sm\:pl-6 {
    padding-left: 3rem !important;
  }

  .sm\:pl-7 {
    padding-left: 4rem !important;
  }

  .sm\:pl-8 {
    padding-left: 5rem !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sm\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sm\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sm\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:p-1 {
    padding: .25rem !important;
  }

  .md\:p-2 {
    padding: .5rem !important;
  }

  .md\:p-3 {
    padding: 1rem !important;
  }

  .md\:p-4 {
    padding: 1.5rem !important;
  }

  .md\:p-5 {
    padding: 2rem !important;
  }

  .md\:p-6 {
    padding: 3rem !important;
  }

  .md\:p-7 {
    padding: 4rem !important;
  }

  .md\:p-8 {
    padding: 5rem !important;
  }

  .md\:pt-0 {
    padding-top: 0 !important;
  }

  .md\:pt-1 {
    padding-top: .25rem !important;
  }

  .md\:pt-2 {
    padding-top: .5rem !important;
  }

  .md\:pt-3 {
    padding-top: 1rem !important;
  }

  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .md\:pt-5 {
    padding-top: 2rem !important;
  }

  .md\:pt-6 {
    padding-top: 3rem !important;
  }

  .md\:pt-7 {
    padding-top: 4rem !important;
  }

  .md\:pt-8 {
    padding-top: 5rem !important;
  }

  .md\:pr-0 {
    padding-right: 0 !important;
  }

  .md\:pr-1 {
    padding-right: .25rem !important;
  }

  .md\:pr-2 {
    padding-right: .5rem !important;
  }

  .md\:pr-3 {
    padding-right: 1rem !important;
  }

  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .md\:pr-5 {
    padding-right: 2rem !important;
  }

  .md\:pr-6 {
    padding-right: 3rem !important;
  }

  .md\:pr-7 {
    padding-right: 4rem !important;
  }

  .md\:pr-8 {
    padding-right: 5rem !important;
  }

  .md\:pl-0 {
    padding-left: 0 !important;
  }

  .md\:pl-1 {
    padding-left: .25rem !important;
  }

  .md\:pl-2 {
    padding-left: .5rem !important;
  }

  .md\:pl-3 {
    padding-left: 1rem !important;
  }

  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .md\:pl-5 {
    padding-left: 2rem !important;
  }

  .md\:pl-6 {
    padding-left: 3rem !important;
  }

  .md\:pl-7 {
    padding-left: 4rem !important;
  }

  .md\:pl-8 {
    padding-left: 5rem !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .md\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .md\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .md\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .md\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:p-0 {
    padding: 0 !important;
  }

  .lg\:p-1 {
    padding: .25rem !important;
  }

  .lg\:p-2 {
    padding: .5rem !important;
  }

  .lg\:p-3 {
    padding: 1rem !important;
  }

  .lg\:p-4 {
    padding: 1.5rem !important;
  }

  .lg\:p-5 {
    padding: 2rem !important;
  }

  .lg\:p-6 {
    padding: 3rem !important;
  }

  .lg\:p-7 {
    padding: 4rem !important;
  }

  .lg\:p-8 {
    padding: 5rem !important;
  }

  .lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lg\:pt-1 {
    padding-top: .25rem !important;
  }

  .lg\:pt-2 {
    padding-top: .5rem !important;
  }

  .lg\:pt-3 {
    padding-top: 1rem !important;
  }

  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .lg\:pt-5 {
    padding-top: 2rem !important;
  }

  .lg\:pt-6 {
    padding-top: 3rem !important;
  }

  .lg\:pt-7 {
    padding-top: 4rem !important;
  }

  .lg\:pt-8 {
    padding-top: 5rem !important;
  }

  .lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lg\:pr-1 {
    padding-right: .25rem !important;
  }

  .lg\:pr-2 {
    padding-right: .5rem !important;
  }

  .lg\:pr-3 {
    padding-right: 1rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .lg\:pr-5 {
    padding-right: 2rem !important;
  }

  .lg\:pr-6 {
    padding-right: 3rem !important;
  }

  .lg\:pr-7 {
    padding-right: 4rem !important;
  }

  .lg\:pr-8 {
    padding-right: 5rem !important;
  }

  .lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lg\:pl-1 {
    padding-left: .25rem !important;
  }

  .lg\:pl-2 {
    padding-left: .5rem !important;
  }

  .lg\:pl-3 {
    padding-left: 1rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .lg\:pl-5 {
    padding-left: 2rem !important;
  }

  .lg\:pl-6 {
    padding-left: 3rem !important;
  }

  .lg\:pl-7 {
    padding-left: 4rem !important;
  }

  .lg\:pl-8 {
    padding-left: 5rem !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lg\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lg\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:p-1 {
    padding: .25rem !important;
  }

  .xl\:p-2 {
    padding: .5rem !important;
  }

  .xl\:p-3 {
    padding: 1rem !important;
  }

  .xl\:p-4 {
    padding: 1.5rem !important;
  }

  .xl\:p-5 {
    padding: 2rem !important;
  }

  .xl\:p-6 {
    padding: 3rem !important;
  }

  .xl\:p-7 {
    padding: 4rem !important;
  }

  .xl\:p-8 {
    padding: 5rem !important;
  }

  .xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:pt-1 {
    padding-top: .25rem !important;
  }

  .xl\:pt-2 {
    padding-top: .5rem !important;
  }

  .xl\:pt-3 {
    padding-top: 1rem !important;
  }

  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .xl\:pt-5 {
    padding-top: 2rem !important;
  }

  .xl\:pt-6 {
    padding-top: 3rem !important;
  }

  .xl\:pt-7 {
    padding-top: 4rem !important;
  }

  .xl\:pt-8 {
    padding-top: 5rem !important;
  }

  .xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:pr-1 {
    padding-right: .25rem !important;
  }

  .xl\:pr-2 {
    padding-right: .5rem !important;
  }

  .xl\:pr-3 {
    padding-right: 1rem !important;
  }

  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .xl\:pr-5 {
    padding-right: 2rem !important;
  }

  .xl\:pr-6 {
    padding-right: 3rem !important;
  }

  .xl\:pr-7 {
    padding-right: 4rem !important;
  }

  .xl\:pr-8 {
    padding-right: 5rem !important;
  }

  .xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:pl-1 {
    padding-left: .25rem !important;
  }

  .xl\:pl-2 {
    padding-left: .5rem !important;
  }

  .xl\:pl-3 {
    padding-left: 1rem !important;
  }

  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .xl\:pl-5 {
    padding-left: 2rem !important;
  }

  .xl\:pl-6 {
    padding-left: 3rem !important;
  }

  .xl\:pl-7 {
    padding-left: 4rem !important;
  }

  .xl\:pl-8 {
    padding-left: 5rem !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xl\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xl\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xl\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -.25rem !important;
}

.-m-2 {
  margin: -.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -.25rem !important;
}

.-mt-2 {
  margin-top: -.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -.25rem !important;
}

.-mr-2 {
  margin-right: -.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -.25rem !important;
}

.-ml-2 {
  margin-left: -.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -.25rem !important;
}

.-mb-2 {
  margin-bottom: -.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.-mx-2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.-my-2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:m-1 {
    margin: .25rem !important;
  }

  .sm\:m-2 {
    margin: .5rem !important;
  }

  .sm\:m-3 {
    margin: 1rem !important;
  }

  .sm\:m-4 {
    margin: 1.5rem !important;
  }

  .sm\:m-5 {
    margin: 2rem !important;
  }

  .sm\:m-6 {
    margin: 3rem !important;
  }

  .sm\:m-7 {
    margin: 4rem !important;
  }

  .sm\:m-8 {
    margin: 5rem !important;
  }

  .sm\:-m-1 {
    margin: -.25rem !important;
  }

  .sm\:-m-2 {
    margin: -.5rem !important;
  }

  .sm\:-m-3 {
    margin: -1rem !important;
  }

  .sm\:-m-4 {
    margin: -1.5rem !important;
  }

  .sm\:-m-5 {
    margin: -2rem !important;
  }

  .sm\:-m-6 {
    margin: -3rem !important;
  }

  .sm\:-m-7 {
    margin: -4rem !important;
  }

  .sm\:-m-8 {
    margin: -5rem !important;
  }

  .sm\:m-auto {
    margin: auto !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mt-1 {
    margin-top: .25rem !important;
  }

  .sm\:mt-2 {
    margin-top: .5rem !important;
  }

  .sm\:mt-3 {
    margin-top: 1rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .sm\:mt-5 {
    margin-top: 2rem !important;
  }

  .sm\:mt-6 {
    margin-top: 3rem !important;
  }

  .sm\:mt-7 {
    margin-top: 4rem !important;
  }

  .sm\:mt-8 {
    margin-top: 5rem !important;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }

  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mr-0 {
    margin-right: 0 !important;
  }

  .sm\:mr-1 {
    margin-right: .25rem !important;
  }

  .sm\:mr-2 {
    margin-right: .5rem !important;
  }

  .sm\:mr-3 {
    margin-right: 1rem !important;
  }

  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .sm\:mr-5 {
    margin-right: 2rem !important;
  }

  .sm\:mr-6 {
    margin-right: 3rem !important;
  }

  .sm\:mr-7 {
    margin-right: 4rem !important;
  }

  .sm\:mr-8 {
    margin-right: 5rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }

  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:ml-0 {
    margin-left: 0 !important;
  }

  .sm\:ml-1 {
    margin-left: .25rem !important;
  }

  .sm\:ml-2 {
    margin-left: .5rem !important;
  }

  .sm\:ml-3 {
    margin-left: 1rem !important;
  }

  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .sm\:ml-5 {
    margin-left: 2rem !important;
  }

  .sm\:ml-6 {
    margin-left: 3rem !important;
  }

  .sm\:ml-7 {
    margin-left: 4rem !important;
  }

  .sm\:ml-8 {
    margin-left: 5rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }

  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sm\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sm\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sm\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .sm\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:m-0 {
    margin: 0 !important;
  }

  .md\:m-1 {
    margin: .25rem !important;
  }

  .md\:m-2 {
    margin: .5rem !important;
  }

  .md\:m-3 {
    margin: 1rem !important;
  }

  .md\:m-4 {
    margin: 1.5rem !important;
  }

  .md\:m-5 {
    margin: 2rem !important;
  }

  .md\:m-6 {
    margin: 3rem !important;
  }

  .md\:m-7 {
    margin: 4rem !important;
  }

  .md\:m-8 {
    margin: 5rem !important;
  }

  .md\:-m-1 {
    margin: -.25rem !important;
  }

  .md\:-m-2 {
    margin: -.5rem !important;
  }

  .md\:-m-3 {
    margin: -1rem !important;
  }

  .md\:-m-4 {
    margin: -1.5rem !important;
  }

  .md\:-m-5 {
    margin: -2rem !important;
  }

  .md\:-m-6 {
    margin: -3rem !important;
  }

  .md\:-m-7 {
    margin: -4rem !important;
  }

  .md\:-m-8 {
    margin: -5rem !important;
  }

  .md\:m-auto {
    margin: auto !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mt-1 {
    margin-top: .25rem !important;
  }

  .md\:mt-2 {
    margin-top: .5rem !important;
  }

  .md\:mt-3 {
    margin-top: 1rem !important;
  }

  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .md\:mt-5 {
    margin-top: 2rem !important;
  }

  .md\:mt-6 {
    margin-top: 3rem !important;
  }

  .md\:mt-7 {
    margin-top: 4rem !important;
  }

  .md\:mt-8 {
    margin-top: 5rem !important;
  }

  .md\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .md\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .md\:-mt-3 {
    margin-top: -1rem !important;
  }

  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .md\:-mt-5 {
    margin-top: -2rem !important;
  }

  .md\:-mt-6 {
    margin-top: -3rem !important;
  }

  .md\:-mt-7 {
    margin-top: -4rem !important;
  }

  .md\:-mt-8 {
    margin-top: -5rem !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:mr-1 {
    margin-right: .25rem !important;
  }

  .md\:mr-2 {
    margin-right: .5rem !important;
  }

  .md\:mr-3 {
    margin-right: 1rem !important;
  }

  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .md\:mr-5 {
    margin-right: 2rem !important;
  }

  .md\:mr-6 {
    margin-right: 3rem !important;
  }

  .md\:mr-7 {
    margin-right: 4rem !important;
  }

  .md\:mr-8 {
    margin-right: 5rem !important;
  }

  .md\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .md\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .md\:-mr-3 {
    margin-right: -1rem !important;
  }

  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .md\:-mr-5 {
    margin-right: -2rem !important;
  }

  .md\:-mr-6 {
    margin-right: -3rem !important;
  }

  .md\:-mr-7 {
    margin-right: -4rem !important;
  }

  .md\:-mr-8 {
    margin-right: -5rem !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:ml-1 {
    margin-left: .25rem !important;
  }

  .md\:ml-2 {
    margin-left: .5rem !important;
  }

  .md\:ml-3 {
    margin-left: 1rem !important;
  }

  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .md\:ml-5 {
    margin-left: 2rem !important;
  }

  .md\:ml-6 {
    margin-left: 3rem !important;
  }

  .md\:ml-7 {
    margin-left: 4rem !important;
  }

  .md\:ml-8 {
    margin-left: 5rem !important;
  }

  .md\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .md\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .md\:-ml-3 {
    margin-left: -1rem !important;
  }

  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .md\:-ml-5 {
    margin-left: -2rem !important;
  }

  .md\:-ml-6 {
    margin-left: -3rem !important;
  }

  .md\:-ml-7 {
    margin-left: -4rem !important;
  }

  .md\:-ml-8 {
    margin-left: -5rem !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .md\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .md\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .md\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .md\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .md\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .md\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:m-0 {
    margin: 0 !important;
  }

  .lg\:m-1 {
    margin: .25rem !important;
  }

  .lg\:m-2 {
    margin: .5rem !important;
  }

  .lg\:m-3 {
    margin: 1rem !important;
  }

  .lg\:m-4 {
    margin: 1.5rem !important;
  }

  .lg\:m-5 {
    margin: 2rem !important;
  }

  .lg\:m-6 {
    margin: 3rem !important;
  }

  .lg\:m-7 {
    margin: 4rem !important;
  }

  .lg\:m-8 {
    margin: 5rem !important;
  }

  .lg\:-m-1 {
    margin: -.25rem !important;
  }

  .lg\:-m-2 {
    margin: -.5rem !important;
  }

  .lg\:-m-3 {
    margin: -1rem !important;
  }

  .lg\:-m-4 {
    margin: -1.5rem !important;
  }

  .lg\:-m-5 {
    margin: -2rem !important;
  }

  .lg\:-m-6 {
    margin: -3rem !important;
  }

  .lg\:-m-7 {
    margin: -4rem !important;
  }

  .lg\:-m-8 {
    margin: -5rem !important;
  }

  .lg\:m-auto {
    margin: auto !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mt-1 {
    margin-top: .25rem !important;
  }

  .lg\:mt-2 {
    margin-top: .5rem !important;
  }

  .lg\:mt-3 {
    margin-top: 1rem !important;
  }

  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .lg\:mt-5 {
    margin-top: 2rem !important;
  }

  .lg\:mt-6 {
    margin-top: 3rem !important;
  }

  .lg\:mt-7 {
    margin-top: 4rem !important;
  }

  .lg\:mt-8 {
    margin-top: 5rem !important;
  }

  .lg\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }

  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lg\:mr-1 {
    margin-right: .25rem !important;
  }

  .lg\:mr-2 {
    margin-right: .5rem !important;
  }

  .lg\:mr-3 {
    margin-right: 1rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .lg\:mr-5 {
    margin-right: 2rem !important;
  }

  .lg\:mr-6 {
    margin-right: 3rem !important;
  }

  .lg\:mr-7 {
    margin-right: 4rem !important;
  }

  .lg\:mr-8 {
    margin-right: 5rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }

  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lg\:ml-1 {
    margin-left: .25rem !important;
  }

  .lg\:ml-2 {
    margin-left: .5rem !important;
  }

  .lg\:ml-3 {
    margin-left: 1rem !important;
  }

  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .lg\:ml-5 {
    margin-left: 2rem !important;
  }

  .lg\:ml-6 {
    margin-left: 3rem !important;
  }

  .lg\:ml-7 {
    margin-left: 4rem !important;
  }

  .lg\:ml-8 {
    margin-left: 5rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }

  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lg\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lg\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lg\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .lg\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:m-1 {
    margin: .25rem !important;
  }

  .xl\:m-2 {
    margin: .5rem !important;
  }

  .xl\:m-3 {
    margin: 1rem !important;
  }

  .xl\:m-4 {
    margin: 1.5rem !important;
  }

  .xl\:m-5 {
    margin: 2rem !important;
  }

  .xl\:m-6 {
    margin: 3rem !important;
  }

  .xl\:m-7 {
    margin: 4rem !important;
  }

  .xl\:m-8 {
    margin: 5rem !important;
  }

  .xl\:-m-1 {
    margin: -.25rem !important;
  }

  .xl\:-m-2 {
    margin: -.5rem !important;
  }

  .xl\:-m-3 {
    margin: -1rem !important;
  }

  .xl\:-m-4 {
    margin: -1.5rem !important;
  }

  .xl\:-m-5 {
    margin: -2rem !important;
  }

  .xl\:-m-6 {
    margin: -3rem !important;
  }

  .xl\:-m-7 {
    margin: -4rem !important;
  }

  .xl\:-m-8 {
    margin: -5rem !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }

  .xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:mt-1 {
    margin-top: .25rem !important;
  }

  .xl\:mt-2 {
    margin-top: .5rem !important;
  }

  .xl\:mt-3 {
    margin-top: 1rem !important;
  }

  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .xl\:mt-5 {
    margin-top: 2rem !important;
  }

  .xl\:mt-6 {
    margin-top: 3rem !important;
  }

  .xl\:mt-7 {
    margin-top: 4rem !important;
  }

  .xl\:mt-8 {
    margin-top: 5rem !important;
  }

  .xl\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }

  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:mr-1 {
    margin-right: .25rem !important;
  }

  .xl\:mr-2 {
    margin-right: .5rem !important;
  }

  .xl\:mr-3 {
    margin-right: 1rem !important;
  }

  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .xl\:mr-5 {
    margin-right: 2rem !important;
  }

  .xl\:mr-6 {
    margin-right: 3rem !important;
  }

  .xl\:mr-7 {
    margin-right: 4rem !important;
  }

  .xl\:mr-8 {
    margin-right: 5rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }

  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:ml-1 {
    margin-left: .25rem !important;
  }

  .xl\:ml-2 {
    margin-left: .5rem !important;
  }

  .xl\:ml-3 {
    margin-left: 1rem !important;
  }

  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .xl\:ml-5 {
    margin-left: 2rem !important;
  }

  .xl\:ml-6 {
    margin-left: 3rem !important;
  }

  .xl\:ml-7 {
    margin-left: 4rem !important;
  }

  .xl\:ml-8 {
    margin-left: 5rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }

  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xl\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xl\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xl\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .xl\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
}

.shadow-2 {
  box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
}

.shadow-3 {
  box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
}

.shadow-4 {
  box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
}

.shadow-5 {
  box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
}

.shadow-6 {
  box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
}

.shadow-7 {
  box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
}

.shadow-8 {
  box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
}

.focus\:shadow-none:focus, .hover\:shadow-none:hover, .active\:shadow-none:active {
  box-shadow: none !important;
}

.focus\:shadow-1:focus, .hover\:shadow-1:hover, .active\:shadow-1:active {
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
}

.focus\:shadow-2:focus, .hover\:shadow-2:hover, .active\:shadow-2:active {
  box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
}

.focus\:shadow-3:focus, .hover\:shadow-3:hover, .active\:shadow-3:active {
  box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
}

.focus\:shadow-4:focus, .hover\:shadow-4:hover, .active\:shadow-4:active {
  box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
}

.focus\:shadow-5:focus, .hover\:shadow-5:hover, .active\:shadow-5:active {
  box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
}

.focus\:shadow-6:focus, .hover\:shadow-6:hover, .active\:shadow-6:active {
  box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
}

.focus\:shadow-7:focus, .hover\:shadow-7:hover, .active\:shadow-7:active {
  box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
}

.focus\:shadow-8:focus, .hover\:shadow-8:hover, .active\:shadow-8:active {
  box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
}

@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }

  .sm\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .sm\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .sm\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .sm\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .sm\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .sm\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .sm\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .sm\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .sm\:focus\:shadow-none:focus, .sm\:hover\:shadow-none:hover, .sm\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .sm\:focus\:shadow-1:focus, .sm\:hover\:shadow-1:hover, .sm\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .sm\:focus\:shadow-2:focus, .sm\:hover\:shadow-2:hover, .sm\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .sm\:focus\:shadow-3:focus, .sm\:hover\:shadow-3:hover, .sm\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .sm\:focus\:shadow-4:focus, .sm\:hover\:shadow-4:hover, .sm\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .sm\:focus\:shadow-5:focus, .sm\:hover\:shadow-5:hover, .sm\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .sm\:focus\:shadow-6:focus, .sm\:hover\:shadow-6:hover, .sm\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .sm\:focus\:shadow-7:focus, .sm\:hover\:shadow-7:hover, .sm\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .sm\:focus\:shadow-8:focus, .sm\:hover\:shadow-8:hover, .sm\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }

  .md\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .md\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .md\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .md\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .md\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .md\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .md\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .md\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .md\:focus\:shadow-none:focus, .md\:hover\:shadow-none:hover, .md\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .md\:focus\:shadow-1:focus, .md\:hover\:shadow-1:hover, .md\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .md\:focus\:shadow-2:focus, .md\:hover\:shadow-2:hover, .md\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .md\:focus\:shadow-3:focus, .md\:hover\:shadow-3:hover, .md\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .md\:focus\:shadow-4:focus, .md\:hover\:shadow-4:hover, .md\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .md\:focus\:shadow-5:focus, .md\:hover\:shadow-5:hover, .md\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .md\:focus\:shadow-6:focus, .md\:hover\:shadow-6:hover, .md\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .md\:focus\:shadow-7:focus, .md\:hover\:shadow-7:hover, .md\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .md\:focus\:shadow-8:focus, .md\:hover\:shadow-8:hover, .md\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }

  .lg\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .lg\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .lg\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .lg\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .lg\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .lg\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .lg\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .lg\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .lg\:focus\:shadow-none:focus, .lg\:hover\:shadow-none:hover, .lg\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .lg\:focus\:shadow-1:focus, .lg\:hover\:shadow-1:hover, .lg\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .lg\:focus\:shadow-2:focus, .lg\:hover\:shadow-2:hover, .lg\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .lg\:focus\:shadow-3:focus, .lg\:hover\:shadow-3:hover, .lg\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .lg\:focus\:shadow-4:focus, .lg\:hover\:shadow-4:hover, .lg\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .lg\:focus\:shadow-5:focus, .lg\:hover\:shadow-5:hover, .lg\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .lg\:focus\:shadow-6:focus, .lg\:hover\:shadow-6:hover, .lg\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .lg\:focus\:shadow-7:focus, .lg\:hover\:shadow-7:hover, .lg\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .lg\:focus\:shadow-8:focus, .lg\:hover\:shadow-8:hover, .lg\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }

  .xl\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .xl\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .xl\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .xl\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .xl\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .xl\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .xl\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .xl\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .xl\:focus\:shadow-none:focus, .xl\:hover\:shadow-none:hover, .xl\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .xl\:focus\:shadow-1:focus, .xl\:hover\:shadow-1:hover, .xl\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .xl\:focus\:shadow-2:focus, .xl\:hover\:shadow-2:hover, .xl\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .xl\:focus\:shadow-3:focus, .xl\:hover\:shadow-3:hover, .xl\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .xl\:focus\:shadow-4:focus, .xl\:hover\:shadow-4:hover, .xl\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .xl\:focus\:shadow-5:focus, .xl\:hover\:shadow-5:hover, .xl\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .xl\:focus\:shadow-6:focus, .xl\:hover\:shadow-6:hover, .xl\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .xl\:focus\:shadow-7:focus, .xl\:hover\:shadow-7:hover, .xl\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .xl\:focus\:shadow-8:focus, .xl\:hover\:shadow-8:hover, .xl\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

.border-none {
  border-style: none;
  border-width: 0 !important;
}

.border-1 {
  border-style: solid;
  border-width: 1px !important;
}

.border-2 {
  border-style: solid;
  border-width: 2px !important;
}

.border-3 {
  border-style: solid;
  border-width: 3px !important;
}

.border-top-none {
  border-top-style: none;
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-style: solid;
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-style: solid;
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-style: solid;
  border-top-width: 3px !important;
}

.border-right-none {
  border-right-style: none;
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-style: solid;
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-style: solid;
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-style: solid;
  border-right-width: 3px !important;
}

.border-left-none {
  border-left-style: none;
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-style: solid;
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-style: solid;
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-style: solid;
  border-left-width: 3px !important;
}

.border-bottom-none {
  border-bottom-style: none;
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-style: solid;
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-style: solid;
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-style: solid;
  border-bottom-width: 3px !important;
}

.border-x-none {
  border-left-style: none;
  border-right-style: none;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.border-x-1 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.border-x-2 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.border-x-3 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.border-y-none {
  border-top-style: none;
  border-bottom-style: none;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.border-y-1 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.border-y-2 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.border-y-3 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .sm\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .sm\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .sm\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .sm\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .sm\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .sm\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .sm\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .sm\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .sm\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .sm\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .sm\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .sm\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .sm\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .sm\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .sm\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .sm\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .sm\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .sm\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .sm\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .sm\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .sm\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .sm\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .sm\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .sm\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .sm\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .sm\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .sm\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .md\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .md\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .md\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .md\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .md\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .md\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .md\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .md\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .md\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .md\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .md\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .md\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .md\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .md\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .md\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .md\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .md\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .md\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .md\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .md\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .md\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .md\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .md\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .md\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .md\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .md\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .md\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .lg\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .lg\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .lg\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .lg\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .lg\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .lg\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .lg\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .lg\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .lg\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .lg\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .lg\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .lg\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .lg\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .lg\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .lg\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .lg\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .lg\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .lg\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .lg\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .lg\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .lg\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .lg\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .lg\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .lg\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .lg\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .lg\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .lg\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .xl\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .xl\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .xl\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .xl\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .xl\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .xl\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .xl\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .xl\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .xl\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .xl\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .xl\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .xl\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .xl\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .xl\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .xl\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .xl\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .xl\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .xl\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .xl\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .xl\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .xl\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .xl\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .xl\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .xl\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .xl\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .xl\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .xl\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }

  .sm\:border-dashed {
    border-style: dashed !important;
  }

  .sm\:border-dotted {
    border-style: dotted !important;
  }

  .sm\:border-double {
    border-style: double !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }

  .md\:border-dashed {
    border-style: dashed !important;
  }

  .md\:border-dotted {
    border-style: dotted !important;
  }

  .md\:border-double {
    border-style: double !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }

  .lg\:border-dashed {
    border-style: dashed !important;
  }

  .lg\:border-dotted {
    border-style: dotted !important;
  }

  .lg\:border-double {
    border-style: double !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }

  .xl\:border-dashed {
    border-style: dashed !important;
  }

  .xl\:border-dotted {
    border-style: dotted !important;
  }

  .xl\:border-double {
    border-style: double !important;
  }
}

.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: .125rem !important;
}

.border-round-sm {
  border-radius: .25rem !important;
}

.border-round-md {
  border-radius: .375rem !important;
}

.border-round-lg {
  border-radius: .5rem !important;
}

.border-round-xl {
  border-radius: .75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }

  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .sm\:border-round-xs {
    border-radius: .125rem !important;
  }

  .sm\:border-round-sm {
    border-radius: .25rem !important;
  }

  .sm\:border-round-md {
    border-radius: .375rem !important;
  }

  .sm\:border-round-lg {
    border-radius: .5rem !important;
  }

  .sm\:border-round-xl {
    border-radius: .75rem !important;
  }

  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .sm\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }

  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .md\:border-round-xs {
    border-radius: .125rem !important;
  }

  .md\:border-round-sm {
    border-radius: .25rem !important;
  }

  .md\:border-round-md {
    border-radius: .375rem !important;
  }

  .md\:border-round-lg {
    border-radius: .5rem !important;
  }

  .md\:border-round-xl {
    border-radius: .75rem !important;
  }

  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .md\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }

  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .lg\:border-round-xs {
    border-radius: .125rem !important;
  }

  .lg\:border-round-sm {
    border-radius: .25rem !important;
  }

  .lg\:border-round-md {
    border-radius: .375rem !important;
  }

  .lg\:border-round-lg {
    border-radius: .5rem !important;
  }

  .lg\:border-round-xl {
    border-radius: .75rem !important;
  }

  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .lg\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }

  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .xl\:border-round-xs {
    border-radius: .125rem !important;
  }

  .xl\:border-round-sm {
    border-radius: .25rem !important;
  }

  .xl\:border-round-md {
    border-radius: .375rem !important;
  }

  .xl\:border-round-lg {
    border-radius: .5rem !important;
  }

  .xl\:border-round-xl {
    border-radius: .75rem !important;
  }

  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .xl\:border-circle {
    border-radius: 50% !important;
  }
}

.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: .125rem !important;
  border-bottom-left-radius: .125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: .125rem !important;
  border-top-right-radius: .125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: .125rem !important;
  border-bottom-right-radius: .125rem !important;
}

.border-round-bottom-xs {
  border-bottom-right-radius: .125rem !important;
  border-bottom-left-radius: .125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.border-round-bottom-sm {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.border-round-left-md {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.border-round-top-md {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.border-round-right-md {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.border-round-bottom-md {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.border-round-bottom-lg {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: .75rem !important;
  border-top-right-radius: .75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: .75rem !important;
  border-bottom-right-radius: .75rem !important;
}

.border-round-bottom-xl {
  border-bottom-right-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .sm\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .sm\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .sm\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .sm\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .sm\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .sm\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .sm\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .sm\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .sm\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .sm\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .sm\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .sm\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .sm\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .sm\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .sm\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .sm\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .sm\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .sm\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .sm\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .sm\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .sm\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .md\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .md\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .md\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .md\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .md\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .md\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .md\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .md\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .md\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .md\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .md\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .md\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .md\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .md\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .md\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .md\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .md\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .md\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .md\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .md\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .md\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .md\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lg\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .lg\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .lg\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .lg\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .lg\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .lg\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .lg\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .lg\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .lg\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .lg\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .lg\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .lg\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .lg\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .lg\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .lg\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .lg\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .lg\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .lg\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .lg\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .lg\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .lg\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xl\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .xl\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .xl\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .xl\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .xl\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .xl\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .xl\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .xl\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .xl\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .xl\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .xl\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .xl\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .xl\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .xl\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .xl\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .xl\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .xl\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .xl\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .xl\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .xl\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .xl\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-1 {
    width: 8.3333% !important;
  }

  .sm\:w-2 {
    width: 16.6667% !important;
  }

  .sm\:w-3 {
    width: 25% !important;
  }

  .sm\:w-4 {
    width: 33.3333% !important;
  }

  .sm\:w-5 {
    width: 41.6667% !important;
  }

  .sm\:w-6 {
    width: 50% !important;
  }

  .sm\:w-7 {
    width: 58.3333% !important;
  }

  .sm\:w-8 {
    width: 66.6667% !important;
  }

  .sm\:w-9 {
    width: 75% !important;
  }

  .sm\:w-10 {
    width: 83.3333% !important;
  }

  .sm\:w-11 {
    width: 91.6667% !important;
  }

  .sm\:w-12 {
    width: 100% !important;
  }

  .sm\:w-min {
    width: min-content !important;
  }

  .sm\:w-max {
    width: max-content !important;
  }

  .sm\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .sm\:w-1rem {
    width: 1rem !important;
  }

  .sm\:w-2rem {
    width: 2rem !important;
  }

  .sm\:w-3rem {
    width: 3rem !important;
  }

  .sm\:w-4rem {
    width: 4rem !important;
  }

  .sm\:w-5rem {
    width: 5rem !important;
  }

  .sm\:w-6rem {
    width: 6rem !important;
  }

  .sm\:w-7rem {
    width: 7rem !important;
  }

  .sm\:w-8rem {
    width: 8rem !important;
  }

  .sm\:w-9rem {
    width: 9rem !important;
  }

  .sm\:w-10rem {
    width: 10rem !important;
  }

  .sm\:w-11rem {
    width: 11rem !important;
  }

  .sm\:w-12rem {
    width: 12rem !important;
  }

  .sm\:w-13rem {
    width: 13rem !important;
  }

  .sm\:w-14rem {
    width: 14rem !important;
  }

  .sm\:w-15rem {
    width: 15rem !important;
  }

  .sm\:w-16rem {
    width: 16rem !important;
  }

  .sm\:w-17rem {
    width: 17rem !important;
  }

  .sm\:w-18rem {
    width: 18rem !important;
  }

  .sm\:w-19rem {
    width: 19rem !important;
  }

  .sm\:w-20rem {
    width: 20rem !important;
  }

  .sm\:w-21rem {
    width: 21rem !important;
  }

  .sm\:w-22rem {
    width: 22rem !important;
  }

  .sm\:w-23rem {
    width: 23rem !important;
  }

  .sm\:w-24rem {
    width: 24rem !important;
  }

  .sm\:w-25rem {
    width: 25rem !important;
  }

  .sm\:w-26rem {
    width: 26rem !important;
  }

  .sm\:w-27rem {
    width: 27rem !important;
  }

  .sm\:w-28rem {
    width: 28rem !important;
  }

  .sm\:w-29rem {
    width: 29rem !important;
  }

  .sm\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-1 {
    width: 8.3333% !important;
  }

  .md\:w-2 {
    width: 16.6667% !important;
  }

  .md\:w-3 {
    width: 25% !important;
  }

  .md\:w-4 {
    width: 33.3333% !important;
  }

  .md\:w-5 {
    width: 41.6667% !important;
  }

  .md\:w-6 {
    width: 50% !important;
  }

  .md\:w-7 {
    width: 58.3333% !important;
  }

  .md\:w-8 {
    width: 66.6667% !important;
  }

  .md\:w-9 {
    width: 75% !important;
  }

  .md\:w-10 {
    width: 83.3333% !important;
  }

  .md\:w-11 {
    width: 91.6667% !important;
  }

  .md\:w-12 {
    width: 100% !important;
  }

  .md\:w-min {
    width: min-content !important;
  }

  .md\:w-max {
    width: max-content !important;
  }

  .md\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .md\:w-1rem {
    width: 1rem !important;
  }

  .md\:w-2rem {
    width: 2rem !important;
  }

  .md\:w-3rem {
    width: 3rem !important;
  }

  .md\:w-4rem {
    width: 4rem !important;
  }

  .md\:w-5rem {
    width: 5rem !important;
  }

  .md\:w-6rem {
    width: 6rem !important;
  }

  .md\:w-7rem {
    width: 7rem !important;
  }

  .md\:w-8rem {
    width: 8rem !important;
  }

  .md\:w-9rem {
    width: 9rem !important;
  }

  .md\:w-10rem {
    width: 10rem !important;
  }

  .md\:w-11rem {
    width: 11rem !important;
  }

  .md\:w-12rem {
    width: 12rem !important;
  }

  .md\:w-13rem {
    width: 13rem !important;
  }

  .md\:w-14rem {
    width: 14rem !important;
  }

  .md\:w-15rem {
    width: 15rem !important;
  }

  .md\:w-16rem {
    width: 16rem !important;
  }

  .md\:w-17rem {
    width: 17rem !important;
  }

  .md\:w-18rem {
    width: 18rem !important;
  }

  .md\:w-19rem {
    width: 19rem !important;
  }

  .md\:w-20rem {
    width: 20rem !important;
  }

  .md\:w-21rem {
    width: 21rem !important;
  }

  .md\:w-22rem {
    width: 22rem !important;
  }

  .md\:w-23rem {
    width: 23rem !important;
  }

  .md\:w-24rem {
    width: 24rem !important;
  }

  .md\:w-25rem {
    width: 25rem !important;
  }

  .md\:w-26rem {
    width: 26rem !important;
  }

  .md\:w-27rem {
    width: 27rem !important;
  }

  .md\:w-28rem {
    width: 28rem !important;
  }

  .md\:w-29rem {
    width: 29rem !important;
  }

  .md\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-1 {
    width: 8.3333% !important;
  }

  .lg\:w-2 {
    width: 16.6667% !important;
  }

  .lg\:w-3 {
    width: 25% !important;
  }

  .lg\:w-4 {
    width: 33.3333% !important;
  }

  .lg\:w-5 {
    width: 41.6667% !important;
  }

  .lg\:w-6 {
    width: 50% !important;
  }

  .lg\:w-7 {
    width: 58.3333% !important;
  }

  .lg\:w-8 {
    width: 66.6667% !important;
  }

  .lg\:w-9 {
    width: 75% !important;
  }

  .lg\:w-10 {
    width: 83.3333% !important;
  }

  .lg\:w-11 {
    width: 91.6667% !important;
  }

  .lg\:w-12 {
    width: 100% !important;
  }

  .lg\:w-min {
    width: min-content !important;
  }

  .lg\:w-max {
    width: max-content !important;
  }

  .lg\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .lg\:w-1rem {
    width: 1rem !important;
  }

  .lg\:w-2rem {
    width: 2rem !important;
  }

  .lg\:w-3rem {
    width: 3rem !important;
  }

  .lg\:w-4rem {
    width: 4rem !important;
  }

  .lg\:w-5rem {
    width: 5rem !important;
  }

  .lg\:w-6rem {
    width: 6rem !important;
  }

  .lg\:w-7rem {
    width: 7rem !important;
  }

  .lg\:w-8rem {
    width: 8rem !important;
  }

  .lg\:w-9rem {
    width: 9rem !important;
  }

  .lg\:w-10rem {
    width: 10rem !important;
  }

  .lg\:w-11rem {
    width: 11rem !important;
  }

  .lg\:w-12rem {
    width: 12rem !important;
  }

  .lg\:w-13rem {
    width: 13rem !important;
  }

  .lg\:w-14rem {
    width: 14rem !important;
  }

  .lg\:w-15rem {
    width: 15rem !important;
  }

  .lg\:w-16rem {
    width: 16rem !important;
  }

  .lg\:w-17rem {
    width: 17rem !important;
  }

  .lg\:w-18rem {
    width: 18rem !important;
  }

  .lg\:w-19rem {
    width: 19rem !important;
  }

  .lg\:w-20rem {
    width: 20rem !important;
  }

  .lg\:w-21rem {
    width: 21rem !important;
  }

  .lg\:w-22rem {
    width: 22rem !important;
  }

  .lg\:w-23rem {
    width: 23rem !important;
  }

  .lg\:w-24rem {
    width: 24rem !important;
  }

  .lg\:w-25rem {
    width: 25rem !important;
  }

  .lg\:w-26rem {
    width: 26rem !important;
  }

  .lg\:w-27rem {
    width: 27rem !important;
  }

  .lg\:w-28rem {
    width: 28rem !important;
  }

  .lg\:w-29rem {
    width: 29rem !important;
  }

  .lg\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-1 {
    width: 8.3333% !important;
  }

  .xl\:w-2 {
    width: 16.6667% !important;
  }

  .xl\:w-3 {
    width: 25% !important;
  }

  .xl\:w-4 {
    width: 33.3333% !important;
  }

  .xl\:w-5 {
    width: 41.6667% !important;
  }

  .xl\:w-6 {
    width: 50% !important;
  }

  .xl\:w-7 {
    width: 58.3333% !important;
  }

  .xl\:w-8 {
    width: 66.6667% !important;
  }

  .xl\:w-9 {
    width: 75% !important;
  }

  .xl\:w-10 {
    width: 83.3333% !important;
  }

  .xl\:w-11 {
    width: 91.6667% !important;
  }

  .xl\:w-12 {
    width: 100% !important;
  }

  .xl\:w-min {
    width: min-content !important;
  }

  .xl\:w-max {
    width: max-content !important;
  }

  .xl\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .xl\:w-1rem {
    width: 1rem !important;
  }

  .xl\:w-2rem {
    width: 2rem !important;
  }

  .xl\:w-3rem {
    width: 3rem !important;
  }

  .xl\:w-4rem {
    width: 4rem !important;
  }

  .xl\:w-5rem {
    width: 5rem !important;
  }

  .xl\:w-6rem {
    width: 6rem !important;
  }

  .xl\:w-7rem {
    width: 7rem !important;
  }

  .xl\:w-8rem {
    width: 8rem !important;
  }

  .xl\:w-9rem {
    width: 9rem !important;
  }

  .xl\:w-10rem {
    width: 10rem !important;
  }

  .xl\:w-11rem {
    width: 11rem !important;
  }

  .xl\:w-12rem {
    width: 12rem !important;
  }

  .xl\:w-13rem {
    width: 13rem !important;
  }

  .xl\:w-14rem {
    width: 14rem !important;
  }

  .xl\:w-15rem {
    width: 15rem !important;
  }

  .xl\:w-16rem {
    width: 16rem !important;
  }

  .xl\:w-17rem {
    width: 17rem !important;
  }

  .xl\:w-18rem {
    width: 18rem !important;
  }

  .xl\:w-19rem {
    width: 19rem !important;
  }

  .xl\:w-20rem {
    width: 20rem !important;
  }

  .xl\:w-21rem {
    width: 21rem !important;
  }

  .xl\:w-22rem {
    width: 22rem !important;
  }

  .xl\:w-23rem {
    width: 23rem !important;
  }

  .xl\:w-24rem {
    width: 24rem !important;
  }

  .xl\:w-25rem {
    width: 25rem !important;
  }

  .xl\:w-26rem {
    width: 26rem !important;
  }

  .xl\:w-27rem {
    width: 27rem !important;
  }

  .xl\:w-28rem {
    width: 28rem !important;
  }

  .xl\:w-29rem {
    width: 29rem !important;
  }

  .xl\:w-30rem {
    width: 30rem !important;
  }
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }

  .sm\:h-screen {
    height: 100vh !important;
  }

  .sm\:h-auto {
    height: auto !important;
  }

  .sm\:h-min {
    height: min-content !important;
  }

  .sm\:h-max {
    height: max-content !important;
  }

  .sm\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .sm\:h-1rem {
    height: 1rem !important;
  }

  .sm\:h-2rem {
    height: 2rem !important;
  }

  .sm\:h-3rem {
    height: 3rem !important;
  }

  .sm\:h-4rem {
    height: 4rem !important;
  }

  .sm\:h-5rem {
    height: 5rem !important;
  }

  .sm\:h-6rem {
    height: 6rem !important;
  }

  .sm\:h-7rem {
    height: 7rem !important;
  }

  .sm\:h-8rem {
    height: 8rem !important;
  }

  .sm\:h-9rem {
    height: 9rem !important;
  }

  .sm\:h-10rem {
    height: 10rem !important;
  }

  .sm\:h-11rem {
    height: 11rem !important;
  }

  .sm\:h-12rem {
    height: 12rem !important;
  }

  .sm\:h-13rem {
    height: 13rem !important;
  }

  .sm\:h-14rem {
    height: 14rem !important;
  }

  .sm\:h-15rem {
    height: 15rem !important;
  }

  .sm\:h-16rem {
    height: 16rem !important;
  }

  .sm\:h-17rem {
    height: 17rem !important;
  }

  .sm\:h-18rem {
    height: 18rem !important;
  }

  .sm\:h-19rem {
    height: 19rem !important;
  }

  .sm\:h-20rem {
    height: 20rem !important;
  }

  .sm\:h-21rem {
    height: 21rem !important;
  }

  .sm\:h-22rem {
    height: 22rem !important;
  }

  .sm\:h-23rem {
    height: 23rem !important;
  }

  .sm\:h-24rem {
    height: 24rem !important;
  }

  .sm\:h-25rem {
    height: 25rem !important;
  }

  .sm\:h-26rem {
    height: 26rem !important;
  }

  .sm\:h-27rem {
    height: 27rem !important;
  }

  .sm\:h-28rem {
    height: 28rem !important;
  }

  .sm\:h-29rem {
    height: 29rem !important;
  }

  .sm\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:h-full {
    height: 100% !important;
  }

  .md\:h-screen {
    height: 100vh !important;
  }

  .md\:h-auto {
    height: auto !important;
  }

  .md\:h-min {
    height: min-content !important;
  }

  .md\:h-max {
    height: max-content !important;
  }

  .md\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .md\:h-1rem {
    height: 1rem !important;
  }

  .md\:h-2rem {
    height: 2rem !important;
  }

  .md\:h-3rem {
    height: 3rem !important;
  }

  .md\:h-4rem {
    height: 4rem !important;
  }

  .md\:h-5rem {
    height: 5rem !important;
  }

  .md\:h-6rem {
    height: 6rem !important;
  }

  .md\:h-7rem {
    height: 7rem !important;
  }

  .md\:h-8rem {
    height: 8rem !important;
  }

  .md\:h-9rem {
    height: 9rem !important;
  }

  .md\:h-10rem {
    height: 10rem !important;
  }

  .md\:h-11rem {
    height: 11rem !important;
  }

  .md\:h-12rem {
    height: 12rem !important;
  }

  .md\:h-13rem {
    height: 13rem !important;
  }

  .md\:h-14rem {
    height: 14rem !important;
  }

  .md\:h-15rem {
    height: 15rem !important;
  }

  .md\:h-16rem {
    height: 16rem !important;
  }

  .md\:h-17rem {
    height: 17rem !important;
  }

  .md\:h-18rem {
    height: 18rem !important;
  }

  .md\:h-19rem {
    height: 19rem !important;
  }

  .md\:h-20rem {
    height: 20rem !important;
  }

  .md\:h-21rem {
    height: 21rem !important;
  }

  .md\:h-22rem {
    height: 22rem !important;
  }

  .md\:h-23rem {
    height: 23rem !important;
  }

  .md\:h-24rem {
    height: 24rem !important;
  }

  .md\:h-25rem {
    height: 25rem !important;
  }

  .md\:h-26rem {
    height: 26rem !important;
  }

  .md\:h-27rem {
    height: 27rem !important;
  }

  .md\:h-28rem {
    height: 28rem !important;
  }

  .md\:h-29rem {
    height: 29rem !important;
  }

  .md\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:h-full {
    height: 100% !important;
  }

  .lg\:h-screen {
    height: 100vh !important;
  }

  .lg\:h-auto {
    height: auto !important;
  }

  .lg\:h-min {
    height: min-content !important;
  }

  .lg\:h-max {
    height: max-content !important;
  }

  .lg\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .lg\:h-1rem {
    height: 1rem !important;
  }

  .lg\:h-2rem {
    height: 2rem !important;
  }

  .lg\:h-3rem {
    height: 3rem !important;
  }

  .lg\:h-4rem {
    height: 4rem !important;
  }

  .lg\:h-5rem {
    height: 5rem !important;
  }

  .lg\:h-6rem {
    height: 6rem !important;
  }

  .lg\:h-7rem {
    height: 7rem !important;
  }

  .lg\:h-8rem {
    height: 8rem !important;
  }

  .lg\:h-9rem {
    height: 9rem !important;
  }

  .lg\:h-10rem {
    height: 10rem !important;
  }

  .lg\:h-11rem {
    height: 11rem !important;
  }

  .lg\:h-12rem {
    height: 12rem !important;
  }

  .lg\:h-13rem {
    height: 13rem !important;
  }

  .lg\:h-14rem {
    height: 14rem !important;
  }

  .lg\:h-15rem {
    height: 15rem !important;
  }

  .lg\:h-16rem {
    height: 16rem !important;
  }

  .lg\:h-17rem {
    height: 17rem !important;
  }

  .lg\:h-18rem {
    height: 18rem !important;
  }

  .lg\:h-19rem {
    height: 19rem !important;
  }

  .lg\:h-20rem {
    height: 20rem !important;
  }

  .lg\:h-21rem {
    height: 21rem !important;
  }

  .lg\:h-22rem {
    height: 22rem !important;
  }

  .lg\:h-23rem {
    height: 23rem !important;
  }

  .lg\:h-24rem {
    height: 24rem !important;
  }

  .lg\:h-25rem {
    height: 25rem !important;
  }

  .lg\:h-26rem {
    height: 26rem !important;
  }

  .lg\:h-27rem {
    height: 27rem !important;
  }

  .lg\:h-28rem {
    height: 28rem !important;
  }

  .lg\:h-29rem {
    height: 29rem !important;
  }

  .lg\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }

  .xl\:h-screen {
    height: 100vh !important;
  }

  .xl\:h-auto {
    height: auto !important;
  }

  .xl\:h-min {
    height: min-content !important;
  }

  .xl\:h-max {
    height: max-content !important;
  }

  .xl\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .xl\:h-1rem {
    height: 1rem !important;
  }

  .xl\:h-2rem {
    height: 2rem !important;
  }

  .xl\:h-3rem {
    height: 3rem !important;
  }

  .xl\:h-4rem {
    height: 4rem !important;
  }

  .xl\:h-5rem {
    height: 5rem !important;
  }

  .xl\:h-6rem {
    height: 6rem !important;
  }

  .xl\:h-7rem {
    height: 7rem !important;
  }

  .xl\:h-8rem {
    height: 8rem !important;
  }

  .xl\:h-9rem {
    height: 9rem !important;
  }

  .xl\:h-10rem {
    height: 10rem !important;
  }

  .xl\:h-11rem {
    height: 11rem !important;
  }

  .xl\:h-12rem {
    height: 12rem !important;
  }

  .xl\:h-13rem {
    height: 13rem !important;
  }

  .xl\:h-14rem {
    height: 14rem !important;
  }

  .xl\:h-15rem {
    height: 15rem !important;
  }

  .xl\:h-16rem {
    height: 16rem !important;
  }

  .xl\:h-17rem {
    height: 17rem !important;
  }

  .xl\:h-18rem {
    height: 18rem !important;
  }

  .xl\:h-19rem {
    height: 19rem !important;
  }

  .xl\:h-20rem {
    height: 20rem !important;
  }

  .xl\:h-21rem {
    height: 21rem !important;
  }

  .xl\:h-22rem {
    height: 22rem !important;
  }

  .xl\:h-23rem {
    height: 23rem !important;
  }

  .xl\:h-24rem {
    height: 24rem !important;
  }

  .xl\:h-25rem {
    height: 25rem !important;
  }

  .xl\:h-26rem {
    height: 26rem !important;
  }

  .xl\:h-27rem {
    height: 27rem !important;
  }

  .xl\:h-28rem {
    height: 28rem !important;
  }

  .xl\:h-29rem {
    height: 29rem !important;
  }

  .xl\:h-30rem {
    height: 30rem !important;
  }
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0 !important;
  }

  .sm\:min-w-full {
    min-width: 100% !important;
  }

  .sm\:min-w-screen {
    min-width: 100vw !important;
  }

  .sm\:min-w-min {
    min-width: min-content !important;
  }

  .sm\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0 !important;
  }

  .md\:min-w-full {
    min-width: 100% !important;
  }

  .md\:min-w-screen {
    min-width: 100vw !important;
  }

  .md\:min-w-min {
    min-width: min-content !important;
  }

  .md\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:min-w-0 {
    min-width: 0 !important;
  }

  .lg\:min-w-full {
    min-width: 100% !important;
  }

  .lg\:min-w-screen {
    min-width: 100vw !important;
  }

  .lg\:min-w-min {
    min-width: min-content !important;
  }

  .lg\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:min-w-0 {
    min-width: 0 !important;
  }

  .xl\:min-w-full {
    min-width: 100% !important;
  }

  .xl\:min-w-screen {
    min-width: 100vw !important;
  }

  .xl\:min-w-min {
    min-width: min-content !important;
  }

  .xl\:min-w-max {
    min-width: max-content !important;
  }
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0 !important;
  }

  .sm\:max-w-full {
    max-width: 100% !important;
  }

  .sm\:max-w-screen {
    max-width: 100vw !important;
  }

  .sm\:max-w-min {
    max-width: min-content !important;
  }

  .sm\:max-w-max {
    max-width: max-content !important;
  }

  .sm\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }

  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }

  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }

  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }

  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }

  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }

  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }

  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }

  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }

  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }

  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }

  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }

  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }

  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }

  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }

  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }

  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }

  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }

  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }

  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }

  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }

  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }

  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }

  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }

  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }

  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }

  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }

  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }

  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }

  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:max-w-0 {
    max-width: 0 !important;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  .md\:max-w-screen {
    max-width: 100vw !important;
  }

  .md\:max-w-min {
    max-width: min-content !important;
  }

  .md\:max-w-max {
    max-width: max-content !important;
  }

  .md\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .md\:max-w-1rem {
    max-width: 1rem !important;
  }

  .md\:max-w-2rem {
    max-width: 2rem !important;
  }

  .md\:max-w-3rem {
    max-width: 3rem !important;
  }

  .md\:max-w-4rem {
    max-width: 4rem !important;
  }

  .md\:max-w-5rem {
    max-width: 5rem !important;
  }

  .md\:max-w-6rem {
    max-width: 6rem !important;
  }

  .md\:max-w-7rem {
    max-width: 7rem !important;
  }

  .md\:max-w-8rem {
    max-width: 8rem !important;
  }

  .md\:max-w-9rem {
    max-width: 9rem !important;
  }

  .md\:max-w-10rem {
    max-width: 10rem !important;
  }

  .md\:max-w-11rem {
    max-width: 11rem !important;
  }

  .md\:max-w-12rem {
    max-width: 12rem !important;
  }

  .md\:max-w-13rem {
    max-width: 13rem !important;
  }

  .md\:max-w-14rem {
    max-width: 14rem !important;
  }

  .md\:max-w-15rem {
    max-width: 15rem !important;
  }

  .md\:max-w-16rem {
    max-width: 16rem !important;
  }

  .md\:max-w-17rem {
    max-width: 17rem !important;
  }

  .md\:max-w-18rem {
    max-width: 18rem !important;
  }

  .md\:max-w-19rem {
    max-width: 19rem !important;
  }

  .md\:max-w-20rem {
    max-width: 20rem !important;
  }

  .md\:max-w-21rem {
    max-width: 21rem !important;
  }

  .md\:max-w-22rem {
    max-width: 22rem !important;
  }

  .md\:max-w-23rem {
    max-width: 23rem !important;
  }

  .md\:max-w-24rem {
    max-width: 24rem !important;
  }

  .md\:max-w-25rem {
    max-width: 25rem !important;
  }

  .md\:max-w-26rem {
    max-width: 26rem !important;
  }

  .md\:max-w-27rem {
    max-width: 27rem !important;
  }

  .md\:max-w-28rem {
    max-width: 28rem !important;
  }

  .md\:max-w-29rem {
    max-width: 29rem !important;
  }

  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:max-w-0 {
    max-width: 0 !important;
  }

  .lg\:max-w-full {
    max-width: 100% !important;
  }

  .lg\:max-w-screen {
    max-width: 100vw !important;
  }

  .lg\:max-w-min {
    max-width: min-content !important;
  }

  .lg\:max-w-max {
    max-width: max-content !important;
  }

  .lg\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }

  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }

  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }

  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }

  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }

  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }

  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }

  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }

  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }

  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }

  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }

  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }

  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }

  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }

  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }

  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }

  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }

  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }

  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }

  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }

  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }

  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }

  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }

  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }

  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }

  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }

  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }

  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }

  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }

  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:max-w-0 {
    max-width: 0 !important;
  }

  .xl\:max-w-full {
    max-width: 100% !important;
  }

  .xl\:max-w-screen {
    max-width: 100vw !important;
  }

  .xl\:max-w-min {
    max-width: min-content !important;
  }

  .xl\:max-w-max {
    max-width: max-content !important;
  }

  .xl\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }

  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }

  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }

  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }

  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }

  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }

  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }

  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }

  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }

  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }

  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }

  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }

  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }

  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }

  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }

  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }

  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }

  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }

  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }

  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }

  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }

  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }

  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }

  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }

  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }

  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }

  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }

  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }

  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }

  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0 !important;
  }

  .sm\:min-h-full {
    min-height: 100% !important;
  }

  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:min-h-0 {
    min-height: 0 !important;
  }

  .md\:min-h-full {
    min-height: 100% !important;
  }

  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:min-h-0 {
    min-height: 0 !important;
  }

  .lg\:min-h-full {
    min-height: 100% !important;
  }

  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:min-h-0 {
    min-height: 0 !important;
  }

  .xl\:min-h-full {
    min-height: 100% !important;
  }

  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}

.max-h-0 {
  max-height: 0 !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: min-content !important;
}

.max-h-max {
  max-height: max-content !important;
}

.max-h-fit {
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0 !important;
  }

  .sm\:max-h-full {
    max-height: 100% !important;
  }

  .sm\:max-h-screen {
    max-height: 100vh !important;
  }

  .sm\:max-h-min {
    max-height: min-content !important;
  }

  .sm\:max-h-max {
    max-height: max-content !important;
  }

  .sm\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }

  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }

  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }

  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }

  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }

  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }

  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }

  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }

  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }

  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }

  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }

  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }

  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }

  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }

  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }

  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }

  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }

  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }

  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }

  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }

  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }

  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }

  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }

  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }

  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }

  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }

  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }

  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }

  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }

  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:max-h-0 {
    max-height: 0 !important;
  }

  .md\:max-h-full {
    max-height: 100% !important;
  }

  .md\:max-h-screen {
    max-height: 100vh !important;
  }

  .md\:max-h-min {
    max-height: min-content !important;
  }

  .md\:max-h-max {
    max-height: max-content !important;
  }

  .md\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .md\:max-h-1rem {
    max-height: 1rem !important;
  }

  .md\:max-h-2rem {
    max-height: 2rem !important;
  }

  .md\:max-h-3rem {
    max-height: 3rem !important;
  }

  .md\:max-h-4rem {
    max-height: 4rem !important;
  }

  .md\:max-h-5rem {
    max-height: 5rem !important;
  }

  .md\:max-h-6rem {
    max-height: 6rem !important;
  }

  .md\:max-h-7rem {
    max-height: 7rem !important;
  }

  .md\:max-h-8rem {
    max-height: 8rem !important;
  }

  .md\:max-h-9rem {
    max-height: 9rem !important;
  }

  .md\:max-h-10rem {
    max-height: 10rem !important;
  }

  .md\:max-h-11rem {
    max-height: 11rem !important;
  }

  .md\:max-h-12rem {
    max-height: 12rem !important;
  }

  .md\:max-h-13rem {
    max-height: 13rem !important;
  }

  .md\:max-h-14rem {
    max-height: 14rem !important;
  }

  .md\:max-h-15rem {
    max-height: 15rem !important;
  }

  .md\:max-h-16rem {
    max-height: 16rem !important;
  }

  .md\:max-h-17rem {
    max-height: 17rem !important;
  }

  .md\:max-h-18rem {
    max-height: 18rem !important;
  }

  .md\:max-h-19rem {
    max-height: 19rem !important;
  }

  .md\:max-h-20rem {
    max-height: 20rem !important;
  }

  .md\:max-h-21rem {
    max-height: 21rem !important;
  }

  .md\:max-h-22rem {
    max-height: 22rem !important;
  }

  .md\:max-h-23rem {
    max-height: 23rem !important;
  }

  .md\:max-h-24rem {
    max-height: 24rem !important;
  }

  .md\:max-h-25rem {
    max-height: 25rem !important;
  }

  .md\:max-h-26rem {
    max-height: 26rem !important;
  }

  .md\:max-h-27rem {
    max-height: 27rem !important;
  }

  .md\:max-h-28rem {
    max-height: 28rem !important;
  }

  .md\:max-h-29rem {
    max-height: 29rem !important;
  }

  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:max-h-0 {
    max-height: 0 !important;
  }

  .lg\:max-h-full {
    max-height: 100% !important;
  }

  .lg\:max-h-screen {
    max-height: 100vh !important;
  }

  .lg\:max-h-min {
    max-height: min-content !important;
  }

  .lg\:max-h-max {
    max-height: max-content !important;
  }

  .lg\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }

  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }

  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }

  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }

  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }

  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }

  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }

  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }

  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }

  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }

  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }

  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }

  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }

  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }

  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }

  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }

  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }

  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }

  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }

  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }

  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }

  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }

  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }

  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }

  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }

  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }

  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }

  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }

  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }

  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:max-h-0 {
    max-height: 0 !important;
  }

  .xl\:max-h-full {
    max-height: 100% !important;
  }

  .xl\:max-h-screen {
    max-height: 100vh !important;
  }

  .xl\:max-h-min {
    max-height: min-content !important;
  }

  .xl\:max-h-max {
    max-height: max-content !important;
  }

  .xl\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }

  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }

  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }

  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }

  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }

  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }

  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }

  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }

  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }

  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }

  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }

  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }

  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }

  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }

  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }

  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }

  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }

  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }

  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }

  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }

  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }

  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }

  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }

  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }

  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }

  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }

  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }

  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }

  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }

  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }

  .sm\:fixed {
    position: fixed !important;
  }

  .sm\:absolute {
    position: absolute !important;
  }

  .sm\:relative {
    position: relative !important;
  }

  .sm\:sticky {
    position: sticky !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:static {
    position: static !important;
  }

  .md\:fixed {
    position: fixed !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:relative {
    position: relative !important;
  }

  .md\:sticky {
    position: sticky !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:static {
    position: static !important;
  }

  .lg\:fixed {
    position: fixed !important;
  }

  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:relative {
    position: relative !important;
  }

  .lg\:sticky {
    position: sticky !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:static {
    position: static !important;
  }

  .xl\:fixed {
    position: fixed !important;
  }

  .xl\:absolute {
    position: absolute !important;
  }

  .xl\:relative {
    position: relative !important;
  }

  .xl\:sticky {
    position: sticky !important;
  }
}

.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }

  .sm\:top-0 {
    top: 0 !important;
  }

  .sm\:top-50 {
    top: 50% !important;
  }

  .sm\:top-100 {
    top: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:top-auto {
    top: auto !important;
  }

  .md\:top-0 {
    top: 0 !important;
  }

  .md\:top-50 {
    top: 50% !important;
  }

  .md\:top-100 {
    top: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:top-auto {
    top: auto !important;
  }

  .lg\:top-0 {
    top: 0 !important;
  }

  .lg\:top-50 {
    top: 50% !important;
  }

  .lg\:top-100 {
    top: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }

  .xl\:top-0 {
    top: 0 !important;
  }

  .xl\:top-50 {
    top: 50% !important;
  }

  .xl\:top-100 {
    top: 100% !important;
  }
}

.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }

  .sm\:left-0 {
    left: 0 !important;
  }

  .sm\:left-50 {
    left: 50% !important;
  }

  .sm\:left-100 {
    left: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:left-auto {
    left: auto !important;
  }

  .md\:left-0 {
    left: 0 !important;
  }

  .md\:left-50 {
    left: 50% !important;
  }

  .md\:left-100 {
    left: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:left-auto {
    left: auto !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:left-50 {
    left: 50% !important;
  }

  .lg\:left-100 {
    left: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }

  .xl\:left-0 {
    left: 0 !important;
  }

  .xl\:left-50 {
    left: 50% !important;
  }

  .xl\:left-100 {
    left: 100% !important;
  }
}

.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0 !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }

  .sm\:right-0 {
    right: 0 !important;
  }

  .sm\:right-50 {
    right: 50% !important;
  }

  .sm\:right-100 {
    right: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:right-auto {
    right: auto !important;
  }

  .md\:right-0 {
    right: 0 !important;
  }

  .md\:right-50 {
    right: 50% !important;
  }

  .md\:right-100 {
    right: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:right-auto {
    right: auto !important;
  }

  .lg\:right-0 {
    right: 0 !important;
  }

  .lg\:right-50 {
    right: 50% !important;
  }

  .lg\:right-100 {
    right: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }

  .xl\:right-0 {
    right: 0 !important;
  }

  .xl\:right-50 {
    right: 50% !important;
  }

  .xl\:right-100 {
    right: 100% !important;
  }
}

.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }

  .sm\:bottom-0 {
    bottom: 0 !important;
  }

  .sm\:bottom-50 {
    bottom: 50% !important;
  }

  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }

  .md\:bottom-0 {
    bottom: 0 !important;
  }

  .md\:bottom-50 {
    bottom: 50% !important;
  }

  .md\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lg\:bottom-50 {
    bottom: 50% !important;
  }

  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }

  .xl\:bottom-0 {
    bottom: 0 !important;
  }

  .xl\:bottom-50 {
    bottom: 50% !important;
  }

  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }

  .sm\:overflow-hidden {
    overflow: hidden !important;
  }

  .sm\:overflow-visible {
    overflow: visible !important;
  }

  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }

  .md\:overflow-hidden {
    overflow: hidden !important;
  }

  .md\:overflow-visible {
    overflow: visible !important;
  }

  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }

  .lg\:overflow-hidden {
    overflow: hidden !important;
  }

  .lg\:overflow-visible {
    overflow: visible !important;
  }

  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }

  .xl\:overflow-hidden {
    overflow: hidden !important;
  }

  .xl\:overflow-visible {
    overflow: visible !important;
  }

  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-1 {
    z-index: 1 !important;
  }

  .sm\:z-2 {
    z-index: 2 !important;
  }

  .sm\:z-3 {
    z-index: 3 !important;
  }

  .sm\:z-4 {
    z-index: 4 !important;
  }

  .sm\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-1 {
    z-index: 1 !important;
  }

  .md\:z-2 {
    z-index: 2 !important;
  }

  .md\:z-3 {
    z-index: 3 !important;
  }

  .md\:z-4 {
    z-index: 4 !important;
  }

  .md\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-1 {
    z-index: 1 !important;
  }

  .lg\:z-2 {
    z-index: 2 !important;
  }

  .lg\:z-3 {
    z-index: 3 !important;
  }

  .lg\:z-4 {
    z-index: 4 !important;
  }

  .lg\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-1 {
    z-index: 1 !important;
  }

  .xl\:z-2 {
    z-index: 2 !important;
  }

  .xl\:z-3 {
    z-index: 3 !important;
  }

  .xl\:z-4 {
    z-index: 4 !important;
  }

  .xl\:z-5 {
    z-index: 5 !important;
  }
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }

  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }

  .sm\:bg-cover {
    background-size: cover !important;
  }

  .sm\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }

  .md\:bg-cover {
    background-size: cover !important;
  }

  .md\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }

  .lg\:bg-cover {
    background-size: cover !important;
  }

  .lg\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }

  .xl\:bg-cover {
    background-size: cover !important;
  }

  .xl\:bg-contain {
    background-size: contain !important;
  }
}

.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: 0 !important;
}

.bg-left-bottom {
  background-position: 0 100% !important;
}

.bg-left-top {
  background-position: 0 0 !important;
}

.bg-right {
  background-position: 100% !important;
}

.bg-right-bottom {
  background-position: 100% 100% !important;
}

.bg-right-top {
  background-position: 100% 0 !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }

  .sm\:bg-center {
    background-position: center !important;
  }

  .sm\:bg-left {
    background-position: 0 !important;
  }

  .sm\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .sm\:bg-left-top {
    background-position: 0 0 !important;
  }

  .sm\:bg-right {
    background-position: 100% !important;
  }

  .sm\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .sm\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .sm\:bg-top {
    background-position: top !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }

  .md\:bg-center {
    background-position: center !important;
  }

  .md\:bg-left {
    background-position: 0 !important;
  }

  .md\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .md\:bg-left-top {
    background-position: 0 0 !important;
  }

  .md\:bg-right {
    background-position: 100% !important;
  }

  .md\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .md\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .md\:bg-top {
    background-position: top !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }

  .lg\:bg-center {
    background-position: center !important;
  }

  .lg\:bg-left {
    background-position: 0 !important;
  }

  .lg\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .lg\:bg-left-top {
    background-position: 0 0 !important;
  }

  .lg\:bg-right {
    background-position: 100% !important;
  }

  .lg\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .lg\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .lg\:bg-top {
    background-position: top !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }

  .xl\:bg-center {
    background-position: center !important;
  }

  .xl\:bg-left {
    background-position: 0 !important;
  }

  .xl\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .xl\:bg-left-top {
    background-position: 0 0 !important;
  }

  .xl\:bg-right {
    background-position: 100% !important;
  }

  .xl\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .xl\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .xl\:bg-top {
    background-position: top !important;
  }
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: .1s !important;
}

.transition-duration-150 {
  transition-duration: .15s !important;
}

.transition-duration-200 {
  transition-duration: .2s !important;
}

.transition-duration-300 {
  transition-duration: .3s !important;
}

.transition-duration-400 {
  transition-duration: .4s !important;
}

.transition-duration-500 {
  transition-duration: .5s !important;
}

.transition-duration-1000 {
  transition-duration: 1s !important;
}

.transition-duration-2000 {
  transition-duration: 2s !important;
}

.transition-duration-3000 {
  transition-duration: 3s !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-delay-100 {
  transition-delay: .1s !important;
}

.transition-delay-150 {
  transition-delay: .15s !important;
}

.transition-delay-200 {
  transition-delay: .2s !important;
}

.transition-delay-300 {
  transition-delay: .3s !important;
}

.transition-delay-400 {
  transition-delay: .4s !important;
}

.transition-delay-500 {
  transition-delay: .5s !important;
}

.transition-delay-1000 {
  transition-delay: 1s !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: 100% 0 !important;
}

.origin-right {
  transform-origin: 100% !important;
}

.origin-bottom-right {
  transform-origin: 100% 100% !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: 0 100% !important;
}

.origin-left {
  transform-origin: 0 !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }

  .sm\:origin-top {
    transform-origin: top !important;
  }

  .sm\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .sm\:origin-right {
    transform-origin: 100% !important;
  }

  .sm\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .sm\:origin-left {
    transform-origin: 0 !important;
  }

  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (min-width: 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }

  .md\:origin-top {
    transform-origin: top !important;
  }

  .md\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .md\:origin-right {
    transform-origin: 100% !important;
  }

  .md\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .md\:origin-left {
    transform-origin: 0 !important;
  }

  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }

  .lg\:origin-top {
    transform-origin: top !important;
  }

  .lg\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .lg\:origin-right {
    transform-origin: 100% !important;
  }

  .lg\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .lg\:origin-left {
    transform-origin: 0 !important;
  }

  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }

  .xl\:origin-top {
    transform-origin: top !important;
  }

  .xl\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .xl\:origin-right {
    transform-origin: 100% !important;
  }

  .xl\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .xl\:origin-left {
    transform-origin: 0 !important;
  }

  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scalein {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: scaleY(.8);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slidedown {
  0% {
    max-height: 0;
  }

  100% {
    max-height: auto;
  }
}

@keyframes slideup {
  0% {
    max-height: 1000px;
  }

  100% {
    max-height: 0;
  }
}

@keyframes fadeinleft {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes fadeinright {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutright {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fadeinup {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutup {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes flip {
  from {
    transform: perspective(2000px)rotateX(-100deg);
  }

  to {
    transform: perspective(2000px)rotateX(0);
  }
}

@keyframes flipleft {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateY(-100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateY(0);
  }
}

@keyframes flipright {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateY(100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateY(0);
  }
}

@keyframes flipup {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateX(-100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateX(0);
  }
}

@keyframes zoomin {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomindown {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

@keyframes zoominleft {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

@keyframes zoominright {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

@keyframes zoominup {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.fadein {
  animation: .15s linear fadein;
}

.fadeout {
  animation: .15s linear fadeout;
}

.slidedown {
  animation: .45s ease-in-out slidedown;
}

.slideup {
  animation: .45s cubic-bezier(0, 1, 0, 1) slideup;
}

.scalein {
  animation: .15s linear scalein;
}

.fadeinleft {
  animation: .15s linear fadeinleft;
}

.fadeoutleft {
  animation: .15s linear fadeoutleft;
}

.fadeinright {
  animation: .15s linear fadeinright;
}

.fadeoutright {
  animation: .15s linear fadeoutright;
}

.fadeinup {
  animation: .15s linear fadeinup;
}

.fadeoutup {
  animation: .15s linear fadeoutup;
}

.fadeindown {
  animation: .15s linear fadeindown;
}

.fadeoutdown {
  animation: .15s linear fadeoutdown;
}

.animate-width {
  animation: 1s linear animate-width;
}

.flip {
  backface-visibility: visible;
  animation: .15s linear flip;
}

.flipup {
  backface-visibility: visible;
  animation: .15s linear flipup;
}

.flipleft {
  backface-visibility: visible;
  animation: .15s linear flipleft;
}

.flipright {
  backface-visibility: visible;
  animation: .15s linear flipright;
}

.zoomin {
  animation: .15s linear zoomin;
}

.zoomindown {
  animation: .15s linear zoomindown;
}

.zoominleft {
  animation: .15s linear zoominleft;
}

.zoominright {
  animation: .15s linear zoominright;
}

.zoominup {
  animation: .15s linear zoominup;
}

.animation-duration-100 {
  animation-duration: .1s !important;
}

.animation-duration-150 {
  animation-duration: .15s !important;
}

.animation-duration-200 {
  animation-duration: .2s !important;
}

.animation-duration-300 {
  animation-duration: .3s !important;
}

.animation-duration-400 {
  animation-duration: .4s !important;
}

.animation-duration-500 {
  animation-duration: .5s !important;
}

.animation-duration-1000 {
  animation-duration: 1s !important;
}

.animation-duration-2000 {
  animation-duration: 2s !important;
}

.animation-duration-3000 {
  animation-duration: 3s !important;
}

.animation-delay-100 {
  animation-delay: .1s !important;
}

.animation-delay-150 {
  animation-delay: .15s !important;
}

.animation-delay-200 {
  animation-delay: .2s !important;
}

.animation-delay-300 {
  animation-delay: .3s !important;
}

.animation-delay-400 {
  animation-delay: .4s !important;
}

.animation-delay-500 {
  animation-delay: .5s !important;
}

.animation-delay-1000 {
  animation-delay: 1s !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}

:root {
  --surface-a: #fff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #fff;
  --surface-f: #fff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #fff;
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --surface-0: #fff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: 1rem;
  --inline-spacing: .5rem;
  --border-radius: 3px;
  --surface-ground: #f8f9fa;
  --surface-section: #fff;
  --surface-card: #fff;
  --surface-overlay: #fff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --focus-ring: 0 0 0 .2rem #a6d5fa;
  --maskbg: #0006;
  --highlight-bg: #e3f2fd;
  --highlight-text-color: #495057;
  color-scheme: light;
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: #0006;
  transition-duration: .2s;
}

.p-disabled, .p-component:disabled {
  opacity: .6;
}

.p-error {
  color: #f44336;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
}

.p-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-component-overlay-enter {
  animation: .15s forwards p-component-overlay-enter-animation;
}

.p-component-overlay-leave {
  animation: .15s forwards p-component-overlay-leave-animation;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: #0000;
  }

  to {
    background-color: var(--maskbg);
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }

  to {
    background-color: #0000;
  }
}

:root {
  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f6fbf6;
  --green-100: #d4ecd5;
  --green-200: #b2ddb4;
  --green-300: #90cd93;
  --green-400: #6ebe71;
  --green-500: #4caf50;
  --green-600: #419544;
  --green-700: #357b38;
  --green-800: #2a602c;
  --green-900: #1e4620;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f5f6fb;
  --indigo-100: #d1d5ed;
  --indigo-200: #acb4df;
  --indigo-300: #8893d1;
  --indigo-400: #6372c3;
  --indigo-500: #3f51b5;
  --indigo-600: #36459a;
  --indigo-700: #2c397f;
  --indigo-800: #232d64;
  --indigo-900: #192048;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fff8f2;
  --orange-100: #fde0c2;
  --orange-200: #fbc791;
  --orange-300: #f9ae61;
  --orange-400: #f79530;
  --orange-500: #f57c00;
  --orange-600: #d06900;
  --orange-700: #ac5700;
  --orange-800: #874400;
  --orange-900: #623200;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --red-50: #fff5f5;
  --red-100: #ffd1ce;
  --red-200: #ffada7;
  --red-300: #ff8980;
  --red-400: #ff6459;
  --red-500: #ff4032;
  --red-600: #d9362b;
  --red-700: #b32d23;
  --red-800: #8c231c;
  --red-900: #661a14;
  --primary-50: #f4fafe;
  --primary-100: #cae6fc;
  --primary-200: #a0d2fa;
  --primary-300: #75bef8;
  --primary-400: #4baaf5;
  --primary-500: #2196f3;
  --primary-600: #1c80cf;
  --primary-700: #1769aa;
  --primary-800: #125386;
  --primary-900: #0d3c61;
}

.p-autocomplete .p-autocomplete-loader {
  right: .5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: .25rem .5rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: .25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  color: #495057;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  color: #495057;
  background: #e3f2fd;
  border-radius: 3px;
  margin-right: .5rem;
  padding: .25rem .5rem;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: .5rem;
}

.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-autocomplete-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: .5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  background: #e9ecef;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  color: #495057;
  background: #fff;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-datepicker {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: .5rem;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #fff;
}

.p-datepicker .p-datepicker-header {
  color: #495057;
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem;
  font-weight: 600;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #495057;
  padding: .5rem;
  font-weight: 600;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #2196f3;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: .5rem;
}

.p-datepicker table {
  margin: .5rem 0;
  font-size: 1rem;
}

.p-datepicker table th {
  padding: .5rem;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: .5rem;
}

.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #0000;
  border-radius: 50%;
  transition: box-shadow .2s;
}

.p-datepicker table td > span.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datepicker table td > span:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datepicker table td.p-datepicker-today > span {
  color: #495057;
  background: #ced4da;
  border-color: #0000;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datepicker .p-datepicker-buttonbar {
  border-top: 1px solid #dee2e6;
  padding: 1rem 0;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: .5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-datepicker .p-timepicker button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: .2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
  padding: 0 .5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0;
}

.p-datepicker .p-monthpicker {
  margin: .5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  border-radius: 3px;
  padding: .5rem;
  transition: box-shadow .2s;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datepicker .p-yearpicker {
  margin: .5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  border-radius: 3px;
  padding: .5rem;
  transition: box-shadow .2s;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding: 0 .5rem;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  border-left: 0;
  padding-right: 0;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}

.p-cascadeselect {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-cascadeselect:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-cascadeselect:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-cascadeselect .p-cascadeselect-label {
  background: none;
  border: 0;
  padding: .5rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  box-shadow: none;
  outline: 0;
}

.p-cascadeselect .p-cascadeselect-trigger {
  color: #6c757d;
  width: 2.357rem;
  background: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-cascadeselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-cascadeselect-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-cascadeselect-panel .p-cascadeselect-items {
  padding: .5rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  transition: box-shadow .2s;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: .5rem 1rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: .875rem;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #fff;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-checkbox .p-checkbox-box {
  width: 20px;
  height: 20px;
  color: #495057;
  background: #fff;
  border: 2px solid #ced4da;
  border-radius: 3px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #fff;
  font-size: 14px;
  transition-duration: .2s;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #2196f3;
  border-color: #2196f3;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2196f3;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #2196f3;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #0b7ad1;
}

.p-chips .p-chips-multiple-container {
  padding: .25rem .5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  color: #495057;
  background: #dee2e6;
  border-radius: 16px;
  margin-right: .5rem;
  padding: .25rem .5rem;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: .5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: .25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  color: #495057;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
}

.p-chips.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border: 1px solid #191919;
}

.p-colorpicker-panel .p-colorpicker-color-handle, .p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #fff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-dropdown {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}

.p-dropdown .p-dropdown-label {
  background: none;
  border: 0;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  box-shadow: none;
  outline: 0;
}

.p-dropdown .p-dropdown-trigger {
  color: #6c757d;
  width: 2.357rem;
  background: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-dropdown.p-invalid.p-component {
  border-color: #f44336;
}

.p-dropdown-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-dropdown-panel .p-dropdown-header {
  color: #495057;
  background: #f8f9fa;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem 1rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin-right: -1.5rem;
  padding-right: 1.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  margin-right: -3rem;
  padding-right: 3rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 2rem;
}

.p-dropdown-panel .p-dropdown-items {
  padding: .5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  color: #495057;
  background: #fff;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  color: #495057;
  background: none;
  padding: .5rem 1rem;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #fff;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: #0000;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  color: #6c757d;
  border: 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  border: 0;
  border-radius: 3px;
  padding: .5rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #495057;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: .5rem 1rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-content .ql-editor {
  color: #495057;
  background: #fff;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover, .p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #2196f3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #2196f3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #2196f3;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #2196f3;
}

.p-inputgroup-addon {
  color: #6c757d;
  min-width: 2.357rem;
  background: #e9ecef;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  padding: .5rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0;
}

.p-inputgroup > .p-component:focus, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus, .p-inputgroup > .p-float-label > .p-component:focus, .p-inputgroup > .p-component:focus ~ label, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label, .p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child, .p-inputgroup > .p-inputwrapper:first-child, .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext, .p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext, .p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  border-radius: 30px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border-radius: 50%;
  margin-top: -.625rem;
  transition-duration: .2s;
  left: .25rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #b6bfc8;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #2196f3;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #fff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #0d89ec;
}

.p-inputswitch.p-invalid .p-inputswitch-slider {
  border-color: #f44336;
}

.p-inputtext {
  color: #495057;
  appearance: none;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-inputtext:enabled:hover {
  border-color: #2196f3;
}

.p-inputtext:enabled:focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-inputtext.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputtext.p-inputtext-sm {
  padding: .4375rem;
  font-size: .875rem;
}

.p-inputtext.p-inputtext-lg {
  padding: .625rem;
  font-size: 1.25rem;
}

.p-float-label > label {
  color: #6c757d;
  transition-duration: .2s;
  left: .5rem;
}

.p-float-label > label.p-error {
  color: #f44336;
}

.p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type, .p-input-icon-left > .p-input-prefix {
  color: #6c757d;
  left: .5rem;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2rem;
}

.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
  color: #6c757d;
  right: .5rem;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext, .p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: #fff;
}

.p-inputtext-sm .p-inputtext {
  padding: .4375rem;
  font-size: .875rem;
}

.p-inputtext-lg .p-inputtext {
  padding: .625rem;
  font-size: 1.25rem;
}

.p-listbox {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.p-listbox .p-listbox-header {
  color: #495057;
  background: #f8f9fa;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem 1rem;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.5rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-listbox .p-listbox-list {
  padding: .5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  color: #495057;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  color: #495057;
  background: #fff;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
  color: #495057;
  background: none;
  padding: .5rem 1rem;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-listbox.p-invalid {
  border-color: #f44336;
}

.p-mention-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-mention-panel .p-mention-items {
  padding: .5rem 0;
}

.p-mention-panel .p-mention-items .p-mention-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-mention-panel .p-mention-items .p-mention-item:hover {
  color: #495057;
  background: #e9ecef;
}

.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-multiselect {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.5rem;
}

.p-multiselect .p-multiselect-label {
  padding: .5rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  color: #495057;
  background: #dee2e6;
  border-radius: 16px;
  margin-right: .5rem;
  padding: .25rem .5rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: .5rem;
}

.p-multiselect .p-multiselect-trigger {
  color: #6c757d;
  width: 2.357rem;
  background: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-multiselect .p-multiselect-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-multiselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: .25rem .5rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: .5rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.5rem;
}

.p-multiselect-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-multiselect-panel .p-multiselect-header {
  color: #495057;
  background: #f8f9fa;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem 1rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 .5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items {
  padding: .5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: .5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  color: #495057;
  background: #fff;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  color: #495057;
  background: none;
  padding: .5rem 1rem;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #fff;
}

.p-password.p-invalid.p-component > .p-inputtext {
  border-color: #f44336;
}

.p-password-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  padding: 1rem;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-password-panel .p-password-meter {
  background: #dee2e6;
  margin-bottom: .5rem;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #d32f2f;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #fbc02d;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #689f38;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  width: 20px;
  height: 20px;
  color: #495057;
  background: #fff;
  border: 2px solid #ced4da;
  border-radius: 50%;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #2196f3;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition-duration: .2s;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #2196f3;
  border-color: #2196f3;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #f44336;
}

.p-radiobutton:focus {
  outline: 0;
}

.p-input-filled .p-radiobutton .p-radiobutton-box, .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #2196f3;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #0b7ad1;
}

.p-rating {
  gap: .5rem;
}

.p-rating .p-rating-item .p-rating-icon {
  color: #495057;
  font-size: 1.143rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}

.p-rating .p-rating-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon, .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
  color: #2196f3;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
  color: #c0392b;
}

.p-selectbutton .p-button {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
  border-color: #ced4da;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button.p-highlight {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #fff;
}

.p-selectbutton .p-button.p-highlight:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}

.p-selectbutton.p-invalid > .p-button {
  border-color: #f44336;
}

.p-slider {
  background: #dee2e6;
  border: 0;
  border-radius: 3px;
}

.p-slider.p-slider-horizontal {
  height: .286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -.5715rem;
  margin-left: -.5715rem;
}

.p-slider.p-slider-vertical {
  width: .286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-bottom: -.5715rem;
  margin-left: -.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #fff;
  border: 2px solid #2196f3;
  border-radius: 50%;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-slider .p-slider-handle:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-slider .p-slider-range {
  background: #2196f3;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #2196f3;
  border-color: #2196f3;
}

.p-treeselect {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-treeselect:not(.p-disabled):hover {
  border-color: #2196f3;
}

.p-treeselect:not(.p-disabled).p-focus {
  outline-offset: 0;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-treeselect .p-treeselect-label {
  padding: .5rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  color: #495057;
  background: #dee2e6;
  border-radius: 16px;
  margin-right: .5rem;
  padding: .25rem .5rem;
}

.p-treeselect .p-treeselect-trigger {
  color: #6c757d;
  width: 2.357rem;
  background: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-treeselect.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: .25rem .5rem;
}

.p-treeselect-panel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-treeselect-panel .p-treeselect-header {
  color: #495057;
  background: #f8f9fa;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem 1rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: .5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: 2rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  color: #495057;
  background: none;
  padding: .5rem 1rem;
}

.p-input-filled .p-treeselect {
  background: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #fff;
}

.p-togglebutton.p-button {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right {
  color: #6c757d;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
  border-color: #ced4da;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}

.p-togglebutton.p-button.p-highlight {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #fff;
}

.p-togglebutton.p-button.p-highlight:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #fff;
}

.p-togglebutton.p-button.p-invalid > .p-button {
  border-color: #f44336;
}

.p-button {
  color: #fff;
  background: #2196f3;
  border: 1px solid #2196f3;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-size: 1rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.p-button.p-button-outlined {
  color: #2196f3;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #2196f3;
  background: #2196f30a;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #2196f3;
  background: #2196f329;
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  color: #6c757d;
  background: #e9ecef;
}

.p-button.p-button-outlined.p-button-plain:enabled:active, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
  color: #6c757d;
  background: #dee2e6;
}

.p-button.p-button-text {
  color: #2196f3;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #2196f3;
  background: #2196f30a;
  border-color: #0000;
}

.p-button.p-button-text:enabled:active, .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #2196f3;
  background: #2196f329;
  border-color: #0000;
}

.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  color: #6c757d;
  background: #e9ecef;
}

.p-button.p-button-text.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
  color: #6c757d;
  background: #dee2e6;
}

.p-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-button .p-button-label {
  transition-duration: .2s;
}

.p-button .p-button-icon-left {
  margin-right: .5rem;
}

.p-button .p-button-icon-right {
  margin-left: .5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: .5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: .5rem;
}

.p-button .p-badge {
  min-width: 1rem;
  height: 1rem;
  color: #2196f3;
  background-color: #fff;
  margin-left: .5rem;
  line-height: 1rem;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: .5rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left, .p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: 2.357rem;
  border-radius: 50%;
}

.p-button.p-button-sm {
  padding: .4375rem .875rem;
  font-size: .875rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: .875rem;
}

.p-button.p-button-lg {
  padding: .625rem 1.25rem;
  font-size: 1.25rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: .5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
  margin-right: .5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
  margin-top: .5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
  margin-bottom: .5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
}

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 2.357rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
  color: #fff;
  background: #607d8b;
  border: 1px solid #607d8b;
}

.p-button.p-button-secondary:enabled:hover, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:enabled:hover, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #56717d;
  border-color: #56717d;
}

.p-button.p-button-secondary:enabled:focus, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:enabled:focus, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #beccd2;
}

.p-button.p-button-secondary:enabled:active, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:enabled:active, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #4d646f;
  border-color: #4d646f;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
  color: #607d8b;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #607d8b;
  background: #607d8b0a;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #607d8b;
  background: #607d8b29;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
  color: #607d8b;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-secondary.p-button-text:enabled:hover, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #607d8b;
  background: #607d8b0a;
  border-color: #0000;
}

.p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #607d8b;
  background: #607d8b29;
  border-color: #0000;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
  color: #fff;
  background: #0288d1;
  border: 1px solid #0288d1;
}

.p-button.p-button-info:enabled:hover, .p-button.p-button-info:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:enabled:hover, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #027abc;
  border-color: #027abc;
}

.p-button.p-button-info:enabled:focus, .p-button.p-button-info:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:enabled:focus, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #89d4fe;
}

.p-button.p-button-info:enabled:active, .p-button.p-button-info:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info:enabled:active, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #026da7;
  border-color: #026da7;
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
  color: #0288d1;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #0288d1;
  background: #0288d10a;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #0288d1;
  background: #0288d129;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
  color: #0288d1;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-info.p-button-text:enabled:hover, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:enabled:hover, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #0288d1;
  background: #0288d10a;
  border-color: #0000;
}

.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:enabled:active, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #0288d1;
  background: #0288d129;
  border-color: #0000;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
  color: #fff;
  background: #689f38;
  border: 1px solid #689f38;
}

.p-button.p-button-success:enabled:hover, .p-button.p-button-success:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:enabled:hover, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #5e8f32;
  border-color: #5e8f32;
}

.p-button.p-button-success:enabled:focus, .p-button.p-button-success:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:enabled:focus, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #c2e0a8;
}

.p-button.p-button-success:enabled:active, .p-button.p-button-success:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success:enabled:active, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #537f2d;
  border-color: #537f2d;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
  color: #689f38;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #689f38;
  background: #689f380a;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #689f38;
  background: #689f3829;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
  color: #689f38;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-success.p-button-text:enabled:hover, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:enabled:hover, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #689f38;
  background: #689f380a;
  border-color: #0000;
}

.p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:enabled:active, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #689f38;
  background: #689f3829;
  border-color: #0000;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
  color: #212529;
  background: #fbc02d;
  border: 1px solid #fbc02d;
}

.p-button.p-button-warning:enabled:hover, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:enabled:hover, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
  color: #212529;
  background: #fab710;
  border-color: #fab710;
}

.p-button.p-button-warning:enabled:focus, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:enabled:focus, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #fde6ab;
}

.p-button.p-button-warning:enabled:active, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:enabled:active, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
  color: #212529;
  background: #e8a704;
  border-color: #e8a704;
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
  color: #fbc02d;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #fbc02d;
  background: #fbc02d0a;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #fbc02d;
  background: #fbc02d29;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
  color: #fbc02d;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-warning.p-button-text:enabled:hover, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #fbc02d;
  background: #fbc02d0a;
  border-color: #0000;
}

.p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:enabled:active, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #fbc02d;
  background: #fbc02d29;
  border-color: #0000;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
  color: #fff;
  background: #9c27b0;
  border: 1px solid #9c27b0;
}

.p-button.p-button-help:enabled:hover, .p-button.p-button-help:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:enabled:hover, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #8c239e;
  border-color: #8c239e;
}

.p-button.p-button-help:enabled:focus, .p-button.p-button-help:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:enabled:focus, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #df9eea;
}

.p-button.p-button-help:enabled:active, .p-button.p-button-help:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help:enabled:active, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #7d1f8d;
  border-color: #7d1f8d;
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
  color: #9c27b0;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #9c27b0;
  background: #9c27b00a;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #9c27b0;
  background: #9c27b029;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
  color: #9c27b0;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-help.p-button-text:enabled:hover, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:enabled:hover, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #9c27b0;
  background: #9c27b00a;
  border-color: #0000;
}

.p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:enabled:active, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #9c27b0;
  background: #9c27b029;
  border-color: #0000;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
  color: #fff;
  background: #d32f2f;
  border: 1px solid #d32f2f;
}

.p-button.p-button-danger:enabled:hover, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:enabled:hover, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  background: #c02929;
  border-color: #c02929;
}

.p-button.p-button-danger:enabled:focus, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:enabled:focus, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 .2rem #edacac;
}

.p-button.p-button-danger:enabled:active, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:enabled:active, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  color: #fff;
  background: #aa2424;
  border-color: #aa2424;
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
  color: #d32f2f;
  background-color: #0000;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #d32f2f;
  background: #d32f2f0a;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: #d32f2f;
  background: #d32f2f29;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
  color: #d32f2f;
  background-color: #0000;
  border-color: #0000;
}

.p-button.p-button-danger.p-button-text:enabled:hover, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: #d32f2f;
  background: #d32f2f0a;
  border-color: #0000;
}

.p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:enabled:active, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: #d32f2f;
  background: #d32f2f29;
  border-color: #0000;
}

.p-button.p-button-link {
  color: #0b7ad1;
  background: none;
  border: #0000;
}

.p-button.p-button-link:enabled:hover, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  color: #0b7ad1;
  background: none;
  border-color: #0000;
}

.p-button.p-button-link:enabled:hover .p-button-label, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus, .p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: none;
  border-color: #0000;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-button.p-button-link:enabled:active, .p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  color: #0b7ad1;
  background: none;
  border-color: #0000;
}

.p-splitbutton {
  border-radius: 3px;
}

.p-splitbutton.p-button-outlined > .p-button {
  color: #2196f3;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #2196f3;
  background: #2196f30a;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #2196f3;
  background: #2196f329;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #6c757d;
  border-color: #6c757d;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #6c757d;
  background: #e9ecef;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #6c757d;
  background: #dee2e6;
}

.p-splitbutton.p-button-text > .p-button {
  color: #2196f3;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #2196f3;
  background: #2196f30a;
  border-color: #0000;
}

.p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #2196f3;
  background: #2196f329;
  border-color: #0000;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #6c757d;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #6c757d;
  background: #e9ecef;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #6c757d;
  background: #dee2e6;
}

.p-splitbutton.p-button-raised {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.p-splitbutton.p-button-rounded, .p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}

.p-splitbutton.p-button-sm > .p-button {
  padding: .4375rem .875rem;
  font-size: .875rem;
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: .875rem;
}

.p-splitbutton.p-button-lg > .p-button {
  padding: .625rem 1.25rem;
  font-size: 1.25rem;
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  color: #607d8b;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #607d8b;
  background: #607d8b0a;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #607d8b;
  background: #607d8b29;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  color: #607d8b;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #607d8b;
  background: #607d8b0a;
  border-color: #0000;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #607d8b;
  background: #607d8b29;
  border-color: #0000;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  color: #0288d1;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #0288d1;
  background: #0288d10a;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #0288d1;
  background: #0288d129;
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
  color: #0288d1;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #0288d1;
  background: #0288d10a;
  border-color: #0000;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #0288d1;
  background: #0288d129;
  border-color: #0000;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  color: #689f38;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #689f38;
  background: #689f380a;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #689f38;
  background: #689f3829;
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
  color: #689f38;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #689f38;
  background: #689f380a;
  border-color: #0000;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #689f38;
  background: #689f3829;
  border-color: #0000;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  color: #fbc02d;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #fbc02d;
  background: #fbc02d0a;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #fbc02d;
  background: #fbc02d29;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
  color: #fbc02d;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #fbc02d;
  background: #fbc02d0a;
  border-color: #0000;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #fbc02d;
  background: #fbc02d29;
  border-color: #0000;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  color: #9c27b0;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #9c27b0;
  background: #9c27b00a;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #9c27b0;
  background: #9c27b029;
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
  color: #9c27b0;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #9c27b0;
  background: #9c27b00a;
  border-color: #0000;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #9c27b0;
  background: #9c27b029;
  border-color: #0000;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  color: #d32f2f;
  background-color: #0000;
  border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #d32f2f;
  background: #d32f2f0a;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #d32f2f;
  background: #d32f2f29;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
  color: #d32f2f;
  background-color: #0000;
  border-color: #0000;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  color: #d32f2f;
  background: #d32f2f0a;
  border-color: #0000;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  color: #d32f2f;
  background: #d32f2f29;
  border-color: #0000;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  color: #fff;
  background: #495057;
}

.p-speeddial-action:hover {
  color: #fff;
  background: #343a40;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: .25rem;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: .5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: .25rem;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: .5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 .25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: .5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 .25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: .5rem;
}

.p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item, .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child, .p-speeddial-semi-circle .p-speeddial-item:first-child, .p-speeddial-semi-circle .p-speeddial-item:last-child, .p-speeddial-quarter-circle .p-speeddial-item:first-child, .p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: #0006;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  margin: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-bottom: .5rem;
  margin-right: .5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 2rem;
  height: .5rem;
  background-color: #e9ecef;
  border-radius: 0;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  color: #495057;
  background: #e3f2fd;
}

.p-datatable .p-paginator-top, .p-datatable .p-paginator-bottom {
  border-width: 0 0 1px;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 1px 0;
  padding: 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-footer {
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: .5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  height: 1.143rem;
  min-width: 1.143rem;
  color: #495057;
  background: #e3f2fd;
  border-radius: 50%;
  margin-left: .5rem;
  line-height: 1.143rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #2196f3;
  background: #f8f9fa;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196f3;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  color: #2196f3;
  background: #e9ecef;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #2196f3;
}

.p-datatable .p-sortable-column:focus {
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-datatable .p-datatable-tbody > tr {
  color: #495057;
  background: #fff;
  transition: box-shadow .2s;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: .5rem;
}

.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr > td.p-highlight, .p-datatable .p-datatable-tbody > tr.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px #e3f2fd;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px #e3f2fd;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  color: #495057;
  background: #e9ecef;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline-offset: -.15rem;
  outline: .15rem solid #a6d5fa;
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
  outline-offset: -.15rem;
  outline: .15rem solid #a6d5fa;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  color: #495057;
  background: #e9ecef;
}

.p-datatable .p-column-resizer-helper {
  background: #2196f3;
}

.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: #f8f9fa;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: #fcfcfc;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler, .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
  background: #fcfcfc;
}

.p-datatable.p-datatable-sm .p-datatable-header, .p-datatable.p-datatable-sm .p-datatable-thead > tr > th, .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td, .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-sm .p-datatable-footer {
  padding: .5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header, .p-datatable.p-datatable-lg .p-datatable-thead > tr > th, .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td, .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem;
}

.p-datatable-drag-selection-helper {
  background: #2196f329;
}

.p-dataview .p-paginator-top, .p-dataview .p-paginator-bottom {
  border-width: 0 0 1px;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 1px 0;
  padding: 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  color: #495057;
  background: #fff;
  border: 0;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
}

.p-dataview .p-dataview-footer {
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-datascroller .p-paginator-top, .p-datascroller .p-paginator-bottom {
  border-width: 0 0 1px;
  border-radius: 0;
}

.p-datascroller .p-datascroller-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 1px 0;
  padding: 1rem;
  font-weight: 600;
}

.p-datascroller .p-datascroller-content {
  color: #495057;
  background: #fff;
  border: 0;
  padding: 0;
}

.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
}

.p-datascroller .p-datascroller-footer {
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1rem;
  font-weight: 600;
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
  margin-left: .5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-column-filter-menu-button:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  color: #495057;
  background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: #495057;
  background: #e3f2fd;
}

.p-column-filter-menu-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-column-filter-clear-button:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-column-filter-clear-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-column-filter-overlay {
  color: #495057;
  min-width: 12.5rem;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: .5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  color: #495057;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: box-shadow .2s;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  color: #495057;
  background: #f8f9fa;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .5rem 1rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: .5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: .5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: .5rem 1rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc.fc-unthemed .fc-view-container th {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  color: #495057;
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-view {
  background: #fff;
}

.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-view-container .fc-event {
  color: #fff;
  background: #0d89ec;
  border: 1px solid #0d89ec;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}

.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #fff;
  background: #2196f3;
  border: 1px solid #2196f3;
  border-radius: 3px;
  align-items: center;
  font-size: 1rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  display: flex;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  text-indent: 0;
  font-size: 1rem;
  font-family: PrimeIcons !important;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  text-indent: 0;
  font-size: 1rem;
  font-family: PrimeIcons !important;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #ced4da;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline-offset: 0;
  z-index: 1;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness th {
  color: #495057;
  background: #f8f9fa;
  border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness td {
  color: #495057;
  border-color: #dee2e6;
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #fff;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
  display: flex;
  overflow: hidden;
  font-family: PrimeIcons !important;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "";
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-top: 0;
  padding: 1rem;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #fff;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  color: #495057;
  background: #e9ecef;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #f8f9fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #fff;
  background: #2196f3;
  border: 1px solid #2196f3;
  border-radius: 3px;
  font-size: 1rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  color: #fff;
  background: #0b7ad1;
  border-color: #0b7ad1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: .6;
  color: #fff;
  background: #2196f3;
  border: 1px solid #2196f3;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  text-indent: 0;
  font-size: 1rem;
  font-family: PrimeIcons !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  text-indent: 0;
  font-size: 1rem;
  font-family: PrimeIcons !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  color: #495057;
  background: #fff;
  border: 1px solid #ced4da;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #ced4da;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  color: #fff;
  background: #0d89ec;
  border-color: #0d89ec;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline-offset: 0;
  z-index: 1;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard .fc-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: .5rem;
}

.p-orderlist .p-orderlist-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
  font-weight: 600;
}

.p-orderlist .p-orderlist-filter-container {
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  padding: 1rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.5rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-orderlist .p-orderlist-list {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: .5rem 0;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  color: #495057;
  background: none;
  border: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: transform .2s, box-shadow .2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n), .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n):hover {
  background: #e9ecef;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #6cbbf5;
}

.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-left {
  border-color: #dee2e6;
  border-right-style: solid;
  border-right-width: 1px;
}

.p-organizationchart .p-organizationchart-line-top {
  border-color: #dee2e6;
  border-top-style: solid;
  border-top-width: 1px;
}

.p-organizationchart .p-organizationchart-node-content {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  padding: 1rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-paginator {
  color: #6c757d;
  background: #fff;
  border: 0 solid #e9ecef;
  border-radius: 3px;
  padding: .5rem 1rem;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  background-color: #0000;
  border: 0;
  border-radius: 3px;
  margin: .143rem;
  transition: box-shadow .2s;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-paginator .p-dropdown {
  height: 2.357rem;
  margin-left: .5rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-page-input {
  margin-left: .5rem;
  margin-right: .5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2.357rem;
}

.p-paginator .p-paginator-current {
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  background-color: #0000;
  border: 0;
  margin: .143rem;
  padding: 0 .5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  background-color: #0000;
  border: 0;
  border-radius: 3px;
  margin: .143rem;
  transition: box-shadow .2s;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #495057;
  background: #e3f2fd;
  border-color: #e3f2fd;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: .5rem;
}

.p-picklist .p-picklist-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
  font-weight: 600;
}

.p-picklist .p-picklist-filter-container {
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  padding: 1rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-picklist .p-picklist-list {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: .5rem 0;
}

.p-picklist .p-picklist-list .p-picklist-item {
  color: #495057;
  background: none;
  border: 0;
  margin: 0;
  padding: .5rem 1rem;
  transition: transform .2s, box-shadow .2s;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-tree {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
}

.p-tree .p-tree-container .p-treenode {
  padding: .143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 3px;
  padding: 0;
  transition: box-shadow .2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  margin-right: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: .5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  color: #495057;
  background: #e9ecef;
}

.p-tree .p-tree-filter-container {
  margin-bottom: .5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  color: #6c757d;
  right: .5rem;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint {
  height: .5rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #89c8f7;
}

.p-treetable .p-paginator-top, .p-treetable .p-paginator-bottom {
  border-width: 0 0 1px;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-width: 1px 0;
  padding: 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-footer {
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  color: #495057;
  background: #f8f9fa;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
  font-weight: 600;
}

.p-treetable .p-sortable-column {
  outline-color: #a6d5fa;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: .5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  height: 1.143rem;
  min-width: 1.143rem;
  color: #495057;
  background: #e3f2fd;
  border-radius: 50%;
  margin-left: .5rem;
  line-height: 1.143rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-treetable .p-sortable-column.p-highlight {
  color: #2196f3;
  background: #f8f9fa;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #2196f3;
}

.p-treetable .p-treetable-tbody > tr {
  color: #495057;
  background: #fff;
  transition: box-shadow .2s;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 0 solid #e9ecef;
  border-bottom-width: 1px;
  padding: 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  margin-right: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
  margin-right: .5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-treetable .p-treetable-tbody > tr:focus {
  outline-offset: -.15rem;
  outline: .15rem solid #a6d5fa;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  color: #495057;
  background: #e3f2fd;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler, .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #495057;
}

.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  color: #495057;
  background: #e9ecef;
}

.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}

.p-treetable .p-column-resizer-helper {
  background: #2196f3;
}

.p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th, .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td, .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: .875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th, .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td, .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-sm .p-treetable-footer {
  padding: .5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header, .p-treetable.p-treetable-lg .p-treetable-thead > tr > th, .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td, .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem;
}

.p-timeline .p-timeline-event-marker {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: 2px solid #2196f3;
  border-radius: 50%;
}

.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite, .p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: .5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  color: #495057;
  background: #f8f9fa;
  border-color: #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-accordion .p-accordion-content {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  padding: 1rem;
}

.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link, .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-card {
  color: #495057;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.p-card .p-card-body {
  padding: 1rem;
}

.p-card .p-card-title {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.p-card .p-card-subtitle {
  color: #6c757d;
  margin-bottom: .5rem;
  font-weight: 400;
}

.p-card .p-card-content {
  padding: 1rem 0;
}

.p-card .p-card-footer {
  padding: 1rem 0 0;
}

.p-fieldset {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.p-fieldset .p-fieldset-legend {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
  font-weight: 600;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  color: #495057;
  border-radius: 3px;
  padding: 1rem;
  transition: box-shadow .2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: .5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-divider .p-divider-content {
  background-color: #fff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 .5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: .5rem 0;
}

.p-panel .p-panel-header {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: .5rem 1rem;
}

.p-panel .p-panel-content {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1rem;
}

.p-panel .p-panel-footer {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-top: 0;
  padding: .5rem 1rem;
}

.p-splitter {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.p-splitter .p-splitter-gutter {
  background: #f8f9fa;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle, .p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0;
}

.p-tabview .p-tabview-nav {
  background: #fff;
  border: 0 solid #dee2e6;
  border-bottom-width: 2px;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #6c757d;
  background: #fff;
  border: 0 solid #0000;
  border-bottom: 2px solid #dee2e6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0 0 -2px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .2rem #a6d5fa;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  color: #6c757d;
  background: #fff;
  border-color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #2196f3;
  background: #fff;
  border-color: #2196f3;
}

.p-tabview .p-tabview-close {
  margin-left: .5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  color: #2196f3;
  width: 2.357rem;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .2rem #a6d5fa;
}

.p-tabview .p-tabview-panels {
  color: #495057;
  background: #fff;
  border: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1rem;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  gap: .5rem;
  padding: 1rem;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 .5rem;
}

.p-confirm-popup {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1rem 1rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  width: auto;
  margin: 0 .5rem 0 0;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after, .p-confirm-popup:before {
  border: solid #fff0;
  border-bottom-color: #fff;
}

.p-confirm-popup.p-confirm-popup-flipped:after, .p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #fff;
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.p-dialog .p-dialog-header {
  color: #495057;
  background: #fff;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  margin-right: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  color: #495057;
  background: #fff;
  padding: 0 1.5rem 2rem;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer {
  color: #495057;
  text-align: right;
  background: #fff;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0 1.5rem 1.5rem;
}

.p-dialog .p-dialog-footer button {
  width: auto;
  margin: 0 .5rem 0 0;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  color: #495057;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-overlaypanel .p-overlaypanel-close {
  color: #fff;
  width: 2rem;
  height: 2rem;
  background: #2196f3;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  color: #fff;
  background: #0d89ec;
}

.p-overlaypanel:after {
  border: solid #fff0;
  border-bottom-color: #fff;
}

.p-overlaypanel:before {
  border: solid #fff0;
  border-bottom-color: #f2f2f2;
}

.p-overlaypanel.p-overlaypanel-flipped:after, .p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #fff;
}

.p-sidebar {
  color: #495057;
  background: #fff;
  border: 0;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #0000;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 1rem;
}

.p-tooltip .p-tooltip-text {
  color: #fff;
  background: #495057;
  border-radius: 3px;
  padding: .5rem;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  gap: .5rem;
  padding: 1rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: .5rem;
}

.p-fileupload .p-fileupload-content {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 2rem 1rem;
}

.p-fileupload .p-progressbar {
  height: .25rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-breadcrumb {
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  border-radius: 3px;
  transition: box-shadow .2s;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  color: #495057;
  margin: 0 .5rem;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  color: #495057;
  width: 12.5rem;
  background: #fff;
  border: 0;
  border-radius: 3px;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-contextmenu .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-contextmenu .p-submenu-list {
  background: #fff;
  border: 0;
  border-radius: 3px;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: .875rem;
  transition: transform .2s;
}

.p-dock .p-dock-list {
  background: #ffffff1a;
  border: 1px solid #fff3;
  border-radius: .5rem;
  padding: .5rem;
}

.p-dock .p-dock-item {
  padding: .5rem;
}

.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
  margin: 0 .9rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
  margin: .9rem 0;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-container, .p-dock.p-dock-bottom .p-dock-container {
    width: 100%;
    overflow-x: auto;
  }

  .p-dock.p-dock-top .p-dock-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-container .p-dock-list {
    margin: 0 auto;
  }

  .p-dock.p-dock-left .p-dock-container, .p-dock.p-dock-right .p-dock-container {
    height: 100%;
    overflow-y: auto;
  }

  .p-dock.p-dock-left .p-dock-container .p-dock-list, .p-dock.p-dock-right .p-dock-container .p-dock-list {
    margin: auto 0;
  }

  .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
    margin: 0;
    transform: none;
  }
}

.p-megamenu {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: .5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: .5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-megamenu .p-megamenu-panel {
  color: #495057;
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-megamenu .p-megamenu-submenu-header {
  color: #495057;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-megamenu .p-megamenu-submenu {
  width: 12.5rem;
  padding: .25rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: .25rem 0;
}

.p-megamenu .p-megamenu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-megamenu .p-megamenu-button:hover {
  color: #6c757d;
  background: #e9ecef;
}

.p-megamenu .p-megamenu-button:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-megamenu .p-submenu-icon {
  transition: transform .2s;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
  background: #fff;
  border: 0;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
  font-size: .875rem;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem {
  width: 100%;
  position: static;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  margin-left: auto;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
  transform: rotate(-180deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
  width: 100%;
  box-shadow: none;
  border: 0;
  position: static;
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
  transition: transform .2s;
  transform: rotate(90deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
  transform: rotate(-90deg);
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
  width: 100%;
  position: static;
}

.p-menu {
  color: #495057;
  width: 12.5rem;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: .25rem 0;
}

.p-menu .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menu .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-menu.p-menu-overlay {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-menu .p-submenu-header {
  color: #495057;
  background: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: 600;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-menubar {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: .5rem;
}

.p-menubar .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: .5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-menubar .p-submenu-list {
  width: 12.5rem;
  background: #fff;
  border: 0;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: .875rem;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }

  .p-menubar .p-menubar-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s;
    display: flex;
  }

  .p-menubar .p-menubar-button:hover {
    color: #6c757d;
    background: #e9ecef;
  }

  .p-menubar .p-menubar-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a6d5fa;
  }

  .p-menubar .p-menubar-root-list {
    width: 100%;
    background: #fff;
    border: 0;
    padding: .25rem 0;
    display: none;
    position: absolute;
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  }

  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0;
  }

  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: #495057;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 0;
    padding: .75rem 1rem;
    transition: box-shadow .2s;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: .5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #6c757d;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #a6d5fa;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform .2s;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    box-shadow: none;
    border: 0;
    position: static;
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    z-index: 1;
    flex-direction: column;
    display: flex;
    top: 100%;
    left: 0;
  }
}

.p-panelmenu .p-panelmenu-header > a {
  color: #495057;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon, .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: .5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  color: #495057;
  background: #f8f9fa;
  border-color: #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.p-panelmenu .p-panelmenu-content {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  padding: .25rem 0;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: .5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a, .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a, .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-slidemenu {
  color: #495057;
  width: 12.5rem;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: .25rem 0;
}

.p-slidemenu .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-slidemenu.p-slidemenu-overlay {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-slidemenu .p-slidemenu-list {
  background: #fff;
  border: 0;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #495057;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #6c757d;
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: .875rem;
}

.p-slidemenu .p-slidemenu-backward {
  color: #495057;
  padding: .75rem 1rem;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: #fff;
  border-radius: 3px;
  transition: box-shadow .2s;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  min-width: 2rem;
  height: 2rem;
  z-index: 1;
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 50%;
  font-size: 1.143rem;
  line-height: 2rem;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: #6c757d;
  margin-top: .5rem;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  color: #495057;
  background: #e3f2fd;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: #495057;
  font-weight: 600;
}

.p-steps .p-steps-item:before {
  content: " ";
  width: 100%;
  border-top: 1px solid #dee2e6;
  margin-top: -1rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.p-tabmenu .p-tabmenu-nav {
  background: #fff;
  border: 0 solid #dee2e6;
  border-bottom-width: 2px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: #6c757d;
  height: calc(100% + 2px);
  background: #fff;
  border: 0 solid #0000;
  border-bottom: 2px solid #dee2e6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0 0 -2px;
  padding: 1rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: .5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .2rem #a6d5fa;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  color: #6c757d;
  background: #fff;
  border-color: #6c757d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: #2196f3;
  background: #fff;
  border-color: #2196f3;
}

.p-tieredmenu {
  color: #495057;
  width: 12.5rem;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: .25rem 0;
}

.p-tieredmenu .p-menuitem-link {
  color: #495057;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
  padding: .75rem 1rem;
  transition: box-shadow .2s;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: .5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon, .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: inset 0 0 0 .15rem #a6d5fa;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-tieredmenu .p-submenu-list {
  background: #fff;
  border: 0;
  padding: .25rem 0;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: .25rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: .875rem;
  transition: transform .2s;
}

.p-inline-message {
  border-radius: 3px;
  margin: 0;
  padding: .5rem;
}

.p-inline-message.p-inline-message-info {
  color: #044868;
  background: #b3e5fc;
  border: 0 solid #0891cf;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #044868;
}

.p-inline-message.p-inline-message-success {
  color: #224a23;
  background: #c8e6c9;
  border: 0 solid #439446;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #224a23;
}

.p-inline-message.p-inline-message-warn {
  color: #6d5100;
  background: #ffecb3;
  border: 0 solid #d9a300;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #6d5100;
}

.p-inline-message.p-inline-message-error {
  color: #73000c;
  background: #ffcdd2;
  border: 0 solid #e60017;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #73000c;
}

.p-inline-message .p-inline-message-icon {
  margin-right: .5rem;
  font-size: 1rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  border-radius: 3px;
  margin: 1rem 0;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  background: none;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-message .p-message-close:hover {
  background: #ffffff4d;
}

.p-message .p-message-close:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-message.p-message-info {
  color: #044868;
  background: #b3e5fc;
  border: 0 solid #0891cf;
  border-left-width: 6px;
}

.p-message.p-message-info .p-message-icon, .p-message.p-message-info .p-message-close {
  color: #044868;
}

.p-message.p-message-success {
  color: #224a23;
  background: #c8e6c9;
  border: 0 solid #439446;
  border-left-width: 6px;
}

.p-message.p-message-success .p-message-icon, .p-message.p-message-success .p-message-close {
  color: #224a23;
}

.p-message.p-message-warn {
  color: #6d5100;
  background: #ffecb3;
  border: 0 solid #d9a300;
  border-left-width: 6px;
}

.p-message.p-message-warn .p-message-icon, .p-message.p-message-warn .p-message-close {
  color: #6d5100;
}

.p-message.p-message-error {
  color: #73000c;
  background: #ffcdd2;
  border: 0 solid #e60017;
  border-left-width: 6px;
}

.p-message.p-message-error .p-message-icon, .p-message.p-message-error .p-message-close {
  color: #73000c;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}

.p-message .p-message-icon {
  margin-right: .5rem;
  font-size: 1.5rem;
}

.p-toast {
  opacity: .9;
}

.p-toast .p-toast-message {
  border-radius: 3px;
  margin: 0 0 1rem;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-toast .p-toast-message .p-toast-message-content {
  border-width: 0 0 0 6px;
  padding: 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: .5rem 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  background: none;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: #ffffff4d;
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-toast .p-toast-message.p-toast-message-info {
  color: #044868;
  background: #b3e5fc;
  border: 0 solid #0891cf;
  border-left-width: 6px;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #044868;
}

.p-toast .p-toast-message.p-toast-message-success {
  color: #224a23;
  background: #c8e6c9;
  border: 0 solid #439446;
  border-left-width: 6px;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #224a23;
}

.p-toast .p-toast-message.p-toast-message-warn {
  color: #6d5100;
  background: #ffecb3;
  border: 0 solid #d9a300;
  border-left-width: 6px;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #6d5100;
}

.p-toast .p-toast-message.p-toast-message-error {
  color: #73000c;
  background: #ffcdd2;
  border: 0 solid #e60017;
  border-left-width: 6px;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #73000c;
}

.p-galleria .p-galleria-close {
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  background: none;
  border-radius: 50%;
  margin: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
  color: #f8f9fa;
  background: #ffffff1a;
}

.p-galleria .p-galleria-item-nav {
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  background: none;
  border-radius: 3px;
  margin: 0 .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  color: #f8f9fa;
  background: #ffffff1a;
}

.p-galleria .p-galleria-caption {
  color: #f8f9fa;
  background: #00000080;
  padding: 1rem;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  width: 1rem;
  height: 1rem;
  background-color: #e9ecef;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  color: #495057;
  background: #e3f2fd;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: .5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: .5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: #00000080;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: #fff6;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #fff9;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  color: #495057;
  background: #e3f2fd;
}

.p-galleria .p-galleria-thumbnail-container {
  background: #000000e6;
  padding: 1rem .25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  color: #f8f9fa;
  width: 2rem;
  height: 2rem;
  background-color: #0000;
  border-radius: 50%;
  margin: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  color: #f8f9fa;
  background: #ffffff1a;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow .2s;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-galleria-mask, .p-image-mask {
  --maskbg: #000000e6;
}

.p-image-preview-indicator {
  color: #f8f9fa;
  background-color: #0000;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: #00000080;
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  width: 3rem;
  height: 3rem;
  background-color: #0000;
  border-radius: 50%;
  margin-right: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-image-action.p-link:last-child {
  margin-right: 0;
}

.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: #ffffff1a;
}

.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 3px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #fff;
}

.p-chip {
  color: #495057;
  background-color: #dee2e6;
  border-radius: 16px;
  padding: 0 .5rem;
}

.p-chip .p-chip-text {
  margin-top: .25rem;
  margin-bottom: .25rem;
  line-height: 1.5;
}

.p-chip .p-chip-icon {
  margin-right: .5rem;
}

.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -.5rem;
  margin-right: .5rem;
}

.p-chip .p-chip-remove-icon {
  border-radius: 3px;
  margin-left: .5rem;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-chip .p-chip-remove-icon:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.p-scrolltop.p-link {
  background: #000000b3;
}

.p-scrolltop.p-link:hover {
  background: #000c;
}

.p-scrolltop .p-scrolltop-icon {
  color: #f8f9fa;
  font-size: 1.5rem;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 3px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, #fff0, #fff6, #fff0);
}

.p-tag {
  color: #fff;
  background: #2196f3;
  border-radius: 3px;
  padding: .25rem .4rem;
  font-size: .75rem;
  font-weight: 700;
}

.p-tag.p-tag-success {
  color: #fff;
  background-color: #689f38;
}

.p-tag.p-tag-info {
  color: #fff;
  background-color: #0288d1;
}

.p-tag.p-tag-warning {
  color: #212529;
  background-color: #fbc02d;
}

.p-tag.p-tag-danger {
  color: #fff;
  background-color: #d32f2f;
}

.p-tag .p-tag-icon {
  margin-right: .25rem;
  font-size: .75rem;
}

.p-inplace .p-inplace-display {
  border-radius: 3px;
  padding: .5rem;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-inplace .p-inplace-display:focus {
  outline-offset: 0;
  outline: 0;
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.p-progressbar {
  height: 1.5rem;
  background: #dee2e6;
  border: 0;
  border-radius: 3px;
}

.p-progressbar .p-progressbar-value {
  background: #2196f3;
  border: 0;
  margin: 0;
}

.p-progressbar .p-progressbar-label {
  color: #fff;
  line-height: 1.5rem;
}

.p-terminal {
  color: #495057;
  background: #fff;
  border: 1px solid #dee2e6;
  padding: 1rem;
}

.p-terminal .p-terminal-input {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
}

.p-badge {
  color: #fff;
  min-width: 1.5rem;
  height: 1.5rem;
  background: #2196f3;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  color: #fff;
  background-color: #607d8b;
}

.p-badge.p-badge-success {
  color: #fff;
  background-color: #689f38;
}

.p-badge.p-badge-info {
  color: #fff;
  background-color: #0288d1;
}

.p-badge.p-badge-warning {
  color: #212529;
  background-color: #fbc02d;
}

.p-badge.p-badge-danger {
  color: #fff;
  background-color: #d32f2f;
}

.p-badge.p-badge-lg {
  min-width: 2.25rem;
  height: 2.25rem;
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  min-width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  line-height: 3rem;
}

.p-tag {
  color: #fff;
  background: #2196f3;
  border-radius: 3px;
  padding: .25rem .4rem;
  font-size: .75rem;
  font-weight: 700;
}

.p-tag.p-tag-success {
  color: #fff;
  background-color: #689f38;
}

.p-tag.p-tag-info {
  color: #fff;
  background-color: #0288d1;
}

.p-tag.p-tag-warning {
  color: #212529;
  background-color: #fbc02d;
}

.p-tag.p-tag-danger {
  color: #fff;
  background-color: #d32f2f;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button, .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: #2196f3;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px #2196f3;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px #2196f3;
}

.p-component, .p-component * {
  box-sizing: border-box;
}

.p-hidden {
  display: none;
}

.p-hidden-space {
  visibility: hidden;
}

.p-hidden-accessible {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.p-hidden-accessible input, .p-hidden-accessible select {
  transform: scale(0);
}

.p-reset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
  list-style: none;
}

.p-disabled, .p-disabled * {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default !important;
}

.p-component-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.p-overflow-hidden {
  overflow: hidden;
}

.p-unselectable-text {
  -webkit-user-select: none;
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@keyframes p-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.p-link {
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
}

.p-link:disabled {
  cursor: default;
}

.p-connected-overlay {
  opacity: 0;
  transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
  transform: scaleY(.8);
}

.p-connected-overlay-visible {
  opacity: 1;
  transform: scaleY(1);
}

.p-connected-overlay-hidden {
  opacity: 0;
  transition: opacity .1s linear;
  transform: scaleY(1);
}

.p-connected-overlay-enter {
  opacity: 0;
  transform: scaleY(.8);
}

.p-connected-overlay-enter-active {
  opacity: 1;
  transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
  transform: scaleY(1);
}

.p-connected-overlay-enter-done {
  transform: none;
}

.p-connected-overlay-exit {
  opacity: 1;
}

.p-connected-overlay-exit-active {
  opacity: 0;
  transition: opacity .1s linear;
}

.p-toggleable-content-enter {
  max-height: 0;
}

.p-toggleable-content-enter-active {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
}

.p-toggleable-content-enter-done {
  transform: none;
}

.p-toggleable-content-exit {
  max-height: 1000px;
}

.p-toggleable-content-exit-active {
  max-height: 0;
  transition: max-height .45s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.p-sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  word-wrap: normal !important;
}

.p-accordion-header-link {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.p-accordion-header-link:focus {
  z-index: 1;
}

.p-accordion-header-text {
  line-height: 1;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
}

.p-autocomplete-loader {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-autocomplete-dd .p-autocomplete-input {
  width: 1%;
  flex: auto;
}

.p-autocomplete-dd .p-autocomplete-input, .p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
}

.p-autocomplete-panel {
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.p-autocomplete-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-autocomplete-multiple-container {
  cursor: text;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: hidden;
}

.p-autocomplete-token {
  cursor: default;
  flex: none;
  align-items: center;
  display: inline-flex;
}

.p-autocomplete-token-icon {
  cursor: pointer;
}

.p-autocomplete-input-token {
  flex: auto;
  display: inline-flex;
}

.p-autocomplete-input-token input {
  box-shadow: none;
  width: 100%;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

.p-fluid .p-autocomplete {
  display: flex;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
  width: 1%;
}

.p-avatar {
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: inline-flex;
}

.p-avatar.p-avatar-image {
  background-color: #0000;
}

.p-avatar-circle, .p-avatar-circle img {
  border-radius: 50%;
}

.p-avatar .p-avatar-icon {
  font-size: 1rem;
}

.p-avatar img {
  width: 100%;
  height: 100%;
}

.p-avatar-clickable {
  cursor: pointer;
}

.p-avatar-group .p-avatar + .p-avatar {
  margin-left: -1rem;
}

.p-avatar-group {
  align-items: center;
  display: flex;
}

.p-badge {
  text-align: center;
  border-radius: 10px;
  padding: 0 .5rem;
  display: inline-block;
}

.p-overlay-badge {
  position: relative;
}

.p-overlay-badge .p-badge {
  transform-origin: 100% 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.p-badge-dot {
  width: .5rem;
  min-width: .5rem;
  height: .5rem;
  border-radius: 50%;
  padding: 0;
}

.p-badge-no-gutter {
  border-radius: 50%;
  padding: 0;
}

.p-blockui-container {
  position: relative;
}

.p-blockui {
  width: 100%;
  height: 100%;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.p-blockui.p-component-overlay {
  position: absolute;
}

.p-blockui-document.p-component-overlay {
  position: fixed;
}

.p-breadcrumb {
  overflow-x: auto;
}

.p-breadcrumb ul {
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.p-breadcrumb .p-menuitem-text {
  line-height: 1;
}

.p-breadcrumb .p-menuitem-link {
  align-items: center;
  text-decoration: none;
  display: flex;
}

.p-breadcrumb::-webkit-scrollbar {
  display: none;
}

.p-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  text-align: center;
  align-items: center;
  margin: 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-button-label {
  flex: auto;
}

.p-button-icon-right {
  order: 1;
}

.p-button:disabled {
  cursor: default;
}

.p-button-icon-only {
  justify-content: center;
}

.p-button-icon-only .p-button-label {
  visibility: hidden;
  width: 0;
  flex: none;
}

.p-button-vertical {
  flex-direction: column;
}

.p-button-icon-bottom {
  order: 2;
}

.p-buttonset .p-button {
  margin: 0;
}

.p-buttonset .p-button:not(:last-child) {
  border-right: 0;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-buttonset .p-button:focus {
  z-index: 1;
  position: relative;
}

.p-calendar {
  max-width: 100%;
  display: inline-flex;
  position: relative;
}

.p-calendar .p-inputtext {
  width: 1%;
  flex: auto;
}

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn-right .p-datepicker-trigger, .p-calendar-w-btn-left .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-calendar-w-btn-left .p-datepicker-trigger {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

.p-calendar .p-datepicker {
  min-width: 100%;
}

.p-datepicker {
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.p-datepicker-inline {
  display: inline-block;
  position: static;
  overflow-x: auto;
}

.p-datepicker-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 auto;
}

.p-datepicker-prev, .p-datepicker-next {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

.p-datepicker-multiple-month .p-datepicker-group-container .p-datepicker-group {
  flex: auto;
}

.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-monthpicker-month {
  width: 33.3%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-datepicker-buttonbar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-timepicker {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-timepicker button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-timepicker > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.p-datepicker-touch-ui, .p-calendar .p-datepicker-touch-ui {
  min-width: 80vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-yearpicker-year {
  width: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-card-header img {
  width: 100%;
}

.p-carousel {
  flex-direction: column;
  display: flex;
}

.p-carousel-content {
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.p-carousel-prev, .p-carousel-next {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-carousel-container {
  flex-direction: row;
  display: flex;
}

.p-carousel-items-content {
  width: 100%;
  overflow: hidden;
}

.p-carousel-items-container {
  flex-direction: row;
  display: flex;
}

.p-carousel-indicators {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

.p-carousel-indicator > button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-carousel-vertical .p-carousel-container {
  flex-direction: column;
}

.p-carousel-vertical .p-carousel-items-container {
  height: 100%;
  flex-direction: column;
}

.p-items-hidden .p-carousel-item {
  visibility: hidden;
}

.p-items-hidden .p-carousel-item.p-carousel-item-active {
  visibility: visible;
}

.p-chart {
  position: relative;
}

.p-cascadeselect {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
  position: relative;
}

.p-cascadeselect-trigger {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-cascadeselect-label {
  white-space: nowrap;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
  flex: auto;
  display: block;
  overflow: hidden;
}

.p-cascadeselect-label-empty {
  visibility: hidden;
  overflow: hidden;
}

.p-cascadeselect .p-cascadeselect-panel {
  min-width: 100%;
}

.p-cascadeselect-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-cascadeselect-item {
  cursor: pointer;
  white-space: nowrap;
  font-weight: normal;
}

.p-cascadeselect-item-content {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-cascadeselect-group-icon {
  margin-left: auto;
}

.p-cascadeselect-items {
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-fluid .p-cascadeselect {
  display: flex;
}

.p-fluid .p-cascadeselect .p-cascadeselect-label {
  width: 1%;
}

.p-cascadeselect-sublist {
  min-width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
}

.p-cascadeselect-item-active {
  overflow: visible !important;
}

.p-cascadeselect-item-active > .p-cascadeselect-sublist {
  display: block;
  top: 0;
  left: 100%;
}

.p-chips {
  display: inline-flex;
}

.p-chips-multiple-container {
  cursor: text;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: hidden;
}

.p-chips-token {
  cursor: default;
  flex: none;
  align-items: center;
  display: inline-flex;
}

.p-chips-input-token {
  flex: auto;
  display: inline-flex;
}

.p-chips-token-icon {
  cursor: pointer;
}

.p-chips-input-token input {
  box-shadow: none;
  width: 100%;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

.p-fluid .p-chips {
  display: flex;
}

.p-checkbox {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  display: inline-flex;
  position: relative;
}

.p-checkbox.p-checkbox-disabled {
  cursor: auto;
}

.p-checkbox-box {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-chip {
  align-items: center;
  display: inline-flex;
}

.p-chip-text, .p-chip-icon.pi {
  line-height: 1.5;
}

.p-chip .p-chip-remove-icon {
  cursor: pointer;
  line-height: 1.5;
}

.p-chip img {
  border-radius: 50%;
}

.p-colorpicker {
  display: inline-block;
}

.p-colorpicker-dragging {
  cursor: pointer;
}

.p-colorpicker-overlay {
  position: relative;
}

.p-colorpicker-panel {
  width: 193px;
  height: 166px;
  position: relative;
}

.p-colorpicker-overlay-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-colorpicker-preview {
  cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-content {
  position: relative;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.p-colorpicker-panel .p-colorpicker-color {
  width: 150px;
  height: 150px;
}

.p-colorpicker-panel .p-colorpicker-color-handle {
  width: 10px;
  height: 10px;
  cursor: pointer;
  opacity: .85;
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 0;
  left: 150px;
}

.p-colorpicker-panel .p-colorpicker-hue {
  width: 17px;
  height: 150px;
  opacity: .85;
  position: absolute;
  top: 8px;
  left: 167px;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
  width: 21px;
  height: 10px;
  opacity: .85;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  margin-top: -5px;
  margin-left: -2px;
  position: absolute;
  top: 150px;
  left: 0;
}

.p-colorpicker-panel .p-colorpicker-color {
  background: url("color.13a9216c.png") 0 0 no-repeat;
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: url("hue.297c63da.png") 0 0 no-repeat;
}

.p-confirm-popup {
  margin-top: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.p-confirm-popup-flipped {
  margin-top: 0;
  margin-bottom: 10px;
}

.p-confirm-popup:after, .p-confirm-popup:before {
  bottom: 100%;
  left: calc(var(--overlayArrowLeft, 0)  + 1.25rem);
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
}

.p-confirm-popup:after {
  border-width: 8px;
  margin-left: -8px;
}

.p-confirm-popup:before {
  border-width: 10px;
  margin-left: -10px;
}

.p-confirm-popup-flipped:after, .p-confirm-popup-flipped:before {
  top: 100%;
  bottom: auto;
}

.p-confirm-popup.p-confirm-popup-flipped:after, .p-confirm-popup.p-confirm-popup-flipped:before {
  border-bottom-color: #0000;
}

.p-confirm-popup .p-confirm-popup-content {
  align-items: center;
  display: flex;
}

.p-contextmenu {
  position: absolute;
}

.p-contextmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-contextmenu .p-submenu-list {
  min-width: 100%;
  z-index: 1;
  position: absolute;
}

.p-contextmenu .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-contextmenu .p-menuitem-text {
  line-height: 1;
}

.p-contextmenu .p-menuitem {
  position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-contextmenu-enter {
  opacity: 0;
}

.p-contextmenu-enter-active {
  opacity: 1;
  transition: opacity .25s;
}

.p-datascroller .p-datascroller-header {
  text-align: center;
  border-bottom: 0;
  padding: .5em .75em;
}

.p-datascroller .p-datascroller-footer {
  text-align: center;
  border-top: 0;
  padding: .25em .625em;
}

.p-datascroller .p-datascroller-content {
  padding: .25em .625em;
}

.p-datascroller-inline .p-datascroller-content {
  overflow: auto;
}

.p-datascroller .p-datascroller-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-datatable {
  position: relative;
}

.p-datatable > .p-datatable-wrapper {
  overflow: auto;
}

.p-datatable-table {
  border-spacing: 0;
  width: 100%;
}

.p-datatable .p-sortable-disabled {
  cursor: auto;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.p-datatable .p-sortable-column .p-column-title, .p-datatable .p-sortable-column .p-sortable-column-icon, .p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: middle;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.p-datatable-selectable .p-selectable-row, .p-datatable-selectable-cell .p-selectable-cell {
  cursor: pointer;
}

.p-datatable-drag-selection-helper {
  z-index: 99999999;
  position: absolute;
}

.p-datatable-scrollable > .p-datatable-wrapper {
  position: relative;
}

.p-datatable-scrollable-table > .p-datatable-thead {
  z-index: 1;
  position: sticky;
  top: 0;
}

.p-datatable-scrollable-table > .p-datatable-frozen-tbody {
  z-index: 1;
  position: sticky;
}

.p-datatable-scrollable-table > .p-datatable-tfoot {
  z-index: 1;
  position: sticky;
  bottom: 0;
}

.p-datatable-scrollable .p-frozen-column {
  background: inherit;
  position: sticky;
}

.p-datatable-scrollable th.p-frozen-column {
  z-index: 1;
}

.p-datatable-flex-scrollable {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.p-datatable-flex-scrollable > .p-datatable-wrapper {
  height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.p-datatable-scrollable-table > .p-datatable-tbody > .p-rowgroup-header {
  z-index: 1;
  position: sticky;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th, .p-datatable-resizable-table > .p-datatable-tfoot > tr > td, .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th.p-resizable-column:not(.p-frozen-column) {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-resizable-table-fit > .p-datatable-thead > tr > th.p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer {
  width: .5rem;
  height: 100%;
  cursor: col-resize;
  border: 1px solid #0000;
  margin: 0;
  padding: 0;
  display: block;
  top: 0;
  right: 0;
  position: absolute !important;
}

.p-datatable .p-column-header-content {
  align-items: center;
  display: flex;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  z-index: 10;
  display: none;
  position: absolute;
}

.p-datatable .p-row-editor-init, .p-datatable .p-row-editor-save, .p-datatable .p-row-editor-cancel, .p-datatable .p-row-toggler {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-datatable-reorder-indicator-up, .p-datatable-reorder-indicator-down {
  display: none;
  position: absolute;
}

.p-reorderable-column, .p-datatable-reorderablerow-handle {
  cursor: move;
}

.p-datatable .p-datatable-loading-overlay {
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.p-column-filter-row {
  width: 100%;
  align-items: center;
  display: flex;
}

.p-column-filter-menu {
  margin-left: auto;
  display: inline-flex;
}

.p-column-filter-row .p-column-filter-element {
  width: 1%;
  flex: auto;
}

.p-column-filter-menu-button, .p-column-filter-clear-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-column-filter-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.p-column-filter-row-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-column-filter-row-item {
  cursor: pointer;
}

.p-column-filter-add-button, .p-column-filter-remove-button {
  justify-content: center;
}

.p-column-filter-add-button .p-button-label, .p-column-filter-remove-button .p-button-label {
  flex-grow: 0;
}

.p-column-filter-buttonbar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
  width: auto;
}

.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}

.p-datatable-virtualscroller-spacer {
  display: flex;
}

.p-datatable .p-virtualscroller .p-virtualscroller-loading {
  min-height: 0;
  position: sticky;
  top: 0;
  left: 0;
  transform: none !important;
}

.p-datatable .p-datatable-thead > tr > th.p-align-left > .p-column-header-content, .p-datatable .p-datatable-tbody > tr > td.p-align-left, .p-datatable .p-datatable-tfoot > tr > td.p-align-left {
  text-align: left;
  justify-content: flex-start;
}

.p-datatable .p-datatable-thead > tr > th.p-align-right > .p-column-header-content, .p-datatable .p-datatable-tbody > tr > td.p-align-right, .p-datatable .p-datatable-tfoot > tr > td.p-align-right {
  text-align: right;
  justify-content: flex-end;
}

.p-datatable .p-datatable-thead > tr > th.p-align-center > .p-column-header-content, .p-datatable .p-datatable-tbody > tr > td.p-align-center, .p-datatable .p-datatable-tfoot > tr > td.p-align-center {
  text-align: center;
  justify-content: center;
}

.p-dataview-loading {
  min-height: 4rem;
  position: relative;
}

.p-dataview .p-dataview-loading-overlay {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.p-dialog-mask {
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  transition-property: background-color;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.p-dialog-visible {
  display: flex;
}

.p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-dialog {
  pointer-events: auto;
  max-height: 90%;
  flex-direction: column;
  display: flex;
  position: relative;
  transform: scale(1);
}

.p-dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}

.p-dialog-header {
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.p-dialog-footer {
  flex-shrink: 0;
}

.p-dialog .p-dialog-header-icons {
  flex-shrink: 0;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.p-dialog .p-dialog-header-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-dialog .p-dialog-title {
  flex-grow: 1;
}

.p-fluid .p-dialog-footer .p-button {
  width: auto;
}

.p-dialog-enter {
  opacity: 0;
  transform: scale(.7);
}

.p-dialog-enter-active {
  opacity: 1;
  transition: all .15s cubic-bezier(0, 0, .2, 1);
  transform: scale(1);
}

.p-dialog-enter-done {
  transform: none;
}

.p-dialog-exit-active {
  opacity: 0;
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
  transform: scale(.7);
}

.p-dialog-top .p-dialog, .p-dialog-bottom .p-dialog, .p-dialog-left .p-dialog, .p-dialog-right .p-dialog, .p-dialog-top-left .p-dialog, .p-dialog-top-right .p-dialog, .p-dialog-bottom-left .p-dialog, .p-dialog-bottom-right .p-dialog {
  margin: .75em;
}

.p-dialog-top .p-dialog-enter, .p-dialog-top .p-dialog-exit-active {
  transform: translate3d(0, -100%, 0);
}

.p-dialog-bottom .p-dialog-enter, .p-dialog-bottom .p-dialog-exit-active {
  transform: translate3d(0, 100%, 0);
}

.p-dialog-left .p-dialog-enter, .p-dialog-left .p-dialog-exit-active, .p-dialog-top-left .p-dialog-enter, .p-dialog-top-left .p-dialog-exit-active, .p-dialog-bottom-left .p-dialog-enter, .p-dialog-bottom-left .p-dialog-exit-active {
  transform: translate3d(-100%, 0, 0);
}

.p-dialog-right .p-dialog-enter, .p-dialog-right .p-dialog-exit-active, .p-dialog-top-right .p-dialog-enter, .p-dialog-top-right .p-dialog-exit-active, .p-dialog-bottom-right .p-dialog-enter, .p-dialog-bottom-right .p-dialog-exit-active {
  transform: translate3d(100%, 0, 0);
}

.p-dialog-top .p-dialog-enter-active, .p-dialog-bottom .p-dialog-enter-active, .p-dialog-left .p-dialog-enter-active, .p-dialog-top-left .p-dialog-enter-active, .p-dialog-bottom-left .p-dialog-enter-active, .p-dialog-right .p-dialog-enter-active, .p-dialog-top-right .p-dialog-enter-active, .p-dialog-bottom-right .p-dialog-enter-active {
  transition: all .3s ease-out;
  transform: translate3d(0, 0, 0);
}

.p-dialog-top .p-dialog-exit-active, .p-dialog-bottom .p-dialog-exit-active, .p-dialog-left .p-dialog-exit-active, .p-dialog-top-left .p-dialog-exit-active, .p-dialog-bottom-left .p-dialog-exit-active, .p-dialog-right .p-dialog-exit-active, .p-dialog-top-right .p-dialog-exit-active, .p-dialog-bottom-right .p-dialog-exit-active {
  transition: all .3s ease-out;
}

.p-dialog-maximized {
  max-height: 100%;
  transition: none;
  transform: none;
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.p-dialog-maximized .p-dialog-content {
  flex-grow: 1;
}

.p-dialog-left {
  justify-content: flex-start;
}

.p-dialog-right {
  justify-content: flex-end;
}

.p-dialog-top {
  align-items: flex-start;
}

.p-dialog-top-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.p-dialog-top-right {
  justify-content: flex-end;
  align-items: flex-start;
}

.p-dialog-bottom {
  align-items: flex-end;
}

.p-dialog-bottom-left {
  justify-content: flex-start;
  align-items: flex-end;
}

.p-dialog-bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
}

.p-confirm-dialog .p-dialog-content {
  align-items: center;
  display: flex;
}

.p-dialog .p-resizable-handle {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  font-size: .1px;
  display: block;
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.p-dialog-draggable .p-dialog-header {
  cursor: move;
}

.p-divider-horizontal {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.p-divider-horizontal:before {
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.p-divider-horizontal.p-divider-left {
  justify-content: flex-start;
}

.p-divider-horizontal.p-divider-right {
  justify-content: flex-end;
}

.p-divider-horizontal.p-divider-center {
  justify-content: center;
}

.p-divider-content {
  z-index: 1;
}

.p-divider-vertical {
  min-height: 100%;
  justify-content: center;
  margin: 0 1rem;
  display: flex;
  position: relative;
}

.p-divider-vertical:before {
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.p-divider-vertical.p-divider-top {
  align-items: flex-start;
}

.p-divider-vertical.p-divider-center {
  align-items: center;
}

.p-divider-vertical.p-divider-bottom {
  align-items: flex-end;
}

.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid;
}

.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid;
}

.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed;
}

.p-divider-dashed.p-divider-vertical:before {
  border-left-style: dashed;
}

.p-divider-dotted.p-divider-horizontal:before {
  border-top-style: dotted;
  border-left-style: dotted;
}

.p-dock {
  z-index: 1;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.p-dock-container {
  pointer-events: auto;
  display: flex;
}

.p-dock-list {
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.p-dock-magnification .p-dock-item {
  will-change: transform;
  transition: all .2s cubic-bezier(.4, 0, .2, 1);
}

.p-dock-action {
  cursor: default;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-dock-magnification .p-dock-item-second-prev, .p-dock-magnification .p-dock-item-second-next {
  transform: scale(1.2);
}

.p-dock-magnification .p-dock-item-prev, .p-dock-magnification .p-dock-item-next {
  transform: scale(1.4);
}

.p-dock-magnification .p-dock-item-current {
  z-index: 1;
  transform: scale(1.6);
}

.p-dock-top {
  width: 100%;
  top: 0;
  left: 0;
}

.p-dock-top.p-dock-magnification .p-dock-item {
  transform-origin: top;
}

.p-dock-top .p-dock-container {
  flex-direction: column-reverse;
}

.p-dock-bottom {
  width: 100%;
  bottom: 0;
  left: 0;
}

.p-dock-bottom.p-dock-magnification .p-dock-item {
  transform-origin: bottom;
}

.p-dock-bottom .p-dock-container {
  flex-direction: column;
}

.p-dock-right {
  height: 100%;
  top: 0;
  right: 0;
}

.p-dock-right.p-dock-magnification .p-dock-item {
  transform-origin: 100%;
}

.p-dock-right .p-dock-list {
  flex-direction: column;
}

.p-dock-left {
  height: 100%;
  top: 0;
  left: 0;
}

.p-dock-left.p-dock-magnification .p-dock-item {
  transform-origin: 0;
}

.p-dock-left .p-dock-list {
  flex-direction: column;
}

.p-dock-left .p-dock-container {
  flex-direction: row-reverse;
}

.p-dropdown {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
  position: relative;
}

.p-dropdown-clear-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-dropdown-trigger {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-dropdown-label {
  white-space: nowrap;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
  flex: auto;
  display: block;
  overflow: hidden;
}

.p-dropdown-label-empty {
  visibility: hidden;
  overflow: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  white-space: nowrap;
  font-weight: normal;
  position: relative;
  overflow: hidden;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon, .p-dropdown-filter-clear-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: 0;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: 0;
  border: 0;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: 0;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid #0000;
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid #0000;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid #0000;
  box-shadow: 0 2px 8px #0003;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.p-fieldset-legend > a, .p-fieldset-legend > span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-fieldset-toggleable .p-fieldset-legend a {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.p-fieldset-legend-text {
  line-height: 1;
}

.p-fileupload-content {
  position: relative;
}

.p-fileupload-row {
  align-items: center;
  display: flex;
}

.p-fileupload-row > div {
  width: 25%;
  flex: auto;
}

.p-fileupload-row > div:last-child {
  text-align: right;
}

.p-fileupload-content > .p-progressbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.p-button.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}

.p-fileupload-buttonbar {
  flex-wrap: wrap;
  display: flex;
}

.p-button.p-fileupload-choose input[type="file"], .p-fileupload-choose.p-fileupload-choose-selected input[type="file"] {
  display: none;
}

.p-fileupload-filename {
  word-break: break-all;
}

.p-fileupload-file-thumbnail {
  flex-shrink: 0;
}

.p-fileupload-file-badge {
  margin: .5rem;
}

.p-fluid .p-fileupload .p-button {
  width: auto;
}

.p-galleria-content {
  flex-direction: column;
  display: flex;
}

.p-galleria-item-wrapper {
  flex-direction: column;
  display: flex;
  position: relative;
}

.p-galleria-item-container {
  height: 100%;
  display: flex;
  position: relative;
}

.p-galleria-item-nav {
  justify-content: center;
  align-items: center;
  margin-top: -.5rem;
  display: inline-flex;
  position: absolute;
  top: 50%;
  overflow: hidden;
}

.p-galleria-item-prev {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: 0;
}

.p-galleria-item-next {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
}

.p-galleria-item {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-galleria-item-nav-onhover .p-galleria-item-nav {
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.p-galleria-item-nav-onhover .p-galleria-item-wrapper:hover .p-galleria-item-nav {
  pointer-events: all;
  opacity: 1;
}

.p-galleria-item-nav-onhover .p-galleria-item-wrapper:hover .p-galleria-item-nav.p-disabled {
  pointer-events: none;
}

.p-galleria-caption {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.p-galleria-thumbnail-wrapper {
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  overflow: auto;
}

.p-galleria-thumbnail-prev, .p-galleria-thumbnail-next {
  flex: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-galleria-thumbnail-prev span, .p-galleria-thumbnail-next span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-galleria-thumbnail-container {
  flex-direction: row;
  display: flex;
}

.p-galleria-thumbnail-items-container {
  width: 100%;
  overflow: hidden;
}

.p-galleria-thumbnail-items {
  display: flex;
}

.p-galleria-thumbnail-item {
  cursor: pointer;
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
}

.p-galleria-thumbnail-item:hover {
  opacity: 1;
  transition: opacity .3s;
}

.p-galleria-thumbnail-item-current {
  opacity: 1;
}

.p-galleria-thumbnails-left .p-galleria-content, .p-galleria-thumbnails-right .p-galleria-content, .p-galleria-thumbnails-left .p-galleria-item-wrapper, .p-galleria-thumbnails-right .p-galleria-item-wrapper {
  flex-direction: row;
}

.p-galleria-thumbnails-left .p-galleria-item-wrapper, .p-galleria-thumbnails-top .p-galleria-item-wrapper {
  order: 2;
}

.p-galleria-thumbnails-left .p-galleria-thumbnail-wrapper, .p-galleria-thumbnails-top .p-galleria-thumbnail-wrapper {
  order: 1;
}

.p-galleria-thumbnails-left .p-galleria-thumbnail-container, .p-galleria-thumbnails-right .p-galleria-thumbnail-container {
  flex-direction: column;
  flex-grow: 1;
}

.p-galleria-thumbnails-left .p-galleria-thumbnail-items, .p-galleria-thumbnails-right .p-galleria-thumbnail-items {
  height: 100%;
  flex-direction: column;
}

.p-galleria-indicators {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-galleria-indicator > button {
  align-items: center;
  display: inline-flex;
}

.p-galleria-indicators-left .p-galleria-item-wrapper, .p-galleria-indicators-right .p-galleria-item-wrapper {
  flex-direction: row;
  align-items: center;
}

.p-galleria-indicators-left .p-galleria-item-container, .p-galleria-indicators-top .p-galleria-item-container {
  order: 2;
}

.p-galleria-indicators-left .p-galleria-indicators, .p-galleria-indicators-top .p-galleria-indicators {
  order: 1;
}

.p-galleria-indicators-left .p-galleria-indicators, .p-galleria-indicators-right .p-galleria-indicators {
  flex-direction: column;
}

.p-galleria-indicator-onitem .p-galleria-indicators {
  display: flex;
  position: absolute;
}

.p-galleria-indicator-onitem.p-galleria-indicators-top .p-galleria-indicators {
  width: 100%;
  align-items: flex-start;
  top: 0;
  left: 0;
}

.p-galleria-indicator-onitem.p-galleria-indicators-right .p-galleria-indicators {
  height: 100%;
  align-items: flex-end;
  top: 0;
  right: 0;
}

.p-galleria-indicator-onitem.p-galleria-indicators-bottom .p-galleria-indicators {
  width: 100%;
  align-items: flex-end;
  bottom: 0;
  left: 0;
}

.p-galleria-indicator-onitem.p-galleria-indicators-left .p-galleria-indicators {
  height: 100%;
  align-items: flex-start;
  top: 0;
  left: 0;
}

.p-galleria-mask {
  width: 100%;
  height: 100%;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.p-galleria-mask.p-component-overlay {
  pointer-events: auto;
}

.p-galleria-close {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.p-galleria-mask .p-galleria-item-nav {
  margin-top: -.5rem;
  position: fixed;
  top: 50%;
}

.p-galleria-enter {
  opacity: 0;
  transform: scale(.7);
}

.p-galleria-enter-active {
  opacity: 1;
  transition: all .15s cubic-bezier(0, 0, .2, 1);
  transform: scale(1);
}

.p-galleria-enter-done {
  transform: none;
}

.p-galleria-exit {
  opacity: 1;
}

.p-galleria-exit-active {
  opacity: 0;
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
  transform: scale(.7);
}

.p-galleria-enter-active .p-galleria-item-nav {
  opacity: 0;
}

.p-items-hidden .p-galleria-thumbnail-item {
  visibility: hidden;
}

.p-items-hidden .p-galleria-thumbnail-item.p-galleria-thumbnail-item-active {
  visibility: visible;
}

.p-image-mask {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-image-preview-container {
  display: inline-block;
  position: relative;
}

.p-image-preview-indicator {
  width: 100%;
  height: 100%;
  opacity: 0;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.p-image-preview-icon {
  font-size: 1.5rem;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  opacity: 1;
  cursor: pointer;
}

.p-image-preview-container > img {
  cursor: pointer;
}

.p-image-toolbar {
  z-index: 1;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.p-image-action.p-link {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-image-preview {
  max-width: 100vw;
  max-height: 100vh;
  transition: transform .15s;
}

.p-image-preview-enter {
  opacity: 0;
  transform: scale(.7);
}

.p-image-preview-enter-active {
  opacity: 1;
  transition: all .15s cubic-bezier(0, 0, .2, 1);
  transform: scale(1);
}

.p-image-preview-enter-done {
  transform: none;
}

.p-image-preview-exit {
  opacity: 1;
}

.p-image-preview-exit-active {
  opacity: 0;
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
  transform: scale(.7);
}

.p-inplace .p-inplace-display {
  cursor: pointer;
  display: inline;
}

.p-inplace .p-inplace-content {
  display: inline;
}

.p-fluid .p-inplace.p-inplace-closable .p-inplace-content {
  display: flex;
}

.p-fluid .p-inplace.p-inplace-closable .p-inplace-content > .p-inputtext {
  width: 1%;
  flex: auto;
}

.p-inplace-content-close {
  margin-left: .25rem;
}

.p-inputnumber {
  display: inline-flex;
}

.p-inputnumber-button {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button .p-button-label, .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button .p-button-label {
  display: none;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
  flex-direction: column;
  display: flex;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
  flex: auto;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  order: 3;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  border-radius: 0;
  order: 2;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  order: 1;
}

.p-inputnumber-buttons-vertical {
  flex-direction: column;
}

.p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-up {
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  order: 1;
}

.p-inputnumber-buttons-vertical .p-inputnumber-input {
  text-align: center;
  border-radius: 0;
  order: 2;
}

.p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-down {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  order: 3;
}

.p-inputnumber-input {
  flex: auto;
}

.p-fluid .p-inputnumber {
  width: 100%;
}

.p-fluid .p-inputnumber .p-inputnumber-input {
  width: 1%;
}

.p-fluid .p-inputnumber-buttons-vertical .p-inputnumber-input {
  width: 100%;
}

.p-inputswitch {
  display: inline-block;
  position: relative;
}

.p-inputswitch-slider {
  cursor: pointer;
  border: 1px solid #0000;
  position: absolute;
  inset: 0;
}

.p-inputswitch-slider:before {
  content: "";
  position: absolute;
  top: 50%;
}

.p-inputtext {
  margin: 0;
}

.p-fluid .p-inputtext {
  width: 100%;
}

.p-inputgroup {
  width: 100%;
  align-items: stretch;
  display: flex;
}

.p-inputgroup-addon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-inputgroup .p-float-label {
  width: 100%;
  align-items: stretch;
  display: flex;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
  width: 1%;
  flex: auto;
}

.p-float-label {
  display: block;
  position: relative;
}

.p-float-label label {
  pointer-events: none;
  margin-top: -.5rem;
  line-height: 1;
  transition-property: all;
  transition-timing-function: ease;
  position: absolute;
  top: 50%;
}

.p-float-label textarea ~ label, .p-float-label .p-mention ~ label {
  top: 1rem;
}

.p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label {
  font-size: 12px;
  top: -.75rem;
}

.p-float-label .p-placeholder, .p-float-label input::placeholder, .p-float-label .p-inputtext::placeholder {
  opacity: 0;
  transition-property: all;
  transition-timing-function: ease;
}

.p-float-label .p-focus .p-placeholder, .p-float-label input:focus::placeholder, .p-float-label .p-inputtext:focus::placeholder {
  opacity: 1;
  transition-property: all;
  transition-timing-function: ease;
}

.p-input-icon-left, .p-input-icon-right {
  display: inline-block;
  position: relative;
}

.p-input-icon-left > i, .p-input-icon-right > i, .p-input-icon-left > svg, .p-input-icon-right > svg, .p-input-icon-left > .p-input-prefix, .p-input-icon-right > .p-input-suffix {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-fluid .p-input-icon-left, .p-fluid .p-input-icon-right {
  width: 100%;
  display: block;
}

.p-inputtextarea-resizable {
  resize: none;
  overflow: hidden;
}

.p-fluid .p-inputtextarea {
  width: 100%;
}

@keyframes dash-frame {
  100% {
    stroke-dashoffset: 0;
  }
}

.p-knob-range {
  fill: none;
  transition: stroke .1s ease-in;
}

.p-knob-value {
  fill: none;
  animation-name: dash-frame;
  animation-fill-mode: forwards;
}

.p-knob-text {
  text-align: center;
  font-size: 1.3rem;
}

.p-listbox-list-wrapper {
  overflow: auto;
}

.p-listbox-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-listbox-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.p-listbox-filter-container {
  position: relative;
}

.p-listbox-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-listbox-filter {
  width: 100%;
}

.p-megamenu {
  display: flex;
}

.p-megamenu-root-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-megamenu-root-list > .p-menuitem {
  position: relative;
}

.p-megamenu .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-megamenu .p-menuitem-text {
  line-height: 1;
}

.p-megamenu-panel {
  width: auto;
  z-index: 1;
  display: none;
  position: absolute;
}

.p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  display: block;
}

.p-megamenu-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-megamenu-horizontal {
  align-items: center;
}

.p-megamenu-horizontal .p-megamenu-root-list {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.p-megamenu-horizontal .p-megamenu-custom, .p-megamenu-horizontal .p-megamenu-end {
  align-self: center;
  margin-left: auto;
}

.p-megamenu-vertical, .p-megamenu-vertical .p-megamenu-root-list {
  flex-direction: column;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  top: 0;
  left: 100%;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  margin-left: auto;
}

.p-megamenu-grid {
  display: flex;
}

.p-megamenu-col-2, .p-megamenu-col-3, .p-megamenu-col-4, .p-megamenu-col-6, .p-megamenu-col-12 {
  flex: none;
  padding: .5rem;
}

.p-megamenu-col-2 {
  width: 16.6667%;
}

.p-megamenu-col-3 {
  width: 25%;
}

.p-megamenu-col-4 {
  width: 33.3333%;
}

.p-megamenu-col-6 {
  width: 50%;
}

.p-megamenu-col-12 {
  width: 100%;
}

.p-megamenu-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: none;
}

.p-mention {
  display: inline-flex;
  position: relative;
}

.p-autocomplete-loader {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-mention .p-mention-panel {
  min-width: 100%;
}

.p-mention-panel {
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.p-mention-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-mention-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-fluid .p-mention {
  display: flex;
}

.p-menu-overlay {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.p-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-menu .p-menuitem-text {
  line-height: 1;
}

.p-menubar {
  align-items: center;
  display: flex;
}

.p-menubar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-menubar .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-menubar .p-menuitem-text {
  line-height: 1;
}

.p-menubar .p-menuitem {
  position: relative;
}

.p-menubar-root-list {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.p-menubar-root-list > li ul {
  z-index: 1;
  display: none;
}

.p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  display: block;
}

.p-menubar .p-submenu-list {
  z-index: 1;
  display: none;
  position: absolute;
}

.p-menubar .p-submenu-list > .p-menuitem-active > .p-submenu-list {
  display: block;
  top: 0;
  left: 100%;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-menubar .p-menubar-custom, .p-menubar .p-menubar-end {
  align-self: center;
  margin-left: auto;
}

.p-menubar-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: none;
}

.p-inline-message {
  vertical-align: top;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.p-inline-message-icon-only .p-inline-message-text {
  visibility: hidden;
  width: 0;
}

.p-fluid .p-inline-message {
  display: flex;
}

.p-message-wrapper {
  align-items: center;
  display: flex;
}

.p-message-close {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-message-close.p-link {
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

.p-message-enter {
  opacity: 0;
}

.p-message-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.p-message-exit {
  opacity: 1;
  max-height: 1000px;
}

.p-message-exit-active {
  opacity: 0;
  max-height: 0;
  margin: 0;
  transition: max-height .3s cubic-bezier(0, 1, 0, 1), opacity .3s, margin .3s;
  overflow: hidden;
}

.p-message-exit-active .p-message-close {
  display: none;
}

.p-multiselect {
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
  position: relative;
}

.p-multiselect-trigger {
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-multiselect-label-container {
  cursor: pointer;
  flex: auto;
  overflow: hidden;
}

.p-multiselect-label {
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.p-multiselect-label-empty {
  visibility: hidden;
  overflow: hidden;
}

.p-multiselect-token {
  cursor: default;
  flex: none;
  align-items: center;
  display: inline-flex;
}

.p-multiselect-token-icon {
  cursor: pointer;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-multiselect-inline.p-multiselect-panel {
  position: initial;
  box-shadow: none;
  background: 0;
  border: 0;
}

.p-multiselect-inline.p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

.p-multiselect-flex.p-multiselect-panel .p-multiselect-items {
  flex-wrap: wrap;
  display: flex;
}

.p-multiselect-items-wrapper {
  overflow: auto;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-multiselect-item {
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  font-weight: normal;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-multiselect-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-multiselect-filter-container {
  flex: auto;
  position: relative;
}

.p-multiselect-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-multiselect-filter-container .p-inputtext {
  width: 100%;
}

.p-multiselect-close {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-multiselect-clear-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-fluid .p-multiselect {
  display: flex;
}

.p-organizationchart-table {
  border-spacing: 0;
  border-collapse: separate;
  margin: 0 auto;
}

.p-organizationchart-table > tbody > tr > td {
  text-align: center;
  vertical-align: top;
  padding: 0 .75rem;
}

.p-organizationchart-node-content {
  display: inline-block;
  position: relative;
}

.p-organizationchart-node-content .p-node-toggler {
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -.75rem;
  text-decoration: none;
  position: absolute;
  bottom: -.75rem;
  left: 50%;
}

.p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
  position: relative;
  top: .25rem;
}

.p-organizationchart-line-down {
  height: 20px;
  width: 1px;
  margin: 0 auto;
}

.p-organizationchart-line-right, .p-organizationchart-line-left {
  border-radius: 0;
}

.p-organizationchart-selectable-node {
  cursor: pointer;
}

.p-orderlist {
  display: flex;
}

.p-orderlist-controls {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.p-orderlist-list-container {
  flex: auto;
}

.p-orderlist-list {
  min-height: 12rem;
  max-height: 24rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.p-orderlist-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.p-orderlist-filter {
  position: relative;
}

.p-orderlist-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-orderlist-filter-input {
  width: 100%;
}

.p-orderlist.p-state-disabled .p-orderlist-item, .p-orderlist.p-state-disabled .p-button {
  cursor: default;
}

.p-orderlist.p-state-disabled .p-orderlist-list {
  overflow: hidden;
}

.p-orderlist .p-orderlist-droppoint {
  height: .5rem;
}

.p-orderlist .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background: var(--primary-color);
}

.p-overlaypanel {
  margin-top: 10px;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.p-overlaypanel-flipped {
  margin-top: 0;
  margin-bottom: 10px;
}

.p-overlaypanel-close {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-overlaypanel-enter {
  opacity: 0;
  transform: scaleY(.8);
}

.p-overlaypanel-enter-active {
  opacity: 1;
  transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
  transform: scaleY(1);
}

.p-overlaypanel-enter-done {
  transform: none;
}

.p-overlaypanel-exit {
  opacity: 1;
}

.p-overlaypanel-exit-active {
  opacity: 0;
  transition: opacity .1s linear;
}

.p-overlaypanel:after, .p-overlaypanel:before {
  bottom: 100%;
  left: calc(var(--overlayArrowLeft, 0)  + 1.25rem);
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
}

.p-overlaypanel:after {
  border-width: 8px;
  margin-left: -8px;
}

.p-overlaypanel:before {
  border-width: 10px;
  margin-left: -10px;
}

.p-overlaypanel-flipped:after, .p-overlaypanel-flipped:before {
  top: 100%;
  bottom: auto;
}

.p-overlaypanel.p-overlaypanel-flipped:after, .p-overlaypanel.p-overlaypanel-flipped:before {
  border-bottom-color: #0000;
}

.p-panel-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-panel-title {
  line-height: 1;
}

.p-panel-header-icon {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-paginator {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-paginator-left-content {
  margin-right: auto;
}

.p-paginator-right-content {
  margin-left: auto;
}

.p-paginator-page, .p-paginator-next, .p-paginator-last, .p-paginator-first, .p-paginator-prev, .p-paginator-current {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-paginator-element:focus {
  z-index: 1;
  position: relative;
}

.p-panelmenu .p-panelmenu-header-link {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.p-panelmenu .p-panelmenu-header-link:focus {
  z-index: 1;
}

.p-panelmenu .p-submenu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-panelmenu .p-menuitem-link {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.p-panelmenu .p-menuitem-text {
  line-height: 1;
}

.p-password {
  display: inline-flex;
  position: relative;
}

.p-password-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-password .p-password-panel {
  min-width: 100%;
}

.p-password-meter {
  height: 10px;
}

.p-password-strength {
  height: 100%;
  width: 0;
  transition: width 1s ease-in-out;
}

.p-fluid .p-password {
  display: flex;
}

.p-password-input::-ms-reveal, .p-password-input::-ms-clear {
  display: none;
}

.p-picklist {
  display: flex;
}

.p-picklist-buttons {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.p-picklist-list-wrapper {
  flex: 50%;
}

.p-picklist-list {
  min-height: 12rem;
  max-height: 24rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.p-picklist-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.p-picklist-filter {
  position: relative;
}

.p-picklist-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-picklist-filter-input {
  width: 100%;
}

.p-progressbar {
  position: relative;
  overflow: hidden;
}

.p-progressbar-determinate .p-progressbar-value {
  height: 100%;
  width: 0;
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.p-progressbar-determinate .p-progressbar-label {
  display: inline-flex;
}

.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width 1s ease-in-out;
}

.p-progressbar-indeterminate .p-progressbar-value:before {
  content: "";
  background-color: inherit;
  will-change: left, right;
  animation: 2.1s cubic-bezier(.65, .815, .735, .395) infinite p-progressbar-indeterminate-anim;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.p-progressbar-indeterminate .p-progressbar-value:after {
  content: "";
  background-color: inherit;
  will-change: left, right;
  animation: 2.1s cubic-bezier(.165, .84, .44, 1) 1.15s infinite p-progressbar-indeterminate-anim-short;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.p-progress-spinner {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.p-progress-spinner:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.p-progress-spinner-svg {
  height: 100%;
  transform-origin: center;
  width: 100%;
  margin: auto;
  animation: 2s linear infinite p-progress-spinner-rotate;
  position: absolute;
  inset: 0;
}

.p-progress-spinner-circle {
  stroke-dasharray: 89 200;
  stroke-dashoffset: 0;
  stroke: #d62d20;
  stroke-linecap: round;
  animation: 1.5s ease-in-out infinite p-progress-spinner-dash, 6s ease-in-out infinite p-progress-spinner-color;
}

@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes p-progress-spinner-color {
  100%, 0% {
    stroke: #d62d20;
  }

  40% {
    stroke: #0057e7;
  }

  66% {
    stroke: #008744;
  }

  80%, 90% {
    stroke: #ffa700;
  }
}

.p-radiobutton {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  display: inline-flex;
}

.p-radiobutton-box {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-radiobutton-icon {
  backface-visibility: hidden;
  visibility: hidden;
  border-radius: 50%;
  transform: translateZ(0)scale(.1);
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  visibility: visible;
  transform: translateZ(0)scale(1);
}

.p-rating {
  align-items: center;
  display: flex;
}

.p-rating-item {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

.p-rating.p-readonly .p-rating-item {
  cursor: default;
}

.p-ripple {
  position: relative;
  overflow: hidden;
}

.p-ink {
  background: #ffffff80;
  border-radius: 100%;
  display: block;
  position: absolute;
  transform: scale(0);
}

.p-ink-active {
  animation: .4s linear ripple;
}

.p-ripple-disabled .p-ink {
  display: none !important;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.p-scrollpanel-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  float: left;
  position: relative;
  overflow: hidden;
}

.p-scrollpanel-content {
  height: calc(100% + 18px);
  width: calc(100% + 18px);
  box-sizing: border-box;
  padding: 0 18px 18px 0;
  position: relative;
  overflow: scroll;
}

.p-scrollpanel-bar {
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  background: #c1c1c1;
  border-radius: 3px;
  transition: opacity .25s linear;
  position: relative;
}

.p-scrollpanel-bar-y {
  width: 9px;
  top: 0;
}

.p-scrollpanel-bar-x {
  height: 9px;
  bottom: 0;
}

.p-scrollpanel-hidden {
  visibility: hidden;
}

.p-scrollpanel:hover .p-scrollpanel-bar, .p-scrollpanel:active .p-scrollpanel-bar {
  opacity: 1;
}

.p-scrollpanel-grabbed {
  -webkit-user-select: none;
  user-select: none;
}

.p-scrolltop {
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.p-scrolltop-sticky {
  position: sticky;
}

.p-scrolltop-sticky.p-link {
  margin-left: auto;
}

.p-scrolltop-helper {
  display: none !important;
}

.p-scrolltop-enter {
  opacity: 0;
}

.p-scrolltop-enter-active {
  opacity: 1;
  transition: opacity .15s;
}

.p-scrolltop-exit {
  opacity: 1;
}

.p-scrolltop-exit-active {
  opacity: 0;
  transition: opacity .15s;
}

.p-skeleton {
  position: relative;
  overflow: hidden;
}

.p-skeleton:after {
  content: "";
  height: 100%;
  z-index: 1;
  animation: 1.2s infinite p-skeleton-animation;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.p-skeleton-circle {
  border-radius: 50%;
}

.p-skeleton-none:after {
  animation: none;
}

@keyframes p-skeleton-animation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.p-sidebar-mask {
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  transition-property: background-color;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.p-sidebar-visible {
  display: flex;
}

.p-sidebar-mask.p-component-overlay {
  pointer-events: auto;
}

.p-sidebar {
  pointer-events: auto;
  flex-direction: column;
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.p-sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
}

.p-sidebar-header {
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.p-sidebar-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-sidebar-full .p-sidebar {
  max-height: 100%;
  transition: none;
  transform: none;
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
}

.p-sidebar-top .p-sidebar-enter, .p-sidebar-top .p-sidebar-exit-active {
  transform: translate3d(0, -100%, 0);
}

.p-sidebar-bottom .p-sidebar-enter, .p-sidebar-bottom .p-sidebar-exit-active {
  transform: translate3d(0, 100%, 0);
}

.p-sidebar-left .p-sidebar-enter, .p-sidebar-left .p-sidebar-exit-active {
  transform: translate3d(-100%, 0, 0);
}

.p-sidebar-right .p-sidebar-enter, .p-sidebar-right .p-sidebar-exit-active {
  transform: translate3d(100%, 0, 0);
}

.p-sidebar-top .p-sidebar-enter-active, .p-sidebar-bottom .p-sidebar-enter-active, .p-sidebar-left .p-sidebar-enter-active, .p-sidebar-right .p-sidebar-enter-active {
  transition: all .3s;
  transform: translate3d(0, 0, 0);
}

.p-sidebar-top .p-sidebar-enter-done, .p-sidebar-bottom .p-sidebar-enter-done, .p-sidebar-left .p-sidebar-enter-done, .p-sidebar-right .p-sidebar-enter-done {
  transform: none;
}

.p-sidebar-top .p-sidebar-exit-active, .p-sidebar-bottom .p-sidebar-exit-active, .p-sidebar-left .p-sidebar-exit-active, .p-sidebar-right .p-sidebar-exit-active {
  transition: all .3s;
}

.p-sidebar-full .p-sidebar-enter {
  opacity: 0;
  transform: scale(.5);
}

.p-sidebar-full .p-sidebar-enter-active {
  opacity: 1;
  transition: all .15s cubic-bezier(0, 0, .2, 1);
  transform: scale(1);
}

.p-sidebar-full .p-sidebar-enter-done {
  transform: none;
}

.p-sidebar-full .p-sidebar-exit-active {
  opacity: 0;
  transition: all .15s cubic-bezier(.4, 0, .2, 1);
  transform: scale(.5);
}

.p-sidebar-left {
  justify-content: flex-start;
}

.p-sidebar-right {
  justify-content: flex-end;
}

.p-sidebar-top {
  align-items: flex-start;
}

.p-sidebar-bottom {
  align-items: flex-end;
}

.p-sidebar-left .p-sidebar, .p-sidebar-right .p-sidebar {
  width: 20rem;
  height: 100%;
}

.p-sidebar-top .p-sidebar, .p-sidebar-bottom .p-sidebar {
  height: 10rem;
  width: 100%;
}

.p-sidebar-left .p-sidebar-sm, .p-sidebar-right .p-sidebar-sm {
  width: 20rem;
}

.p-sidebar-left .p-sidebar-md, .p-sidebar-right .p-sidebar-md {
  width: 40rem;
}

.p-sidebar-left .p-sidebar-lg, .p-sidebar-right .p-sidebar-lg {
  width: 60rem;
}

.p-sidebar-top .p-sidebar-sm, .p-sidebar-bottom .p-sidebar-sm {
  height: 10rem;
}

.p-sidebar-top .p-sidebar-md, .p-sidebar-bottom .p-sidebar-md {
  height: 20rem;
}

.p-sidebar-top .p-sidebar-lg, .p-sidebar-bottom .p-sidebar-lg {
  height: 30rem;
}

.p-sidebar-left .p-sidebar-view, .p-sidebar-right .p-sidebar-view, .p-sidebar-top .p-sidebar-view, .p-sidebar-bottom .p-sidebar-view, .p-sidebar-left .p-sidebar-content, .p-sidebar-right .p-sidebar-content, .p-sidebar-top .p-sidebar-content, .p-sidebar-bottom .p-sidebar-content {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 64em) {
  .p-sidebar-left .p-sidebar-lg, .p-sidebar-left .p-sidebar-md, .p-sidebar-right .p-sidebar-lg, .p-sidebar-right .p-sidebar-md {
    width: 20rem;
  }
}

.p-slidemenu {
  width: 12.5em;
}

.p-slidemenu.p-slidemenu-overlay {
  position: absolute;
}

.p-slidemenu .p-menu-separator {
  border-width: 1px 0 0;
}

.p-slidemenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-slidemenu .p-slidemenu-rootlist {
  position: absolute;
  top: 0;
}

.p-slidemenu .p-submenu-list {
  width: 12.5em;
  display: none;
  position: absolute;
  top: 0;
}

.p-slidemenu .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.p-slidemenu .p-menuitem-icon, .p-slidemenu .p-menuitem-text {
  vertical-align: middle;
}

.p-slidemenu .p-menuitem {
  position: relative;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-slidemenu .p-slidemenu-wrapper {
  position: relative;
}

.p-slidemenu .p-slidemenu-content {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.p-slidemenu-backward {
  width: 100%;
  cursor: pointer;
  padding: .25em;
  position: absolute;
  bottom: 0;
}

.p-slidemenu-backward .p-slidemenu-backward-icon, .p-slidemenu-backward span {
  vertical-align: middle;
}

.p-slidemenu .p-menuitem-active {
  position: static;
}

.p-slidemenu .p-menuitem-active > .p-submenu-list {
  display: block;
}

.p-slider {
  position: relative;
}

.p-slider .p-slider-handle {
  cursor: grab;
  touch-action: none;
  z-index: 1;
  display: block;
  position: absolute;
}

.p-slider .p-slider-handle.p-slider-handle-active {
  z-index: 2;
}

.p-slider-range {
  display: block;
  position: absolute;
}

.p-slider-horizontal .p-slider-range {
  height: 100%;
  top: 0;
  left: 0;
}

.p-slider-horizontal .p-slider-handle {
  top: 50%;
}

.p-slider-vertical {
  height: 100px;
}

.p-slider-vertical .p-slider-handle {
  left: 50%;
}

.p-slider-vertical .p-slider-range {
  width: 100%;
  bottom: 0;
  left: 0;
}

.p-speeddial {
  z-index: 1;
  display: flex;
  position: absolute;
}

.p-speeddial-list {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: top 0s linear .2s;
  display: flex;
}

.p-speeddial-item {
  opacity: 0;
  will-change: transform;
  transition: transform .2s cubic-bezier(.4, 0, .2, 1), opacity .8s;
  transform: scale(0);
}

.p-speeddial-action {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item {
  position: absolute;
}

.p-speeddial-rotate {
  will-change: transform;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

.p-speeddial-mask {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .25s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.p-speeddial-mask-visible {
  pointer-events: none;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
}

.p-speeddial-opened .p-speeddial-list {
  pointer-events: auto;
}

.p-speeddial-opened .p-speeddial-item {
  opacity: 1;
  transform: scale(1);
}

.p-speeddial-opened .p-speeddial-rotate {
  transform: rotate(45deg);
}

.p-speeddial-direction-up {
  flex-direction: column-reverse;
  align-items: center;
}

.p-speeddial-direction-up .p-speeddial-list {
  flex-direction: column-reverse;
}

.p-speeddial-direction-down {
  flex-direction: column;
  align-items: center;
}

.p-speeddial-direction-down .p-speeddial-list {
  flex-direction: column;
}

.p-speeddial-direction-left {
  flex-direction: row-reverse;
  justify-content: center;
}

.p-speeddial-direction-left .p-speeddial-list {
  flex-direction: row-reverse;
}

.p-speeddial-direction-right {
  flex-direction: row;
  justify-content: center;
}

.p-speeddial-direction-right .p-speeddial-list {
  flex-direction: row;
}

.p-splitbutton {
  display: inline-flex;
  position: relative;
}

.p-splitbutton .p-splitbutton-defaultbutton, .p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button, .p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: auto;
}

.p-splitbutton-menubutton, .p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button, .p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-splitbutton .p-menu {
  min-width: 100%;
}

.p-fluid .p-splitbutton {
  display: flex;
}

.p-splitter {
  flex-wrap: nowrap;
  display: flex;
}

.p-splitter-vertical {
  flex-direction: column;
}

.p-splitter-panel {
  flex-grow: 1;
}

.p-splitter-panel-nested {
  display: flex;
}

.p-splitter-panel .p-splitter {
  border: 0;
  flex-grow: 1;
}

.p-splitter-gutter {
  cursor: col-resize;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-splitter-horizontal.p-splitter-resizing {
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
}

.p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
  height: 24px;
  width: 100%;
}

.p-splitter-horizontal > .p-splitter-gutter {
  cursor: col-resize;
}

.p-splitter-vertical.p-splitter-resizing {
  cursor: row-resize;
  -webkit-user-select: none;
  user-select: none;
}

.p-splitter-vertical > .p-splitter-gutter {
  cursor: row-resize;
}

.p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
  width: 24px;
  height: 100%;
}

.p-steps {
  position: relative;
}

.p-steps ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.p-steps-item {
  flex: auto;
  justify-content: center;
  display: flex;
  position: relative;
}

.p-steps-item .p-menuitem-link {
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;
}

.p-steps.p-readonly .p-steps-item {
  cursor: auto;
}

.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: default;
}

.p-steps-title {
  white-space: nowrap;
}

.p-steps-number {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-steps-title {
  display: block;
}

.p-tabmenu {
  overflow-x: auto;
}

.p-tabmenu-nav {
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.p-tabmenu-nav a {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-tabmenu-nav a:focus {
  z-index: 1;
}

.p-tabmenu-nav .p-menuitem-text {
  line-height: 1;
}

.p-tabmenu-ink-bar {
  z-index: 1;
  display: none;
}

.p-tabmenu::-webkit-scrollbar {
  display: none;
}

.p-tabview-nav-container {
  position: relative;
}

.p-tabview-scrollable .p-tabview-nav-container {
  overflow: hidden;
}

.p-tabview-nav-content {
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior: contain auto;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.p-tabview-nav {
  flex: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.p-tabview-nav-link {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-tabview-ink-bar {
  z-index: 1;
  display: none;
}

.p-tabview-nav-link:focus, .p-tabview-close {
  z-index: 1;
}

.p-tabview-title {
  white-space: nowrap;
  line-height: 1;
}

.p-tabview-nav-btn {
  z-index: 2;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.p-tabview-nav-prev {
  left: 0;
}

.p-tabview-nav-next {
  right: 0;
}

.p-tabview-nav-content::-webkit-scrollbar {
  display: none;
}

.p-tag {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.p-tag-icon, .p-tag-value, .p-tag-icon.pi {
  line-height: 1.5;
}

.p-tag.p-tag-rounded {
  border-radius: 10rem;
}

.p-terminal {
  height: 18rem;
  overflow: auto;
}

.p-terminal-prompt-container {
  align-items: center;
  display: flex;
}

.p-terminal-input {
  color: inherit;
  background-color: #0000;
  border: 0;
  outline: 0;
  flex: auto;
  padding: 0;
}

.p-terminal-input::-ms-clear {
  display: none;
}

.p-tieredmenu-overlay {
  position: absolute;
}

.p-tieredmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-tieredmenu .p-submenu-list {
  min-width: 100%;
  z-index: 1;
  display: none;
  position: absolute;
}

.p-tieredmenu .p-menuitem-link {
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-tieredmenu .p-menuitem-text {
  line-height: 1;
}

.p-tieredmenu .p-menuitem {
  position: relative;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-tieredmenu .p-menuitem-active > .p-submenu-list {
  display: block;
  top: 0;
  left: 100%;
}

.p-tieredmenu .p-menuitem-active > .p-submenu-list-flipped {
  left: -100%;
}

.p-timeline {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.p-timeline-left .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-left .p-timeline-event-content {
  text-align: left;
}

.p-timeline-right .p-timeline-event {
  flex-direction: row-reverse;
}

.p-timeline-right .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-right .p-timeline-event-content {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n) {
  flex-direction: row-reverse;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n+1) .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n+1) .p-timeline-event-content, .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n) .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n) .p-timeline-event-content {
  text-align: right;
}

.p-timeline-event {
  min-height: 70px;
  display: flex;
  position: relative;
}

.p-timeline-event:last-child {
  min-height: 0;
}

.p-timeline-event-opposite, .p-timeline-event-content {
  flex: 1;
  padding: 0 1rem;
}

.p-timeline-event-separator {
  flex-direction: column;
  flex: 0;
  align-items: center;
  display: flex;
}

.p-timeline-event-marker {
  align-self: baseline;
  display: flex;
}

.p-timeline-event-connector {
  flex-grow: 1;
}

.p-timeline-horizontal {
  flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event {
  flex-direction: column;
  flex: 1;
}

.p-timeline-horizontal .p-timeline-event:last-child {
  flex: 0;
}

.p-timeline-horizontal .p-timeline-event-separator {
  flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event-connector {
  width: 100%;
}

.p-timeline-bottom .p-timeline-event, .p-timeline-horizontal.p-timeline-alternate .p-timeline-event:nth-child(2n) {
  flex-direction: column-reverse;
}

.p-toast {
  width: calc(100% - var(--toast-indent, 0px));
  max-width: 25rem;
  position: fixed;
}

.p-toast-message-content {
  align-items: flex-start;
  display: flex;
}

.p-toast-message-text {
  flex: auto;
}

.p-toast-summary, .p-toast-detail {
  overflow-wrap: anywhere;
}

.p-toast-top-right {
  --toast-indent: 20px;
  top: 20px;
  right: 20px;
}

.p-toast-top-left {
  --toast-indent: 20px;
  top: 20px;
  left: 20px;
}

.p-toast-bottom-left {
  --toast-indent: 20px;
  bottom: 20px;
  left: 20px;
}

.p-toast-bottom-right {
  --toast-indent: 20px;
  bottom: 20px;
  right: 20px;
}

.p-toast-top-center {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.p-toast-bottom-center {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.p-toast-center {
  min-width: 20vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-toast-icon-close {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-toast-icon-close.p-link {
  cursor: pointer;
}

.p-toast-message-enter {
  opacity: 0;
  transform: translateY(50%);
}

.p-toast-message-enter-active {
  opacity: 1;
  transition: transform .3s, opacity .3s;
  transform: translateY(0);
}

.p-toast-message-enter-done {
  transform: none;
}

.p-toast-message-exit {
  opacity: 1;
  max-height: 1000px;
}

.p-toast .p-toast-message.p-toast-message-exit-active {
  opacity: 0;
  max-height: 0;
  margin-bottom: 0;
  transition: max-height .45s cubic-bezier(0, 1, 0, 1), opacity .3s, margin-bottom .3s;
  overflow: hidden;
}

.p-toolbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-toolbar-group-start, .p-toolbar-group-center, .p-toolbar-group-end, .p-toolbar-group-left, .p-toolbar-group-right {
  align-items: center;
  display: flex;
}

.p-tooltip {
  padding: .25em .5rem;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.p-tooltip.p-tooltip-right, .p-tooltip.p-tooltip-left {
  padding: 0 .25rem;
}

.p-tooltip.p-tooltip-top, .p-tooltip.p-tooltip-bottom {
  padding: .25em 0;
}

.p-tooltip .p-tooltip-text {
  white-space: pre-line;
  word-break: break-word;
}

.p-tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.p-tooltip-right .p-tooltip-arrow {
  border-width: .25em .25em .25em 0;
  margin-top: -.25rem;
  top: 50%;
  left: 0;
}

.p-tooltip-left .p-tooltip-arrow {
  border-width: .25em 0 .25em .25rem;
  margin-top: -.25rem;
  top: 50%;
  right: 0;
}

.p-tooltip.p-tooltip-top {
  padding: .25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  border-width: .25em .25em 0;
  margin-left: -.25rem;
  bottom: 0;
  left: 50%;
}

.p-tooltip-bottom .p-tooltip-arrow {
  border-width: 0 .25em .25rem;
  margin-left: -.25rem;
  top: 0;
  left: 50%;
}

.p-tree-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.p-treenode-children {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-treenode-selectable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.p-tree-toggler {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  visibility: hidden;
}

.p-treenode-content {
  align-items: center;
  display: flex;
}

.p-tree-filter {
  width: 100%;
}

.p-tree-filter-container {
  width: 100%;
  display: block;
  position: relative;
}

.p-tree-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-tree-loading {
  min-height: 4rem;
  position: relative;
}

.p-tree .p-tree-loading-overlay {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.p-treeselect {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
  position: relative;
}

.p-treeselect-trigger {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-treeselect-label-container {
  cursor: pointer;
  flex: auto;
  overflow: hidden;
}

.p-treeselect-label {
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.p-treeselect-label-empty {
  visibility: hidden;
  overflow: hidden;
}

.p-treeselect-token {
  cursor: default;
  flex: none;
  align-items: center;
  display: inline-flex;
}

.p-treeselect .p-treeselect-panel {
  min-width: 100%;
}

.p-treeselect-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-treeselect-items-wrapper {
  overflow: auto;
}

.p-treeselect-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.p-treeselect-filter-container {
  flex: auto;
  position: relative;
}

.p-treeselect-filter-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-treeselect-filter-container .p-inputtext {
  width: 100%;
}

.p-treeselect-close {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.p-treeselect-clear-icon {
  margin-top: -.5rem;
  position: absolute;
  top: 50%;
}

.p-fluid .p-treeselect {
  display: flex;
}

.p-treetable {
  position: relative;
}

.p-treetable > .p-treetable-wrapper {
  overflow: auto;
}

.p-treetable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-treetable .p-sortable-column {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.p-treetable-selectable .p-treetable-tbody > tr {
  cursor: pointer;
}

.p-treetable-toggler {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.p-treetable-toggler + .p-checkbox, .p-treetable-toggler + .p-checkbox + span {
  vertical-align: middle;
}

.p-treetable-resizable > .p-treetable-wrapper {
  overflow-x: auto;
}

.p-treetable-resizable .p-treetable-thead > tr > th, .p-treetable-resizable .p-treetable-tfoot > tr > td, .p-treetable-resizable .p-treetable-tbody > tr > td {
  overflow: hidden;
}

.p-treetable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-treetable .p-column-resizer {
  width: .5rem;
  height: 100%;
  cursor: col-resize;
  border: 1px solid #0000;
  margin: 0;
  padding: 0;
  display: block;
  top: 0;
  right: 0;
  position: absolute !important;
}

.p-treetable .p-column-resizer-helper {
  width: 1px;
  z-index: 10;
  display: none;
  position: absolute;
}

.p-treetable-scrollable-wrapper {
  position: relative;
}

.p-treetable-scrollable-header, .p-treetable-scrollable-footer {
  border: 0;
  overflow: hidden;
}

.p-treetable-scrollable-body {
  position: relative;
  overflow: auto;
}

.p-treetable-virtual-table {
  position: absolute;
}

.p-treetable-frozen-view .p-treetable-scrollable-body {
  overflow: hidden;
}

.p-treetable-unfrozen-view {
  position: absolute;
  top: 0;
  left: 0;
}

.p-treetable-reorder-indicator-up, .p-treetable-reorder-indicator-down {
  display: none;
  position: absolute;
}

.p-treetable .p-treetable-loading-overlay {
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.p-virtualscroller {
  contain: strict;
  will-change: scroll-position;
  outline: 0;
  position: relative;
  overflow: auto;
  transform: translateZ(0);
}

.p-virtualscroller-content {
  min-height: 100%;
  min-width: 100%;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
}

.p-virtualscroller-spacer {
  height: 1px;
  width: 1px;
  transform-origin: 0 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.p-virtualscroller-loader {
  width: 100%;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
}

.p-virtualscroller-loader.p-component-overlay {
  justify-content: center;
  align-items: center;
  display: flex;
}

.p-virtualscroller-loading-icon {
  font-size: 2rem;
}

.p-virtualscroller-horizontal > .p-virtualscroller-content {
  display: flex;
}

.p-virtualscroller-inline .p-virtualscroller-content {
  position: static;
}

@font-face {
  font-family: primeicons;
  font-display: block;
  src: url("primeicons.81e5fde4.eot");
  src: url("primeicons.81e5fde4.eot#iefix") format("embedded-opentype"), url("primeicons.bb5567ec.woff2") format("woff2"), url("primeicons.51e61582.woff") format("woff"), url("primeicons.05540d0d.ttf") format("truetype"), url("primeicons.34fb6b01.svg#primeicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.pi {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: primeicons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.pi:before {
  --webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pi-fw {
  width: 1.28571em;
  text-align: center;
}

.pi-spin {
  animation: 2s linear infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.pi-eraser:before {
  content: "";
}

.pi-stopwatch:before {
  content: "";
}

.pi-verified:before {
  content: "";
}

.pi-delete-left:before {
  content: "";
}

.pi-hourglass:before {
  content: "";
}

.pi-truck:before {
  content: "";
}

.pi-wrench:before {
  content: "";
}

.pi-microphone:before {
  content: "";
}

.pi-megaphone:before {
  content: "";
}

.pi-arrow-right-arrow-left:before {
  content: "";
}

.pi-bitcoin:before {
  content: "";
}

.pi-file-edit:before {
  content: "";
}

.pi-language:before {
  content: "";
}

.pi-file-export:before {
  content: "";
}

.pi-file-import:before {
  content: "";
}

.pi-file-word:before {
  content: "";
}

.pi-gift:before {
  content: "";
}

.pi-cart-plus:before {
  content: "";
}

.pi-thumbs-down-fill:before {
  content: "";
}

.pi-thumbs-up-fill:before {
  content: "";
}

.pi-arrows-alt:before {
  content: "";
}

.pi-calculator:before {
  content: "";
}

.pi-sort-alt-slash:before {
  content: "";
}

.pi-arrows-h:before {
  content: "";
}

.pi-arrows-v:before {
  content: "";
}

.pi-pound:before {
  content: "";
}

.pi-prime:before {
  content: "";
}

.pi-chart-pie:before {
  content: "";
}

.pi-reddit:before {
  content: "";
}

.pi-code:before {
  content: "";
}

.pi-sync:before {
  content: "";
}

.pi-shopping-bag:before {
  content: "";
}

.pi-server:before {
  content: "";
}

.pi-database:before {
  content: "";
}

.pi-hashtag:before {
  content: "";
}

.pi-bookmark-fill:before {
  content: "";
}

.pi-filter-fill:before {
  content: "";
}

.pi-heart-fill:before {
  content: "";
}

.pi-flag-fill:before {
  content: "";
}

.pi-circle:before {
  content: "";
}

.pi-circle-fill:before {
  content: "";
}

.pi-bolt:before {
  content: "";
}

.pi-history:before {
  content: "";
}

.pi-box:before {
  content: "";
}

.pi-at:before {
  content: "";
}

.pi-arrow-up-right:before {
  content: "";
}

.pi-arrow-up-left:before {
  content: "";
}

.pi-arrow-down-left:before {
  content: "";
}

.pi-arrow-down-right:before {
  content: "";
}

.pi-telegram:before {
  content: "";
}

.pi-stop-circle:before {
  content: "";
}

.pi-stop:before {
  content: "";
}

.pi-whatsapp:before {
  content: "";
}

.pi-building:before {
  content: "";
}

.pi-qrcode:before {
  content: "";
}

.pi-car:before {
  content: "";
}

.pi-instagram:before {
  content: "";
}

.pi-linkedin:before {
  content: "";
}

.pi-send:before {
  content: "";
}

.pi-slack:before {
  content: "";
}

.pi-sun:before {
  content: "";
}

.pi-moon:before {
  content: "";
}

.pi-vimeo:before {
  content: "";
}

.pi-youtube:before {
  content: "";
}

.pi-flag:before {
  content: "";
}

.pi-wallet:before {
  content: "";
}

.pi-map:before {
  content: "";
}

.pi-link:before {
  content: "";
}

.pi-credit-card:before {
  content: "";
}

.pi-discord:before {
  content: "";
}

.pi-percentage:before {
  content: "";
}

.pi-euro:before {
  content: "";
}

.pi-book:before {
  content: "";
}

.pi-shield:before {
  content: "";
}

.pi-paypal:before {
  content: "";
}

.pi-amazon:before {
  content: "";
}

.pi-phone:before {
  content: "";
}

.pi-filter-slash:before {
  content: "";
}

.pi-facebook:before {
  content: "";
}

.pi-github:before {
  content: "";
}

.pi-twitter:before {
  content: "";
}

.pi-step-backward-alt:before {
  content: "";
}

.pi-step-forward-alt:before {
  content: "";
}

.pi-forward:before {
  content: "";
}

.pi-backward:before {
  content: "";
}

.pi-fast-backward:before {
  content: "";
}

.pi-fast-forward:before {
  content: "";
}

.pi-pause:before {
  content: "";
}

.pi-play:before {
  content: "";
}

.pi-compass:before {
  content: "";
}

.pi-id-card:before {
  content: "";
}

.pi-ticket:before {
  content: "";
}

.pi-file-o:before {
  content: "";
}

.pi-reply:before {
  content: "";
}

.pi-directions-alt:before {
  content: "";
}

.pi-directions:before {
  content: "";
}

.pi-thumbs-up:before {
  content: "";
}

.pi-thumbs-down:before {
  content: "";
}

.pi-sort-numeric-down-alt:before {
  content: "";
}

.pi-sort-numeric-up-alt:before {
  content: "";
}

.pi-sort-alpha-down-alt:before {
  content: "";
}

.pi-sort-alpha-up-alt:before {
  content: "";
}

.pi-sort-numeric-down:before {
  content: "";
}

.pi-sort-numeric-up:before {
  content: "";
}

.pi-sort-alpha-down:before {
  content: "";
}

.pi-sort-alpha-up:before {
  content: "";
}

.pi-sort-alt:before {
  content: "";
}

.pi-sort-amount-up:before {
  content: "";
}

.pi-sort-amount-down:before {
  content: "";
}

.pi-sort-amount-down-alt:before {
  content: "";
}

.pi-sort-amount-up-alt:before {
  content: "";
}

.pi-palette:before {
  content: "";
}

.pi-undo:before {
  content: "";
}

.pi-desktop:before {
  content: "";
}

.pi-sliders-v:before {
  content: "";
}

.pi-sliders-h:before {
  content: "";
}

.pi-search-plus:before {
  content: "";
}

.pi-search-minus:before {
  content: "";
}

.pi-file-excel:before {
  content: "";
}

.pi-file-pdf:before {
  content: "";
}

.pi-check-square:before {
  content: "";
}

.pi-chart-line:before {
  content: "";
}

.pi-user-edit:before {
  content: "";
}

.pi-exclamation-circle:before {
  content: "";
}

.pi-android:before {
  content: "";
}

.pi-google:before {
  content: "";
}

.pi-apple:before {
  content: "";
}

.pi-microsoft:before {
  content: "";
}

.pi-heart:before {
  content: "";
}

.pi-mobile:before {
  content: "";
}

.pi-tablet:before {
  content: "";
}

.pi-key:before {
  content: "";
}

.pi-shopping-cart:before {
  content: "";
}

.pi-comments:before {
  content: "";
}

.pi-comment:before {
  content: "";
}

.pi-briefcase:before {
  content: "";
}

.pi-bell:before {
  content: "";
}

.pi-paperclip:before {
  content: "";
}

.pi-share-alt:before {
  content: "";
}

.pi-envelope:before {
  content: "";
}

.pi-volume-down:before {
  content: "";
}

.pi-volume-up:before {
  content: "";
}

.pi-volume-off:before {
  content: "";
}

.pi-eject:before {
  content: "";
}

.pi-money-bill:before {
  content: "";
}

.pi-images:before {
  content: "";
}

.pi-image:before {
  content: "";
}

.pi-sign-in:before {
  content: "";
}

.pi-sign-out:before {
  content: "";
}

.pi-wifi:before {
  content: "";
}

.pi-sitemap:before {
  content: "";
}

.pi-chart-bar:before {
  content: "";
}

.pi-camera:before {
  content: "";
}

.pi-dollar:before {
  content: "";
}

.pi-lock-open:before {
  content: "";
}

.pi-table:before {
  content: "";
}

.pi-map-marker:before {
  content: "";
}

.pi-list:before {
  content: "";
}

.pi-eye-slash:before {
  content: "";
}

.pi-eye:before {
  content: "";
}

.pi-folder-open:before {
  content: "";
}

.pi-folder:before {
  content: "";
}

.pi-video:before {
  content: "";
}

.pi-inbox:before {
  content: "";
}

.pi-lock:before {
  content: "";
}

.pi-unlock:before {
  content: "";
}

.pi-tags:before {
  content: "";
}

.pi-tag:before {
  content: "";
}

.pi-power-off:before {
  content: "";
}

.pi-save:before {
  content: "";
}

.pi-question-circle:before {
  content: "";
}

.pi-question:before {
  content: "";
}

.pi-copy:before {
  content: "";
}

.pi-file:before {
  content: "";
}

.pi-clone:before {
  content: "";
}

.pi-calendar-times:before {
  content: "";
}

.pi-calendar-minus:before {
  content: "";
}

.pi-calendar-plus:before {
  content: "";
}

.pi-ellipsis-v:before {
  content: "";
}

.pi-ellipsis-h:before {
  content: "";
}

.pi-bookmark:before {
  content: "";
}

.pi-globe:before {
  content: "";
}

.pi-replay:before {
  content: "";
}

.pi-filter:before {
  content: "";
}

.pi-print:before {
  content: "";
}

.pi-align-right:before {
  content: "";
}

.pi-align-left:before {
  content: "";
}

.pi-align-center:before {
  content: "";
}

.pi-align-justify:before {
  content: "";
}

.pi-cog:before {
  content: "";
}

.pi-cloud-download:before {
  content: "";
}

.pi-cloud-upload:before {
  content: "";
}

.pi-cloud:before {
  content: "";
}

.pi-pencil:before {
  content: "";
}

.pi-users:before {
  content: "";
}

.pi-clock:before {
  content: "";
}

.pi-user-minus:before {
  content: "";
}

.pi-user-plus:before {
  content: "";
}

.pi-trash:before {
  content: "";
}

.pi-external-link:before {
  content: "";
}

.pi-window-maximize:before {
  content: "";
}

.pi-window-minimize:before {
  content: "";
}

.pi-refresh:before {
  content: "";
}

.pi-user:before {
  content: "";
}

.pi-exclamation-triangle:before {
  content: "";
}

.pi-calendar:before {
  content: "";
}

.pi-chevron-circle-left:before {
  content: "";
}

.pi-chevron-circle-down:before {
  content: "";
}

.pi-chevron-circle-right:before {
  content: "";
}

.pi-chevron-circle-up:before {
  content: "";
}

.pi-angle-double-down:before {
  content: "";
}

.pi-angle-double-left:before {
  content: "";
}

.pi-angle-double-right:before {
  content: "";
}

.pi-angle-double-up:before {
  content: "";
}

.pi-angle-down:before {
  content: "";
}

.pi-angle-left:before {
  content: "";
}

.pi-angle-right:before {
  content: "";
}

.pi-angle-up:before {
  content: "";
}

.pi-upload:before {
  content: "";
}

.pi-download:before {
  content: "";
}

.pi-ban:before {
  content: "";
}

.pi-star-fill:before {
  content: "";
}

.pi-star:before {
  content: "";
}

.pi-chevron-left:before {
  content: "";
}

.pi-chevron-right:before {
  content: "";
}

.pi-chevron-down:before {
  content: "";
}

.pi-chevron-up:before {
  content: "";
}

.pi-caret-left:before {
  content: "";
}

.pi-caret-right:before {
  content: "";
}

.pi-caret-down:before {
  content: "";
}

.pi-caret-up:before {
  content: "";
}

.pi-search:before {
  content: "";
}

.pi-check:before {
  content: "";
}

.pi-check-circle:before {
  content: "";
}

.pi-times:before {
  content: "";
}

.pi-times-circle:before {
  content: "";
}

.pi-plus:before {
  content: "";
}

.pi-plus-circle:before {
  content: "";
}

.pi-minus:before {
  content: "";
}

.pi-minus-circle:before {
  content: "";
}

.pi-circle-on:before {
  content: "";
}

.pi-circle-off:before {
  content: "";
}

.pi-sort-down:before {
  content: "";
}

.pi-sort-up:before {
  content: "";
}

.pi-sort:before {
  content: "";
}

.pi-step-backward:before {
  content: "";
}

.pi-step-forward:before {
  content: "";
}

.pi-th-large:before {
  content: "";
}

.pi-arrow-down:before {
  content: "";
}

.pi-arrow-left:before {
  content: "";
}

.pi-arrow-right:before {
  content: "";
}

.pi-arrow-up:before {
  content: "";
}

.pi-bars:before {
  content: "";
}

.pi-arrow-circle-down:before {
  content: "";
}

.pi-arrow-circle-left:before {
  content: "";
}

.pi-arrow-circle-right:before {
  content: "";
}

.pi-arrow-circle-up:before {
  content: "";
}

.pi-info:before {
  content: "";
}

.pi-info-circle:before {
  content: "";
}

.pi-home:before {
  content: "";
}

.pi-spinner:before {
  content: "";
}

.rdw-option-wrapper {
  min-width: 25px;
  height: 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 5px;
  display: flex;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-option-wrapper:active, .rdw-option-active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-option-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdown-wrapper:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-dropdown-carettoopen {
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-carettoclose {
  height: 0;
  width: 0;
  border-bottom: 6px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-selectedtext {
  height: 100%;
  align-items: center;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  width: 98%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-inline-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-block-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  justify-content: center;
  display: flex;
}

.rdw-fontfamily-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-text-align-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left !important;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
}

.rdw-right-aligned-block > div, .rdw-left-aligned-block > div, .rdw-center-aligned-block > div, .rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-colorpicker-modal {
  width: 175px;
  height: 175px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-colorpicker-modal-header {
  padding-bottom: 5px;
  display: flex;
}

.rdw-colorpicker-modal-style-label {
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
  font-size: 15px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 5px auto;
  display: flex;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1;
}

.rdw-colorpicker-option {
  min-height: 20px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: none;
  margin: 3px;
  padding: 0;
  box-shadow: inset 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd;
}

.rdw-colorpicker-option-active {
  box-shadow: 0 0 2px 2px #bfbdbd;
}

.rdw-link-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  width: 235px;
  height: 205px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  height: 25px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 10px;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-embedded-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  background: #6eb8d4;
  border: 1px solid #f1f1f1;
  border-bottom: 2px solid #0a66b7;
  margin-top: 5px;
}

.rdw-embedded-modal-link-section {
  flex-direction: column;
  display: flex;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-embedded-modal-link-input-wrapper {
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  justify-content: center;
  display: flex;
}

.rdw-embedded-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-emoji-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  overflow: auto;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-emoji-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  font-size: 22px;
  display: flex;
}

.rdw-spinner {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.rdw-spinner .rdw-bounce1 {
  animation-delay: -.32s;
}

.rdw-spinner .rdw-bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.rdw-image-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-image-modal {
  width: 235px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-header {
  margin: 10px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  outline-offset: -10px;
  background-color: #f1f1f1;
  border: none;
  outline: 2px dashed gray;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 9px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}

.rdw-image-modal-upload-option-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.rdw-image-modal-url-section {
  align-items: center;
  display: flex;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 15px 0 12px;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 5px;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: -3px;
  left: 0;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-history-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-history-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 40%;
}

.rdw-mention-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  min-width: 100px;
  max-height: 150px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: auto;
}

.rdw-suggestion-option {
  border-bottom: 1px solid #f1f1f1;
  padding: 7px 5px;
}

.rdw-suggestion-option-active {
  background-color: #f1f1f1;
}

.rdw-hashtag-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-image-alignment-options-popup {
  width: 105px;
  cursor: pointer;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px 2px;
  display: flex;
  position: absolute;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment, .rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  justify-content: center;
  display: flex;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  justify-content: flex-end;
  display: flex;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.rdw-editor-toolbar {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 6px 5px 0;
  font-size: 15px;
  display: flex;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: #fff0;
  border-left: .1px solid #0000;
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  z-index: 0;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

div.menubar-hover ul.p-tabmenu-nav {
  background-color: #ccc;
}

.smart-form .smiley-rating .rating-field-wrapper {
  gap: 23px;
  display: flex;
}

.smart-form .smiley-rating .rating-field > label {
  cursor: pointer;
  height: 39px;
  width: 39px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  transition: all .2s ease-in-out;
  display: flex;
  padding: 3px !important;
}

.smart-form .smiley-rating .rating-field > label:hover {
  border-color: #0071e2;
}

.smart-form .smiley-rating .rating-field:hover label {
  border-color: #a1a1a1;
}

.smart-form .smiley-rating .rating-field input[type="radio"]:checked ~ label {
  border-color: #0071e2;
}

.smart-form .smiley-rating .rating-field input[type="radio"]:focus ~ label {
  border-color: #104077;
}

.smart-form .smiley-rating .rating-field input[type="radio"][aria-invalid="true"] ~ label {
  border-color: #f93234;
}

.colorfulpicker {
  align-items: center;
  display: flex;
  position: relative;
}

.colorfulpicker .preview {
  height: 34px;
  width: 34px;
  border: 1px solid #ccc;
  border-right: none;
}

.colorfulpicker .preview.focus {
  box-shadow: none;
  border-color: #009fdf !important;
}

.colorfulpicker .colorfulpicker-container {
  z-index: 1;
  display: none;
  position: absolute;
  top: 37px;
  left: 0;
}

.colorfulpicker .colorfulpicker-container.show {
  display: block;
}

.colorfulpicker .colorfulpicker-container .react-colorful__last-control, .colorfulpicker .colorfulpicker-container .react-colorful__saturation {
  border-radius: 0;
}

.smartform-field-array-group:first-child .head {
  margin-top: 0;
}

.smartform-field-array-group .head {
  color: #495057;
  cursor: pointer;
  height: 38px;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  align-items: center;
  margin-top: 16px;
  padding: 10px;
  font-size: 14px;
  display: flex;
}

.smartform-field-array-group .head.active .delete-button, .smartform-field-array-group .head:hover .delete-button {
  opacity: 1;
  pointer-events: auto;
}

.smartform-field-array-group .head.active {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.smartform-field-array-group .head.active ~ .body {
  border-color: #dee2e6;
}

.smartform-field-array-group .head .delete-button {
  background-color: initial;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  margin-left: auto;
  font-size: 20px;
  transition: opacity .2s ease-in-out, color .2s ease-in-out;
}

.smartform-field-array-group .head .delete-button:hover {
  color: #dc3545;
}

.smartform-field-array-group .body {
  margin-top: -1px;
}

.smartform-field-array .smartform-field-array-interface, .smartform-field-array-group .body {
  border: 1px solid #ced4da;
  padding: 1rem;
}

.smartform-field-array .smartform-field-array-interface .add-button {
  margin-top: 16px;
}

.smartform-field-array .smartform-field-array-interface .add-button span {
  margin-left: 10px;
}

.field.invalid {
  border: 1px solid red;
}

.smartlist-filter {
  margin: 5px;
}

.smartlist-sort {
  margin: 0 5px 5px;
}

.smartlist-refresh-button {
  height: 20px;
  width: 20px;
  font-size: 1.25em;
}

.smartlist-ordering, .smartlist-sort-order {
  display: flex;
}

.smartlist-sort-option-inactive {
  filter: opacity(50%);
}

.smartlist-sort-order-button {
  margin: 5px;
}

.smartlist-sort-field .p-dropdown {
  width: 100%;
}

.yrPvz {
  pointer-events: none;
  z-index: 11;
  position: absolute;
  top: 25px;
  right: 25px;
}

.-XbtI {
  margin-top: 40px;
  position: relative;
}

.-XbtI:first-child {
  margin-top: 0;
}

.SNMht {
  color: #fff;
  cursor: pointer;
  height: 40px;
  pointer-events: auto;
  width: 40px;
  background-color: #525252b3;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px;
  font-size: 18px;
  display: flex;
}

.SNMht:hover {
  background-color: #525252;
}

.SNMht.P6jaN, .SNMht:active {
  background-color: #393939;
}

.SNMht svg {
  fill: #fff;
  height: 20px;
  width: 20px;
}

.action-button-with-window-container {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.action-button-with-window-container .actionbar-button svg.icon-loading {
  height: 26px !important;
  width: 26px !important;
}

.action-button-with-window-container .window-container {
  height: 500px;
  pointer-events: none;
  width: 500px;
  position: absolute;
  top: 0;
  right: 40px;
  overflow: hidden;
}

.action-button-with-window-container .window-container .window {
  pointer-events: auto;
  transition: right .3s;
  position: absolute;
  top: 0;
  right: -510px;
}

.action-button-with-window-container .window-container .window.show {
  right: 10px;
}

.action-button-with-window-container .window-container .window > .window-head {
  height: 40px;
  background-color: #393939;
  align-items: center;
  display: flex;
}

.action-button-with-window-container .window-container .window > .window-head > h2 {
  color: #fff;
  text-transform: uppercase;
  margin-left: 10px;
  font-family: open_sanssemibold, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
}

.action-button-with-window-container .window-container .window > .window-head > .close-btn {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  outline: none;
  margin-left: auto;
}

.action-button-with-window-container .window-container .window > .window-head > .close-btn > svg {
  fill: #fff;
  height: 14px;
  width: 14px;
}

.action-button-with-window-container .window-container .window > .window-body {
  max-width: 500px;
  background-color: #ffffffe6;
}

.flatpickr-calendar {
  box-sizing: border-box;
  direction: ltr;
  opacity: 0;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
  background: none;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 0 3px 13px #00000014;
}

.flatpickr-calendar.inline, .flatpickr-calendar.open {
  max-height: 640px;
  opacity: 1;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasTime .dayContainer, .flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #48484833;
}

.flatpickr-calendar.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid #48484833;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:after, .flatpickr-calendar:before {
  content: "";
  height: 0;
  pointer-events: none;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.arrowRight:after, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:after, .flatpickr-calendar.arrowCenter:before {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #48484833;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #42a5f5;
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #48484833;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #42a5f5;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  fill: #fff;
  color: #fff;
  height: 34px;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #42a5f5;
  border-radius: 5px 5px 0 0;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
  fill: #fff;
  color: #fff;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled, .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-next-month i, .flatpickr-months .flatpickr-prev-month i {
  position: relative;
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month, .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month, .flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-next-month:hover, .flatpickr-months .flatpickr-prev-month:hover {
  color: #bbb;
}

.flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-next-month svg, .flatpickr-months .flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}

.flatpickr-months .flatpickr-next-month svg path, .flatpickr-months .flatpickr-prev-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-inner-spin-button, .numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  box-sizing: border-box;
  cursor: pointer;
  height: 50%;
  opacity: 0;
  width: 14px;
  border: 1px solid #48484826;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #0000001a;
}

.numInputWrapper span:active {
  background: #0003;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #48484899;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #48484899;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  height: auto;
  width: inherit;
}

.numInputWrapper span svg path {
  fill: #ffffff80;
}

.numInputWrapper:hover {
  background: #0000000d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  color: inherit;
  height: 34px;
  font-size: 135%;
  font-weight: 300;
  line-height: inherit;
  text-align: center;
  width: 75%;
  padding: 7.48px 0 0;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translateZ(0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #0000000d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  appearance: textfield;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-family: inherit;
  font-size: inherit;
  height: auto;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #ffffff80;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  height: auto;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: #42a5f5;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active, .flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #0000000d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #42a5f5;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  height: 28px;
  text-align: center;
  width: 100%;
  background: #42a5f5;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  color: #0000008a;
  cursor: default;
  text-align: center;
  background: #42a5f5;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  border-left: 1px solid #48484833;
  border-right: 1px solid #48484833;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  box-sizing: border-box;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  text-align: left;
  width: 307.875px;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translateZ(0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #48484833;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  height: 39px;
  max-width: 39px;
  text-align: center;
  width: 14.2857%;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
  cursor: pointer;
  background: #e2e2e2;
  border-color: #e2e2e2;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #bbb;
}

.flatpickr-day.today:focus, .flatpickr-day.today:hover {
  color: #fff;
  background: #bbb;
  border-color: #bbb;
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  box-shadow: none;
  color: #fff;
  background: #42a5f5;
  border-color: #42a5f5;
}

.flatpickr-day.endRange.startRange, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.endRange.endRange, .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #42a5f5;
}

.flatpickr-day.endRange.startRange.endRange, .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e2e2e2, 5px 0 #e2e2e2;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.prevMonthDay {
  color: #4848484d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  color: #4848481a;
  cursor: not-allowed;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #42a5f5, 5px 0 #42a5f5;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  border-left: 1px solid #48484833;
  padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  color: #4848484d;
  cursor: default;
  max-width: none;
  width: 100%;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #48484833;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  box-sizing: border-box;
  height: 0;
  max-height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 0 0 5px 5px;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}

.flatpickr-time .numInputWrapper {
  float: left;
  height: 40px;
  width: 40%;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  appearance: textfield;
  box-shadow: none;
  box-sizing: border-box;
  color: #484848;
  height: inherit;
  font-size: 14px;
  line-height: inherit;
  text-align: center;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-am-pm, .flatpickr-time .flatpickr-time-separator {
  color: #484848;
  float: left;
  height: inherit;
  font-weight: 700;
  line-height: inherit;
  -webkit-user-select: none;
  user-select: none;
  width: 2%;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  text-align: center;
  width: 18%;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time .flatpickr-am-pm:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time input:hover {
  background: #eaeaea;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-months .flatpickr-month, .flatpickr-weekdays {
  color: #000;
  background-color: #bdeaff;
}

span.flatpickr-weekday {
  background-color: initial;
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
  border-bottom-color: #bdeaff;
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
  border-top-color: #bdeaff;
}

.flatpickr-day.selected {
  background-color: #00b0ff;
  border-color: #00b0ff;
}

.flatpickr-months svg {
  fill: #000;
  color: #000;
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  width: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  overflow: hidden;
  height: auto !important;
  width: auto !important;
}

.simplebar-mask, .simplebar-offset {
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  box-sizing: inherit !important;
  direction: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  -ms-overflow-style: none;
  direction: inherit;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  scrollbar-width: none;
  width: auto;
  display: block;
  position: relative;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.simplebar-content:after, .simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  width: 100%;
}

.simplebar-height-auto-observer-wrapper {
  flex-basis: 0;
  flex-grow: inherit;
  float: left;
  height: 100%;
  max-height: 1px;
  max-width: 1px;
  pointer-events: none;
  width: 100%;
  z-index: -1;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
  opacity: 0;
  width: 1000%;
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
}

.simplebar-height-auto-observer, .simplebar-track {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  bottom: 0;
  right: 0;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
  top: 2px;
  left: 0;
  right: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  height: 500px;
  opacity: 0;
  visibility: hidden;
  width: 500px;
  position: fixed;
  overflow-x: scroll;
  overflow-y: hidden;
}

.simplebar-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  visibility: hidden;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.noDisplay {
  pointer-events: none;
  visibility: hidden !important;
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
}

body, html {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

html {
  -ms-content-zooming: none;
  text-size-adjust: 100%;
  touch-action: none;
}

body {
  font-family: open_sansregular, sans-serif;
  font-size: 14px;
}

span.semibold {
  font-family: open_sanssemibold, sans-serif;
  font-weight: 400;
}

span.bold {
  font-family: open_sansbold, sans-serif;
  font-weight: 400;
}

span.italic {
  font-family: open_sansitalic, sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: open_sansregular, sans-serif;
  font-weight: 400;
}

.clear {
  clear: both;
}

.rotate180 {
  transition: all .2s;
  transform: rotate(180deg);
}

.icon-spin {
  -webkit-filter: blur();
  margin-right: 10px;
  animation: 2s linear infinite spin;
  line-height: 0 !important;
}

.block-form-field {
  flex-direction: column;
  margin-bottom: 16px;
  padding: 0;
  display: flex;
}

.block-form-field label {
  margin-bottom: 10px;
}

.block-form-field .maxchoices {
  margin: -10px 0 15px;
}

.block-form-field .inputtext {
  appearance: none;
  color: #495057;
  width: 100%;
  background: #fff;
  border: 1px solid #777;
  border-radius: 3px;
  padding: .5rem;
  font-family: inherit;
  font-size: 1rem;
  transition: border-color .2s;
}

.block-form-field .inputtext:hover {
  border-color: #929292;
}

.block-form-field .inputtext:focus {
  border-color: #00b0ff;
}

.block-form-field .inputtext:focus-visible {
  outline: none;
}

.block-form-field fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.block-form-field fieldset legend {
  margin-bottom: 10px;
}

.block-form-field .checkbox-field-item input[type="checkbox"], .block-form-field .radio-field-item input[type="radio"] {
  margin-right: 10px;
}

.block-form-field .checkbox-field-item.disabled {
  opacity: .5;
  pointer-events: none;
}

.block-form-field-slider .range-slider {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  padding: 50px 0 0;
  display: flex;
  position: relative;
}

.block-form-field-slider .range-slider input[type="range"] {
  -webkit-appearance: none;
  background: #929292;
  background-image: linear-gradient(var(--accentColor), var(--accentColor));
  height: 7px;
  width: 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.block-form-field-slider .range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--accentColor);
  box-shadow: 0 0 2px 0 var(--accentColor);
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.block-form-field-slider .range-slider input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  background: none;
  border: none;
}

.block-form-field-slider .range-slider input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: color-mix(in srgb, var(--accentColor), #0000 75%) 0 0 0 7px;
}

.block-form-field-slider .range-slider input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: color-mix(in srgb, var(--accentColor), #0000 50%) 0 0 0 11px;
  transition: box-shadow .35s cubic-bezier(.4, 0, .2, 1), left .35s cubic-bezier(.4, 0, .2, 1), bottom .35s cubic-bezier(.4, 0, .2, 1);
}

.block-form-field-slider .range-slider .range-value {
  pointer-events: none;
  position: absolute;
  transform: translateY(-40px);
}

.block-form-field-slider .range-slider .range-value span {
  color: var(--accentColor);
  text-align: center;
  white-space: nowrap;
  font-weight: 600;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.block-form-field-slider .range-slider .range-labels {
  color: var(--accentColor);
  justify-content: space-between;
  margin-top: 20px;
  font-weight: 600;
  display: flex;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper {
  flex-wrap: wrap;
  gap: 5px clamp(5px, 10%, 23px);
  transition: gap .1s ease-in-out;
  display: flex;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper .rating-field input[type="radio"]:not(:checked) ~ label {
  opacity: 1;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper:hover .rating-field-item input[type="radio"]:not(:checked) ~ label {
  filter: grayscale();
  opacity: .7;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper:hover .rating-field-item input[type="radio"]:not(:checked) ~ label:hover {
  filter: none;
  opacity: 1;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper.hasvalue .rating-field-item input[type="radio"]:not(:checked) ~ label {
  filter: grayscale();
  opacity: .7;
}

.block-form-field-smiley .smiley-rating .rating-field-wrapper.hasvalue .rating-field-item input[type="radio"]:checked ~ label {
  filter: none;
  opacity: 1;
}

.block-form-field-smiley .smiley-rating .rating-field-item > label {
  cursor: pointer;
  height: 39px;
  width: 39px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-bottom: 0;
  transition: all .2s ease-in-out;
  display: flex;
  padding: 3px !important;
}

.block-form-field-smiley .smiley-rating .rating-field-item input[type="radio"]:checked ~ label, .block-form-field-smiley .smiley-rating .rating-field-item:hover label, .block-form-field-smiley .smiley-rating .rating-field-item > label:hover {
  border-color: var(--accentColor);
}

.block-form-field-smiley .smiley-rating .rating-field-item input[type="radio"]:focus ~ label {
  border-color: var(--accentColor-darkest);
}

.block-form-field-smiley .smiley-rating .rating-field-item input[type="radio"][aria-invalid="true"] ~ label {
  border-color: #f93234;
}

.block-form-field-checkbox.image-choice-grid .field-wrap {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  display: flex;
  container-type: inline-size;
}

.block-form-field-checkbox.image-choice-grid .checkbox-field-item {
  min-width: 140px;
  justify-content: flex-start;
}

.block-form-field-checkbox.image-choice-grid .checkbox-field-item label {
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.block-form-field-checkbox.image-choice-grid .image-wrap {
  cursor: pointer;
  justify-content: center;
  padding-bottom: 5px;
  display: flex;
  position: relative;
}

.block-form-field-checkbox.image-choice-grid .image-wrap img {
  max-width: 150px;
  object-fit: contain;
}

.block-form-field-checkbox.image-choice-grid .control-label {
  overflow-wrap: break-word;
}

.block-form-field-checkbox.image-choice-grid .checkbox-wrap {
  max-width: 100%;
  margin-bottom: 3px;
  display: flex;
  position: relative;
}

@container (max-width: 450px) {
  .block-form-field-checkbox.image-choice-grid .field-wrap .checkbox-field-item {
    min-width: 100%;
    flex: none;
  }
}

@container (min-width: 450px) {
  .block-form-field-checkbox.image-choice-grid .field-wrap .checkbox-field-item {
    max-width: calc(50% - 5px);
    width: 100%;
  }
}

@container (min-width: 768px) {
  .block-form-field-checkbox.image-choice-grid .field-wrap .checkbox-field-item {
    max-width: calc(33% - 5px);
    width: 100%;
  }
}

@container (min-width: 1024px) {
  .block-form-field-checkbox.image-choice-grid .field-wrap .checkbox-field-item {
    max-width: none;
    width: auto;
    flex-basis: 19%;
  }
}

@container (min-width: 1400px) {
  .block-form-field-checkbox.image-choice-grid .field-wrap .checkbox-field-item {
    max-width: none;
    width: auto;
    flex-basis: 16%;
  }
}

.block-form-field-dropdown .react-select__menu {
  position: relative !important;
}

.cc {
  display: none;
}

.cc, .cc *, .cc :after, .cc :before, .cc + .cc-btn, .cc:after, .cc:before {
  box-sizing: border-box;
}

.cc-btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: 0;
  position: relative;
}

input[type="checkbox"].cc-theme1 + .cc-btn {
  background-color: initial;
  border: 1px solid #393939;
  border-radius: 5px;
  padding: 8px;
  display: inline-block;
  position: relative;
}

input[type="checkbox"].cc-theme1 + .cc-btn svg {
  fill: #00b0ff;
  height: 14px;
  width: 14px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"].cc-theme1:checked + .cc-btn svg {
  display: block;
}

input[type="radio"].cc-theme1 + .cc-btn {
  background-color: initial;
  border: 1px solid #393939;
  border-radius: 100%;
  padding: 7px;
  position: relative;
}

input[type="radio"].cc-theme1 + .cc-btn:before {
  content: "";
  background: none;
  border-radius: 100%;
  padding: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="radio"].cc-theme1:checked + .cc-btn:before {
  background-color: #00b0ff;
}

.cc-btn > .fa {
  color: #b8b8b8;
  font-size: 14px;
}

input[type="checkbox"].cc-theme2 + .cc-btn {
  height: auto;
  width: auto;
  display: block;
}

input[type="checkbox"].cc-theme2:hover + .fa {
  color: #929292;
}

input[type="checkbox"].cc-theme2:hover + .fa.disabled {
  color: #b8b8b8;
}

input[type="checkbox"].cc-theme2:checked + .fa {
  color: #00b0ff;
}

input[type="checkbox"].cc-theme2:checked + .fa.disabled {
  color: #b8b8b8;
}

input[type="checkbox"].cc-theme2:checked + .fa:hover {
  color: #007bb3;
}

input[type="checkbox"].cc-theme2:checked + .fa:hover.disabled {
  color: #929292;
}

input[type="checkbox"].cc-theme2black + .cc-btn {
  height: auto;
  width: auto;
  display: block;
}

input[type="checkbox"].cc-theme2black:hover + .fa {
  color: #929292;
}

input[type="checkbox"].cc-theme2black:hover + .fa.disabled {
  color: #b8b8b8;
}

input[type="checkbox"].cc-theme2black:checked + .fa {
  color: #000;
}

input[type="checkbox"].cc-theme2black:checked + .fa.disabled {
  color: #b8b8b8;
}

input[type="checkbox"].cc-theme2black:checked + .fa:hover {
  color: #007bb3;
}

input[type="checkbox"].cc-theme2black:checked + .fa:hover.disabled {
  color: #929292;
}

input[type="checkbox"].cc-theme3 + .cc-btn {
  height: 20px;
  width: 40px;
  background-color: #ebf9ff;
  margin: 3px;
  padding: 2px;
  transition: all .4s;
  display: block;
}

input[type="checkbox"].cc-theme3 + .cc-btn:after, input[type="checkbox"].cc-theme3 + .cc-btn:before {
  content: "|||";
  height: 16px;
  text-align: center;
  width: 16px;
  background: #bdeaff;
  font-size: 12px;
  transition: all .1s;
  display: block;
  position: relative;
}

input[type="checkbox"].cc-theme3 + .cc-btn:after {
  left: 0;
}

input[type="checkbox"].cc-theme3 + .cc-btn:before {
  display: none;
}

input[type="checkbox"].cc-theme3:checked + .cc-btn:after {
  background-color: #00b0ff;
  left: 20px;
}

input[type="checkbox"].cc-theme3:checked + .cc-btn:before {
  background-color: #00b0ff;
}

.measurement-window {
  box-sizing: border-box;
  min-width: 210px;
  padding: 10px;
}

.measurement-window.results {
  min-height: 95px;
}

@media screen and (max-height: 180px) and (orientation: landscape), screen and (max-width: 768px) and (orientation: portrait) {
  .measurement-window.results {
    height: auto;
    min-height: 0;
    overflow: visible;
  }
}

.measurement-window .tool-row {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.measurement-window .tool-row .btn {
  background-color: initial;
  color: #000;
  cursor: pointer;
  height: 37px;
  width: 37px;
  border: 1px solid #bdeaff;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 24px;
  display: flex;
}

.measurement-window .tool-row .btn.active, .measurement-window .tool-row .btn:hover {
  background-color: #bdeaff;
}

.measurement-window .tool-row .btn:active {
  background-color: #009be0;
}

.measurement-window .tool-row .btn .icon {
  text-align: center;
  margin: auto;
  display: block;
}

.measurement-window .tool-row .btn .icon-measurement-circle, .measurement-window .tool-row .btn .icon-measurement-line {
  height: 24px;
  width: 24px;
}

.measurement-window .tool-row .btn .icon-measurement-square {
  height: 28px;
  width: 28px;
}

.measurement-window .result {
  margin-bottom: 10px;
}

.measurement-window .result.hidden .body {
  max-height: 0 !important;
}

.measurement-window .result .body, .measurement-window .result .head {
  color: #000;
  background-color: #e2e2e280;
}

.measurement-window .result .head {
  cursor: pointer;
  align-items: center;
  padding: 5px;
  display: flex;
}

.measurement-window .result .head:hover {
  background-color: #d3d3d3;
}

.measurement-window .result .head span {
  margin-right: 5px;
}

.measurement-window .result .head span:first-child {
  padding-left: 5px;
}

.measurement-window .result .head .icon-caret-down {
  height: 18px;
  width: 18px;
  margin-left: auto;
  padding: 0 5px;
  transition: transform .3s ease-in-out;
}

.measurement-window .result .head .icon-caret-down.rotate180 {
  transform: rotate(180deg);
}

.measurement-window .result .body {
  transition: max-height .3s;
}

.measurement-window .result .body ul {
  padding: 10px;
  list-style: none;
}

.measurement-window .result .body ul li {
  align-items: center;
  padding: 3px 3px 3px 0;
  display: flex;
}

.measurement-window .result .body ul li span:last-child {
  margin-left: auto;
  font-weight: 700;
}

.measurement-window .refresh-btn {
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  background-color: #bdeaff;
  border: 0;
  outline: 0;
  align-items: center;
  padding: 5px 10px;
  font-size: 13px;
  display: flex;
}

.measurement-window .refresh-btn:hover {
  background-color: #ccc;
}

.measurement-window .refresh-btn:active {
  background-color: #bdbdbd;
}

.measurement-window .refresh-btn .icon-refresh {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.action-button-with-window-container.print .actionbar-button svg.icon-print {
  height: 22px !important;
  width: 22px !important;
}

.action-button-with-window-container.print .window-container {
  height: 100vh;
  width: 700px;
  top: 50%;
  transform: translateY(-25%);
}

.action-button-with-window-container.print .window-container .window {
  right: -710px;
}

.action-button-with-window-container.print .window-container .window.show {
  right: 10px;
}

.action-button-with-window-container.print .window-container .window > .window-body {
  max-width: 700px;
}

.print-window .print-ui {
  padding: 15px;
}

@media screen and (max-width: 1024px) {
  .print-window .print-ui {
    padding: 15px;
    font-size: 13px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window .print-ui {
    padding: 15px;
    font-size: 13px;
  }
}

.print-window .print-title {
  width: 100px;
  font-family: open_sanssemibold, sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .print-window .print-title {
    width: 80px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window .print-title {
    width: 80px;
  }
}

.print-window .print-format, .print-window .print-layout, .print-window .print-orientation, .print-window .print-scale {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .print-window .print-format, .print-window .print-layout, .print-window .print-orientation, .print-window .print-scale {
    margin-bottom: 10px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window .print-format, .print-window .print-layout, .print-window .print-orientation, .print-window .print-scale {
    margin-bottom: 10px;
  }
}

.print-window .print-options, .print-window .print-properties {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .print-window .print-options, .print-window .print-properties {
    margin-bottom: 10px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window .print-options, .print-window .print-properties {
    margin-bottom: 10px;
  }
}

.print-window .print-format, .print-window .print-orientation, .print-window .print-scale {
  height: 22px;
}

.print-window .print-format > label, .print-window .print-options > label, .print-window .print-orientation > label, .print-window .print-properties > label, .print-window .print-scale > label {
  cursor: pointer;
  flex-basis: 100px;
  align-items: center;
  display: flex;
}

.print-window .print-format > label input:disabled ~ span, .print-window .print-format > label.disabled, .print-window .print-options > label input:disabled ~ span, .print-window .print-options > label.disabled, .print-window .print-orientation > label input:disabled ~ span, .print-window .print-orientation > label.disabled, .print-window .print-properties > label input:disabled ~ span, .print-window .print-properties > label.disabled, .print-window .print-scale > label input:disabled ~ span, .print-window .print-scale > label.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.print-window .print-format .print-format-span, .print-window .print-format .print-option-span, .print-window .print-format .print-orientation-span, .print-window .print-format .print-properties-span, .print-window .print-format .print-scale-span, .print-window .print-options .print-format-span, .print-window .print-options .print-option-span, .print-window .print-options .print-orientation-span, .print-window .print-options .print-properties-span, .print-window .print-options .print-scale-span, .print-window .print-orientation .print-format-span, .print-window .print-orientation .print-option-span, .print-window .print-orientation .print-orientation-span, .print-window .print-orientation .print-properties-span, .print-window .print-orientation .print-scale-span, .print-window .print-properties .print-format-span, .print-window .print-properties .print-option-span, .print-window .print-properties .print-orientation-span, .print-window .print-properties .print-properties-span, .print-window .print-properties .print-scale-span, .print-window .print-scale .print-format-span, .print-window .print-scale .print-option-span, .print-window .print-scale .print-orientation-span, .print-window .print-scale .print-properties-span, .print-window .print-scale .print-scale-span {
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.print-window .print-layout > label {
  cursor: pointer;
  align-items: center;
  margin-right: 12px;
  display: flex;
  position: relative;
}

.print-window .print-layout > label:last-of-type {
  margin-right: 0;
}

.print-window .print-layout > label .cc-btn {
  margin-right: 4px;
}

.print-window .print-layout > label .print-layout-canvas {
  background-color: #fff;
  border: 1px solid #000;
}

@media screen and (max-width: 1024px) {
  .print-window .print-layout > label .print-layout-canvas {
    width: 85px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window .print-layout > label .print-layout-canvas {
    width: 85px;
  }
}

.print-window .print-layout > label .print-layout-span {
  padding-left: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.print-window .print-layout > label[for="print-layout-right"] .print-layout-span {
  padding-left: 12px;
}

.print-window .print-scale #print-scale-custom {
  background-color: initial;
  width: 100px;
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  outline: 0;
  margin-top: 2px;
  font-family: open_sansregular, sans-serif;
}

.print-window .print-options {
  display: -ms-grid;
  -ms-grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-template-rows: 43px;
  grid-template-rows: 43px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  display: grid;
}

.print-window .print-properties {
  grid-column-gap: 15px;
  display: -ms-grid;
  -ms-grid-template-columns: 110px 1fr 110px 1fr;
  -ms-grid-template-rows: 43px 43px;
  grid-template-rows: 43px 43px;
  grid-template-columns: 110px 1fr 110px 1fr;
  align-items: flex-start;
  display: grid;
}

.print-window .print-properties > label {
  padding: 5px 0;
}

.print-window .print-properties > .print-properties-input {
  border: 1px solid #000;
  outline: 0;
  padding: 5px 10px;
  font-family: open_sansregular, sans-serif;
}

.print-window .print-properties > .print-properties-input:disabled {
  cursor: not-allowed;
  opacity: .25;
}

.print-window #print-description {
  box-sizing: border-box;
  max-height: 200px;
  min-height: 80px;
  resize: vertical;
  width: 100%;
  border: 1px solid #000;
  outline: 0;
  padding: 10px;
  font-family: open_sansregular, sans-serif;
  font-size: 14px;
  display: block;
}

@media screen and (max-width: 1024px) {
  .print-window #print-description {
    min-height: auto;
  }
}

@media screen and (max-Height: 850px) {
  .print-window #print-description {
    min-height: auto;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .print-window #print-properties > input, .print-window #print-properties > label, .print-window .print-options > input, .print-window .print-options > label {
    margin: 0 15px 15px 0;
  }
}

.print-window #print-submit-btn {
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
  text-transform: uppercase;
  width: 100%;
  background-color: #00b0ff;
  border: 0;
  outline: 0;
  padding: 10px 0;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .print-window #print-submit-btn {
    padding: 7px 0;
    font-size: 14px;
  }
}

@media screen and (max-Height: 850px) {
  .print-window #print-submit-btn {
    padding: 7px 0;
    font-size: 14px;
  }
}

.print-window #print-submit-btn:hover {
  background-color: #009be0;
}

.print-window #print-submit-btn:active {
  background-color: #0086c2;
}

.print-window #print-submit-btn:disabled {
  cursor: not-allowed;
  -moz-filter: saturate(50%);
  -ms-filter: saturate(50%);
  filter: saturate(50%);
  background-color: #5cccff;
}

.print-window .error-message {
  color: #e60000;
  background-color: #ffd7d7;
  border: 1px solid #e60000;
  margin-top: 15px;
  padding: 5px;
}

@supports (-ms-ime-align: auto) {
  .print-window #print-right-bar #print-window .window-body #print-scale #print-scale-custom {
    margin-top: 0;
  }
}

.print-window :root #print-right-bar #print-window .window-body #print-scale #print-scale-custom, .print-window _:-ms-fullscreen {
  margin-top: 0;
}

.share-window {
  padding: 15px;
}

.share-window .permalink-input {
  box-sizing: border-box;
  height: 28px;
  border: 1px solid #b8b8b8;
  outline: 0;
  flex: 1;
  padding: 5px 10px;
  font-family: monospace;
  font-size: 13px;
}

.share-window .permalink-input[readonly] {
  color: gray;
}

.share-window .permalink-input:not([readonly]):focus {
  border: 1px solid #00b0ff;
}

.share-window .btn {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 28px;
  text-transform: uppercase;
  background-color: #00b0ff;
  border: 0;
  outline: 0;
  padding: 0 10px;
  font-family: open_sanssemibold, sans-serif;
  font-weight: 400;
}

.share-window .btn.disabled, .share-window .btn:active, .share-window .btn:disabled {
  background-color: #bdeaff;
}

.share-window .btn.btn-copy {
  margin-left: 10px;
}

.share-window .btn.btn-copy.copied {
  background-color: #33cc80;
}

.share-window .error-message {
  color: #e60000;
  background-color: #ffd7d7;
  border: 1px solid #e60000;
  margin-bottom: 10px;
  padding: 5px;
}

.legend-layer-menu-button {
  background: none;
  border: none;
  outline: none;
}

.legend-layer-menu-button svg {
  fill: #b8b8b8;
  height: 15px;
  width: 15px;
  display: block;
}

.legend-layer-menu-button:hover svg {
  fill: #929292;
}

.legend-layer-menu-button:active svg {
  fill: #777;
}

.legend-layer-menu {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  right: -3px;
  transform: translateX(100%);
}

.legend-layer-menu.show {
  display: block;
}

.legend-layer-menu .legend-layer-menu-arrow {
  border-color: #0000 var(--accentColor) #0000 #0000;
  height: 0;
  width: 0;
  z-index: 2;
  border-style: solid;
  border-width: 16px 18px 16px 0;
  position: absolute;
  top: 0;
  left: -5px;
}

.legend-layer-menu-option-button {
  display: none;
}

.legend-layer-menu-option {
  border: 1px solid var(--accentColor);
  max-width: 300px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  display: none;
  position: absolute;
  top: 0;
  right: -13px;
  box-shadow: 0 3px 10px #2229;
}

.legend-layer-menu-option.show {
  display: block;
}

.legend-layer-menu-option .menu-option-head {
  background-color: var(--accentColor);
  color: #fff;
  cursor: default;
  align-items: center;
  padding: 7px 10px;
  font-size: 13px;
  display: flex;
}

.legend-layer-menu-option .menu-option-head span {
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  flex: 1;
  font-weight: 700;
  overflow: hidden;
}

.legend-layer-menu-option .menu-option-head .close-btn {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin-left: 10px;
}

.legend-layer-menu-option .menu-option-head .close-btn:hover svg {
  fill: #000;
}

.legend-layer-menu-option .menu-option-head .close-btn svg {
  fill: #fff;
  height: 13px;
  width: 13px;
  margin-bottom: -1px;
}

.legend-layer-menu-option .menu-option-body {
  cursor: default;
  min-width: 180px;
  padding: 10px;
}

.opacity-slider .slider {
  align-items: center;
  display: flex;
}

.opacity-slider .slider .prefix, .opacity-slider .slider .suffix {
  font-size: 12px;
}

.opacity-slider .slider .prefix {
  margin-right: 10px;
}

.opacity-slider .slider .suffix {
  margin-left: 10px;
}

.opacity-slider .slider .range-track {
  background-color: var(--accentColor);
  cursor: pointer;
}

.opacity-slider .slider .range-track:hover {
  background-color: var(--accentColor-darker);
}

.opacity-slider .slider .range-track:hover .range-handle {
  border: 2px solid var(--accentColor-darker);
}

.opacity-slider .slider .range-handle {
  border: 2px solid var(--accentColor);
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  outline: 0;
}

.legend-group .group-head {
  background-color: var(--accentColor-lighter);
  cursor: pointer;
  border-bottom: 1px solid #fff;
  align-items: center;
  padding: 5px 7px;
  font-weight: 600;
  transition: all .2s ease-in-out;
  display: flex;
}

.legend-group .group-head:hover {
  background-color: var(--accentColor-light);
}

.legend-group .group-head > .default-toggle-btn {
  height: 0;
  width: 0;
  border: 5px solid #0000;
  border-top-width: 0;
  border-bottom-color: #000;
  margin-left: auto;
  margin-right: 3px;
  transition: transform .2s ease-in-out;
}

.legend-group .group-head > .default-toggle-btn.rotate-180 {
  transform: rotate(180deg);
}

.legend-group .group-body {
  max-height: 0;
  padding-left: 15px;
  transition: all .2s ease-out;
  overflow: hidden;
}

.legend-group .group-body.show {
  max-height: 100em;
  transition: max-height .2s ease-in;
}

.legend-group .group-body.open {
  overflow: visible;
}

.legend-layer {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.legend-layer .symbology-class {
  width: 100%;
  align-items: center;
  padding: 3px 3px 3px 29px;
  display: flex;
}

.legend-layer .symbology-class .symbologyIcon {
  flex-shrink: 0;
  padding-right: 5px;
}

.legend-layer .symbology-class .styleArea, .legend-layer .symbology-class .styleIcon, .legend-layer .symbology-class .stylePoint, .legend-layer .symbology-class .styleStroke {
  box-sizing: initial;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.legend-layer .symbology-class .styleIcon {
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .1s ease-in-out;
}

.legend-layer .symbology-class .styleIcon:hover {
  transform: scale(1.2);
}

.legend-layer .symbology-class .stylePoint {
  border-radius: 100%;
}

.legend-layer .symbology-class .stylePoint.rectangle {
  border-radius: unset;
}

.legend-layer .symbology-class .styleStroke {
  width: 15px;
}

.legend-layer .symbology-class .styleArea {
  border-radius: 5px;
}

.legend-layer .symbology-class span {
  padding-right: 10px;
  font-size: 13px;
  line-height: 1.2em;
}

.legend-layer .symbology-class:last-of-type {
  margin-bottom: 4px;
}

.legend-layer {
  position: relative;
}

.legend-layer .layer-head {
  cursor: pointer;
  align-items: center;
  padding: 4px 8px 4px 5px;
  font-weight: 500;
  transition: background-color .1s ease-in-out;
  display: flex;
  position: relative;
}

.legend-layer .layer-head > span {
  font-weight: 600;
  transition: all .1s ease-in-out;
}

.legend-layer .layer-head:hover {
  background-color: #e9e9e9;
}

.legend-layer .layer-head:hover > span {
  color: #555;
}

.legend-layer .layer-body {
  max-height: 0;
  padding-right: 7px;
  transition: all .1s ease-out;
  overflow: hidden;
}

.legend-layer .layer-body.show {
  max-height: 100em;
  transition: max-height .2s ease-in;
}

.legend-layer:hover .layer-body, .legend-layer:hover .layer-head {
  background-color: #e9e9e9;
}

.legend-layer.not-visible .layer-body, .legend-layer.not-visible .layer-head span {
  opacity: .6;
  font-style: italic;
}

.legend-group .group-head > label, .legend-layer .layer-head > label {
  cursor: pointer;
}

.legend-group .group-head > label > .enable-input, .legend-layer .layer-head > label > .enable-input {
  display: none;
}

.legend-group .group-head > label > .enable-input:checked + .default-enable-icon, .legend-layer .layer-head > label > .enable-input:checked + .default-enable-icon {
  fill: #222;
  color: #000;
}

.legend-group .group-head > label > .enable-input:checked + .default-enable-icon:hover, .legend-layer .layer-head > label > .enable-input:checked + .default-enable-icon:hover {
  fill: var(--accentColor-darker);
  color: var(--accentColor-darker);
}

.legend-group .group-head > label > .default-enable-icon, .legend-layer .layer-head > label > .default-enable-icon {
  fill: #2226;
  color: #2226;
  height: 16px;
  width: 16px;
  margin-right: 5px;
  padding: 0;
  transition: color .1s ease-in-out;
  display: block;
}

.legend-group .group-head > label > .default-enable-icon:hover, .legend-layer .layer-head > label > .default-enable-icon:hover {
  fill: #2229;
  color: #2229;
}

.legend-layer .layer-head > label {
  border-right: 1px solid #a1a1a1;
  margin-right: 5px;
}

.legend-layer .layer-head > label > .enable-input:checked + .default-enable-icon {
  fill: var(--accentColor);
  color: var(--accentColor);
}

.legend-layer .layer-head > label > .enable-input:checked + .default-enable-icon:hover {
  fill: var(--accentColor-darker);
  color: var(--accentColor-darker);
}

.legend-layer .layer-head > label > .default-enable-icon {
  fill: #63636366;
  color: #63636366;
  margin-right: 6px;
  line-height: 1.2;
  display: block;
}

.legend-layer .layer-head > label > .default-enable-icon:hover {
  fill: #63636399;
  color: #63636399;
}

.legend-layer .layer-head > span {
  flex: 1;
}

.symbology-legend-image {
  padding: 3px 3px 3px 29px;
}

.legend-bar {
  pointer-events: none;
  width: 250px;
  flex-direction: column;
  justify-content: flex-end;
  margin: 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.legend-position .topleft {
  inset: 5px auto auto 5px;
}

.legend-position .topleft .legend-button-arrow {
  rotate: 180deg;
}

.legend-position .topright {
  inset: 5px 55px auto auto;
}

.legend-position .topright .legend-button-arrow {
  rotate: 180deg;
}

.legend-position .bottomleft {
  inset: auto auto 5px 5px;
}

.legend-position .bottomleft.combined {
  bottom: 55px;
}

.legend-position .bottomright {
  inset: auto 5px 5px auto;
}

.editmode .legend-position .bottomleft {
  bottom: 55px;
}

.editmode .legend-position .bottomleft.combined {
  bottom: 100px;
}

.legend-container {
  max-height: 40px;
  min-height: 40px;
  transition: max-height .2s ease-in-out;
  overflow: hidden;
}

.legend-container .custom-scrollbars {
  height: 100%;
  width: 100%;
}

.legend-container .custom-scrollbars .simplebar-horizontal, .legend-container .custom-scrollbars .simplebar-vertical {
  background-color: initial;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-horizontal {
  height: 4px;
  padding: 0;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  height: inherit;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-vertical {
  width: 4px;
  padding: 0;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  width: inherit;
}

.legend-container .custom-scrollbars .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  left: 0;
  right: 0;
}

.legend-container .map-legend {
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.3;
}

.legend-container .legend-button {
  background: var(--accentColor);
  color: #fff;
  cursor: pointer;
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  font-size: 14px;
}

.legend-container .legend-button, .legend-container .legend-button-icon {
  height: 40px;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
}

.legend-container .legend-button-icon {
  background: var(--accentColor-darker);
  width: 40px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  gap: 3px;
}

.legend-container .legend-button-icon > div {
  height: 2px;
  width: 17px;
  background: #fff;
}

.legend-container .legend-button:hover {
  background: var(--accentColor-dark);
}

.legend-container .legend-button:hover .legend-button-icon {
  background: var(--accentColor-darkest);
}

.legend-container .legend-button-label {
  text-transform: uppercase;
  width: 100%;
  padding: 0 10px;
  font-weight: 700;
}

.legend-container .legend-button-arrow {
  height: 40px;
  width: 40px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.legend-container .legend-button-arrow > div {
  height: 0;
  width: 0;
  border: 7px solid #0000;
  border-top-color: #fff;
  border-bottom-width: 0;
  transition: all .2s ease-in-out;
}

.legend-container .legend-wrapper {
  height: 100%;
  pointer-events: all;
  width: 100%;
  background: #fff;
  flex: none;
  position: relative;
  overflow: hidden;
}

.legend-container.legend-is-hidden .legend-wrapper {
  height: 100%;
}

.legend-container.legend-is-visible {
  max-height: 100%;
}

.legend-container.legend-is-visible .legend-wrapper {
  height: 100%;
  max-height: calc(100% - 40px);
}

.legend-container.legend-is-visible .legend-button-arrow > div {
  transform: rotate(180deg);
}

.Z4uqi {
  position: relative;
}

.Z4uqi .fvncD {
  cursor: pointer;
  height: 50px;
  max-width: 250px;
  min-width: 165px;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  gap: 5px;
  padding: 10px 5px;
  display: inline-flex;
}

.Z4uqi .fvncD .utxmc {
  height: 25px;
  width: 25px;
  background-image: url("temp.d9aa9cc6.jpg");
  background-position: -12px -12px;
  background-size: 50px 50px;
  border: 1px solid #525252;
  overflow: hidden;
}

.Z4uqi .fvncD .utxmc img {
  height: 50px;
  width: 50px;
  margin: -12px 0 0 -12px;
}

.Z4uqi .fvncD .T-ZOJ {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.Z4uqi .fvncD .oYeHJ {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: all .2s ease-in-out;
  display: flex;
}

.Z4uqi .fvncD .oYeHJ div {
  border: 6px solid #0000;
  border-bottom-width: 0;
  border-top-color: var(--accentColor-darkest);
  height: 0;
  width: 0;
  transition: all .2s ease-in-out;
}

.Z4uqi .fvncD.b8HX0 .oYeHJ {
  transform: rotate(180deg);
}

.Z4uqi .rN0Q7 {
  max-height: 0;
  max-width: 250px;
  -webkit-user-select: none;
  user-select: none;
  background: #fffc;
  transition: max-height .5s ease-out;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.Z4uqi .rN0Q7 .nvQX6 {
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
}

.Z4uqi .rN0Q7 .nvQX6:hover {
  background-color: #0000000d;
}

.Z4uqi .rN0Q7 .U7C8p {
  height: 50px;
  width: 50px;
  background-image: url("temp.d9aa9cc6.jpg");
  background-size: 50px 50px;
  margin: 1px 2px;
}

.Z4uqi .rN0Q7 ._53ClT {
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
}

.Z4uqi:hover .fvncD .oYeHJ {
  transform: rotate(180deg);
}

.Z4uqi:hover .rN0Q7 {
  max-height: 1000px;
  transition: max-height .5s ease-in;
}

.edit-mode-action-modal-message {
  padding: 20px;
}

.nestable {
  position: relative;
}

.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: none;
}

.nestable > .nestable-list {
  padding: 0;
}

.nestable-item, .nestable-item-copy {
  margin: 10px 0 0;
}

.nestable-item-copy:first-child, .nestable-item:first-child {
  margin-top: 0;
}

.nestable-item .nestable-list, .nestable-item-copy .nestable-list {
  margin-top: 10px;
}

.nestable-item {
  position: relative;
}

.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}

.nestable-item.is-dragging * {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity= 0);
  opacity: 0;
}

.nestable-item.is-dragging:before {
  content: " ";
  background: #87ceeb;
  border: 1px dashed #4682b4;
  border-radius: 5px;
  position: absolute;
  inset: 0;
}

.nestable-item-icon {
  cursor: pointer;
  margin-right: 5px;
}

.nestable-drag-layer {
  pointer-events: none;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

.nestable-drag-layer > .nestable-list {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.nestable-icon {
  background-color: initial;
  height: 20px;
  width: 20px;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.nestable-icon:before {
  content: "-";
  height: 0;
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.icon-plus-gray {
  height: 20px;
  width: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNTYyIDUuNUgxLjA0OGExIDEgMCAxIDAgMCAyaDQuNTE0djQuNTE0YTEgMSAwIDEgMCAyIDBWNy41aDQuNTE1YTEgMSAwIDEgMCAwLTJINy41NjJWLjk4NmExIDEgMCAxIDAtMiAwVjUuNXoiIGZpbGw9IiM5Nzk3OTciIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
}

.icon-minus-gray {
  height: 20px;
  width: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSIyMCIgeT0iMjkuNSIgd2lkdGg9IjEzIiBoZWlnaHQ9IjIiIHJ4PSIxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjAgLTI5KSIgZmlsbD0iIzk3OTc5NyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
}

.editmode .nestable .screen-container {
  color: #004563;
  border: 1px solid #00456333;
  border-radius: 5px;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  transition: all .2s ease-in-out;
  display: flex;
}

.editmode .nestable .screen-container.active {
  color: #004563;
  background: #00456333;
  border-color: #00a4e2;
}

.editmode .nestable .screen-container.active .dragHandle span {
  background: #004563;
}

.editmode .nestable .screen-container.active .deleteItem {
  color: #004563;
}

.editmode .nestable .screen-container:hover {
  color: #00a4e2;
  border-color: #00a4e2;
}

.editmode .nestable .screen-container .index {
  flex: 0;
  padding: 6px 5px;
}

.editmode .nestable .screen-container .dragHandle {
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 6px 8px;
  display: flex;
}

.editmode .nestable .screen-container .dragHandle span {
  height: 4px;
  width: 4px;
  background: #004563;
  position: relative;
}

.editmode .nestable .screen-container .dragHandle span:after, .editmode .nestable .screen-container .dragHandle span:before {
  background: inherit;
  content: "";
  height: inherit;
  width: inherit;
  position: absolute;
  left: 0;
}

.editmode .nestable .screen-container .dragHandle span:before {
  top: -6px;
}

.editmode .nestable .screen-container .dragHandle span:after {
  top: 6px;
}

.editmode .nestable .screen-container .title {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  overflow: hidden;
}

.editmode .nestable .screen-container .title span {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 6px 5px;
  overflow: hidden;
}

.editmode .nestable .screen-container .deleteItem {
  color: #004563;
  opacity: .5;
  align-items: center;
  padding: 0 10px;
  font-size: 18px;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.editmode .nestable .screen-container .deleteItem:hover {
  opacity: 1;
}

.editmode .nestable .screen-container .deleteItem.disabled {
  display: none;
}

.editmode .nestable-item {
  cursor: pointer;
  border-radius: 5px;
}

.editmode .nestable-item.is-dragging:before {
  opacity: .2;
  background: #004563 !important;
  border: 1px solid #004563 !important;
  border-radius: 5px !important;
}

.editmode .nestable-drag-layer {
  border-radius: 5px;
}

.editmode .nestable-drag-layer .screen-container {
  box-shadow: 0 2px 10px -5px #00000080;
}

.editmode .nestable-drag-layer .index {
  display: none;
}

.editmode .nestable-drag-layer .deleteItem {
  display: none !important;
}

.edit-mode-loader-screen {
  height: 100vh;
  -webkit-user-select: none;
  user-select: none;
  width: 100vw;
  z-index: 10000;
  background: #fff;
}

.edit-mode-loader-block, .edit-mode-loader-screen {
  opacity: 0;
  justify-content: center;
  align-items: center;
  animation: .2s 10ms forwards show;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.edit-mode-loader-block {
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: #fff;
}

.edit-mode-loader-inner {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.edit-mode-loader-title {
  margin: 0 0 15px;
  font-size: 1rem;
  font-weight: 600;
}

.edit-mode-loader-spinner {
  background-color: initial;
  height: 60px;
  width: 60px;
  border: 4px solid #00a4e233;
  border-bottom-color: #00a4e2;
  border-radius: 50%;
  animation: 2s linear infinite spin;
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

.editmode {
  color: #000;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
}

.editmode, .editmode input, .editmode textarea {
  line-height: 1.5;
}

.editmode small {
  font-size: 80%;
}

.editmode .simplebar-scrollbar {
  background: none;
  padding: 0;
}

.editmode .simplebar-horizontal .simplebar-scrollbar:before, .editmode .simplebar-scrollbar:before, .editmode .simplebar-vertical .simplebar-scrollbar:before {
  background-color: #000;
}

.editmode .simplebar-scrollbar.simplebar-visible:before {
  opacity: .2;
  transition: opacity .2s linear;
}

.editmode .simplebar-scrollbar.simplebar-visible.simplebar-hover:before {
  opacity: .4;
}

.editmode .simplebar-track.simplebar-horizontal {
  height: 11px;
  padding: 0;
  left: 0;
}

.editmode .simplebar-horizontal .simplebar-scrollbar, .editmode .simplebar-vertical .simplebar-scrollbar {
  width: 11px;
}

.editmode .simplebar-track.simplebar-vertical {
  width: 11px;
  padding: 0;
  top: 0;
}

@font-face {
  font-family: open_sans_editor;
  font-style: normal;
  font-weight: 400;
  src: url("opensans-regular-webfont.7aee7f67.woff2") format("woff2"), url("opensans-regular-webfont.9c8f86e3.woff") format("woff");
}

@font-face {
  font-family: open_sans_editor;
  font-style: normal;
  font-weight: 600;
  src: url("opensans-semibold-webfont.c57a7df7.woff2") format("woff2"), url("opensans-semibold-webfont.bed514b4.woff") format("woff");
}

@font-face {
  font-family: open_sans_editor;
  font-style: normal;
  font-weight: 700;
  src: url("opensans-bold-webfont.cd911c14.woff2") format("woff2"), url("opensans-bold-webfont.020eebe5.woff") format("woff");
}

@font-face {
  font-family: open_sans_editor;
  font-style: italic;
  font-weight: 400;
  src: url("opensans-italic-webfont.91857ce4.woff2") format("woff2"), url("opensans-italic-webfont.cc887125.woff") format("woff");
}

@font-face {
  font-family: open_sans_editor;
  font-style: italic;
  font-weight: 700;
  src: url("opensans-bolditalic-webfont.6fe0d0f6.woff2") format("woff2"), url("opensans-bolditalic-webfont.c3af6589.woff") format("woff");
}

@font-face {
  font-family: open_sans_editor;
  font-style: italic;
  font-weight: 600;
  src: url("opensans-semibolditalic-webfont.c277deb8.woff2") format("woff2"), url("opensans-semibolditalic-webfont.dc0801dd.woff") format("woff");
}

.editmode-button {
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  background: #00a4e2;
  border: 1px solid #00a4e2;
  border-radius: 6px;
  align-items: center;
  margin: 0;
  padding: 10px 14px;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
  text-decoration: none;
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.editmode-button:focus {
  outline: none;
}

.editmode-button.focus-visible, .editmode-button:focus-visible {
  outline: 2px solid #000;
}

.editmode-button.disabled, .editmode-button:disabled {
  cursor: default;
  outline: none;
}

.editmode-button.disabled:focus, .editmode-button:disabled:focus {
  outline: none;
}

.editmode-button.editmode-button-icon {
  flex: none;
  align-items: center;
  gap: 10px;
  display: flex;
}

.editmode-button-info, .editmode-button-primary {
  color: #fff;
  background: #00a4e2;
  border: 1px solid #00a4e2;
}

.editmode-button-info:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover, .editmode-button-primary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #0086b9;
  border-color: #0086b9;
}

.editmode-button-info:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active, .editmode-button-primary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #007faf;
  border-color: #007faf;
}

.editmode-button-info.disabled, .editmode-button-info:disabled, .editmode-button-primary.disabled, .editmode-button-primary:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-info.button-small, .editmode-button-primary.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editmode-button-secundary {
  color: #fff;
  background: #004563;
  border: 1px solid #004563;
}

.editmode-button-secundary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #00293a;
  border-color: #00293a;
}

.editmode-button-secundary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #002130;
  border-color: #002130;
}

.editmode-button-secundary.disabled, .editmode-button-secundary:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-secundary.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editmode-button-tertiary {
  color: #fff;
  background: #3faa3a;
  border: 1px solid #3faa3a;
}

.editmode-button-tertiary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #348c30;
  border-color: #348c30;
}

.editmode-button-tertiary:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #31842d;
  border-color: #31842d;
}

.editmode-button-tertiary.disabled, .editmode-button-tertiary:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-tertiary.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editmode-button-danger {
  color: #fff;
  background: #f93234;
  border: 1px solid #f93234;
}

.editmode-button-danger:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #f80a0d;
  border-color: #f80a0d;
}

.editmode-button-danger:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #f10709;
  border-color: #f10709;
}

.editmode-button-danger.disabled, .editmode-button-danger:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-danger.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editmode-button-warning {
  color: #fff;
  background: #ffbf00;
  border: 1px solid #ffbf00;
}

.editmode-button-warning:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #d6a000;
  border-color: #d6a000;
}

.editmode-button-warning:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #c90;
  border-color: #c90;
}

.editmode-button-warning.disabled, .editmode-button-warning:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-warning.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editmode-button-cancel {
  color: #fff;
  background: #7793af;
  border: 1px solid #7793af;
}

.editmode-button-cancel:not(a):not(.pm-disabled):not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #5e7f9f;
  border-color: #5e7f9f;
}

.editmode-button-cancel:not(a):not(.pm-disabled):not(.disabled):not(:disabled):active {
  background: #5a7999;
  border-color: #5a7999;
}

.editmode-button-cancel.disabled, .editmode-button-cancel:disabled {
  color: #ffffff80;
  opacity: .5;
}

.editmode-button-cancel.button-small {
  padding: 5px 9px;
  font-size: 14px;
}

.editbutton {
  box-sizing: initial;
  color: #fff;
  height: 41px;
  width: 41px;
  background: #004563;
  border: 3px solid #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
}

.editbutton:hover {
  background: #00a4e2;
}

.editmode-header {
  color: #fff;
  height: 60px;
  -webkit-user-select: none;
  user-select: none;
  background: #004563;
  flex: none;
  align-items: center;
  gap: 30px;
  padding: 0 30px;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
}

.editmode-header .editmode-buttons {
  gap: 15px;
  margin-left: auto;
  display: flex;
}

.editmode-header .editmode-button {
  text-transform: uppercase;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
}

.editmode-header .user {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.editmode-header .user .profileName {
  color: #004563;
  text-align: right;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .editmode-header .user .profileName {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .editmode-header .user .profileName {
    font-size: 14px;
  }
}

.editmode-header .user .profileImg {
  cursor: pointer;
  height: 42px;
  width: 42px;
  border: 2px solid #fff;
  border-radius: 100%;
  flex: none;
  overflow: hidden;
  box-shadow: 0 0 6px #00000029;
}

@media (max-width: 769px) {
  .editmode-header .user .profileImg {
    height: 42px;
    width: 42px;
  }
}

.editmode-header .user .profileImg img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.editmode-header .user .currentUserWindow {
  color: #000;
  opacity: 0;
  z-index: 9999;
  background: #fff;
  border: 1px solid #00000029;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  animation: .2s 10ms forwards show;
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 10px 20px -5px #0003;
}

.editmode-header .user .currentUserWindow:after, .editmode-header .user .currentUserWindow:before {
  content: " ";
  height: 0;
  pointer-events: none;
  width: 0;
  display: block;
  position: absolute;
  right: 10px;
}

.editmode-header .user .currentUserWindow:after {
  border: 7px solid #0000;
  border-bottom-color: #f9f9f9;
  top: -14px;
  right: 11px;
}

.editmode-header .user .currentUserWindow:before {
  border: 8px solid #0000;
  border-bottom-color: #cdcdcd;
  top: -16px;
}

.editmode-header .user .currentUserWindow .top {
  width: 100%;
  background: #f9f9f9;
  border-bottom: 1px solid #00000029;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px;
  display: flex;
}

.editmode-header .user .currentUserWindow .user-info {
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.editmode-header .user .currentUserWindow .user-name {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
}

.editmode-header .user .currentUserWindow .user-email {
  white-space: nowrap;
  font-size: 11px;
}

.editmode-header .user .currentUserWindow .user-details {
  text-align: left;
  padding-right: 20px;
}

.editmode-header .user .currentUserWindow .user-image {
  height: 60px;
  object-fit: cover;
  width: 60px;
  border: 2px solid #fff;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 6px #00000029;
}

.editmode-header .user .currentUserWindow .user-links {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  padding: 7px 0 0;
  display: flex;
}

.editmode-header .user .currentUserWindow .user-links button {
  color: #004563;
  cursor: pointer;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 11px;
}

.editmode-header .user .currentUserWindow .user-links button:hover {
  color: #000;
  text-decoration: underline;
}

.editmode-header .user .currentUserWindow .bottom {
  width: 100%;
}

.editmode-header .user .currentUserWindow .bottom button {
  color: #004563;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  background: #fff;
  border: none;
  padding: 10px 20px;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
}

.editmode-header .user .currentUserWindow .bottom button:first-child {
  margin-top: 8px;
}

.editmode-header .user .currentUserWindow .bottom button:hover {
  background: #c1d3e3;
}

.editmode-header .user .currentUserWindow .bottom button.logout {
  background: #f9f9f9;
  border-top: 1px solid #00000029;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 10px 20px;
}

.editmode-header .user .currentUserWindow .bottom button.logout:hover {
  background: #c1d3e3;
}

.editmode-header .user .currentUserWindow .bottom button.logout:only-child {
  border-top: none;
  margin-top: 0;
}

.editmode-menu {
  align-items: center;
  gap: 20px;
  margin-left: 50px;
  display: flex;
}

.editmode-menu .menu-item {
  cursor: pointer;
  text-transform: uppercase;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.editmode-menu .menu-item .icon {
  color: #00a4e2;
  height: 20px;
  width: 20px;
}

.editmode-logo {
  gap: 5px;
  display: flex;
}

.editmode-logo img {
  width: 36px;
}

.editmode-logo span {
  font-size: 20px;
  font-weight: 600;
  transform: translateY(2px);
}

#viewer-modals .edit-modal, .editmode .edit-modal {
  height: 100%;
  width: 100%;
  z-index: 1001;
  justify-content: center;
  align-items: center;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#viewer-modals .edit-modal.hidden, .editmode .edit-modal.hidden {
  display: none;
}

#viewer-modals .edit-modal-backdrop, .editmode .edit-modal-backdrop {
  height: 100%;
  width: 100%;
  background: #a1a1a133;
  position: absolute;
  top: 0;
  right: 0;
}

#viewer-modals .edit-modal.danger-modal .edit-modal-header, .editmode .edit-modal.danger-modal .edit-modal-header {
  background-color: #f93234;
}

#viewer-modals .edit-modal.warning-modal .edit-modal-header, .editmode .edit-modal.warning-modal .edit-modal-header {
  background-color: #ffbf00;
}

#viewer-modals .edit-modal-window, .editmode .edit-modal-window {
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  min-height: 200px;
  width: 65vw;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  margin: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 10px 10px #00000026;
}

#viewer-modals .edit-modal.small .edit-modal-window, .editmode .edit-modal.small .edit-modal-window {
  max-width: 400px;
  width: 100%;
}

#viewer-modals .edit-modal.medium .edit-modal-window, .editmode .edit-modal.medium .edit-modal-window {
  max-width: 800px;
  width: 100%;
}

#viewer-modals .edit-modal-header, .editmode .edit-modal-header {
  color: #fff;
  height: 35px;
  -webkit-user-select: none;
  user-select: none;
  background: #00a4e2;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

#viewer-modals .edit-modal-title, .editmode .edit-modal-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

#viewer-modals .edit-modal-close, .editmode .edit-modal-close {
  color: #fff;
  cursor: pointer;
  height: 35px;
  width: 35px;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
  transform: translateX(20px);
}

#viewer-modals .edit-modal-close:hover, .editmode .edit-modal-close:hover {
  color: #21212199;
}

#viewer-modals .edit-modal-body, .editmode .edit-modal-body {
  border-radius: 0 0 8px 8px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

#viewer-modals .edit-modal-body .custom-scrollbars, .editmode .edit-modal-body .custom-scrollbars {
  height: 100%;
  max-height: calc(80vh - 94px);
}

#viewer-modals .edit-modal-body .simplebar-content, .editmode .edit-modal-body .simplebar-content {
  box-sizing: border-box;
  height: 100%;
  padding: 20px !important;
}

#viewer-modals .edit-modal-body .edit-mode-loader-screen, .editmode .edit-modal-body .edit-mode-loader-screen {
  height: 100%;
  width: 100%;
}

#viewer-modals .edit-modal-footer, .editmode .edit-modal-footer {
  margin-top: auto;
  padding: 20px 0 0;
}

#viewer-modals .edit-modal-footer .footer-buttons, .editmode .edit-modal-footer .footer-buttons {
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  display: flex;
}

#viewer-modals .edit-modal .modal-buttons, .editmode .edit-modal .modal-buttons {
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.editmode .edit-mode-yaml-form .smart-form, .editmode .editmode-form .smart-form {
  max-width: none;
  padding: 0;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.editmode .edit-mode-yaml-form .smart-form .field:last-of-type, .editmode .editmode-form .smart-form .field:last-of-type {
  margin-bottom: 0;
}

.editmode .edit-mode-yaml-form .smart-form .field > div, .editmode .editmode-form .smart-form .field > div {
  border: none;
  align-items: center;
  padding: 0;
}

.editmode .edit-mode-yaml-form .smart-form .p-dropdown, .editmode .edit-mode-yaml-form .smart-form .p-inputnumber:enabled, .editmode .edit-mode-yaml-form .smart-form .p-inputtext:enabled, .editmode .edit-mode-yaml-form .smart-form input, .editmode .edit-mode-yaml-form .smart-form select, .editmode .edit-mode-yaml-form .smart-form textarea, .editmode .editmode-form .smart-form .p-dropdown, .editmode .editmode-form .smart-form .p-inputnumber:enabled, .editmode .editmode-form .smart-form .p-inputtext:enabled, .editmode .editmode-form .smart-form input, .editmode .editmode-form .smart-form select, .editmode .editmode-form .smart-form textarea {
  border-width: 1px;
  border-radius: 0;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
}

.editmode .edit-mode-yaml-form .smart-form .control-label, .editmode .edit-mode-yaml-form .smart-form .legend, .editmode .edit-mode-yaml-form .smart-form .smartform-field label, .editmode .edit-mode-yaml-form .smart-form legend, .editmode .editmode-form .smart-form .control-label, .editmode .editmode-form .smart-form .legend, .editmode .editmode-form .smart-form .smartform-field label, .editmode .editmode-form .smart-form legend {
  text-transform: none;
  width: auto;
  margin-bottom: .5rem;
  margin-right: 20px;
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 600;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field, .editmode .editmode-form .smart-form .radio-field {
  align-items: center;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field span, .editmode .editmode-form .smart-form .radio-field span {
  height: 20px;
  width: 20px;
  border-width: 1px;
  position: static;
  top: 10px;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field label, .editmode .editmode-form .smart-form .radio-field label {
  margin-left: -20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:checked + span, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:checked + span {
  border-color: #00a4e2;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:checked + span:before, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:checked + span:before {
  height: 10px;
  opacity: 1;
  width: 10px;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:checked + span + label, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:checked + span + label {
  background-color: initial;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:focus + span, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:focus + span {
  border-color: #00a4e2;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:focus + span:before, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:focus + span:before {
  background-color: #00a4e2;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"]:focus + span + label, .editmode .editmode-form .smart-form .radio-field input[type="radio"]:focus + span + label {
  background-color: initial;
}

.editmode .edit-mode-yaml-form .smart-form .radio-field input[type="radio"][aria-invalid="true"] + span, .editmode .editmode-form .smart-form .radio-field input[type="radio"][aria-invalid="true"] + span {
  border-color: #f93234;
}

.editmode .edit-mode-yaml-form .smart-form .checkbox-field label, .editmode .editmode-form .smart-form .checkbox-field label {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
}

.editmode .edit-mode-yaml-form .smart-form .p-checkbox-label .p-checkbox, .editmode .editmode-form .smart-form .p-checkbox-label .p-checkbox {
  margin-right: 10px;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-main, .editmode .editmode-form .smart-form .rdw-editor-main {
  min-height: 200px;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar, .editmode .editmode-form .smart-form .rdw-editor-toolbar {
  border: 1px solid #ced4da;
  border-bottom: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper, .editmode .editmode-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper {
  height: 23px;
  border-color: #ced4da;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar .rdw-option-active, .editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper:active, .editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper:hover, .editmode .editmode-form .smart-form .rdw-editor-toolbar .rdw-option-active, .editmode .editmode-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper:active, .editmode .editmode-form .smart-form .rdw-editor-toolbar .rdw-option-wrapper:hover {
  box-shadow: none;
  border-color: #2196f3;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-toolbar .rdw-colorpicker-modal .rdw-colorpicker-modal-options, .editmode .editmode-form .smart-form .rdw-editor-toolbar .rdw-colorpicker-modal .rdw-colorpicker-modal-options {
  overflow-x: hidden;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-main, .editmode .editmode-form .smart-form .rdw-editor-main {
  border: 1px solid #ced4da;
  padding-left: 10px;
  padding-right: 10px;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-main:focus-within, .editmode .edit-mode-yaml-form .smart-form .rdw-editor-main:hover, .editmode .editmode-form .smart-form .rdw-editor-main:focus-within, .editmode .editmode-form .smart-form .rdw-editor-main:hover {
  border-color: #2196f3;
}

.editmode .edit-mode-yaml-form .smart-form .rdw-editor-main:focus-within, .editmode .editmode-form .smart-form .rdw-editor-main:focus-within {
  box-shadow: 0 0 0 .2rem #a6d5fa;
}

.editmode .edit-mode-yaml-form .smart-form .field.invalid, .editmode .editmode-form .smart-form .field.invalid {
  border: none;
}

.editmode .edit-mode-yaml-form .smart-form .field.invalid .p-component, .editmode .editmode-form .smart-form .field.invalid .p-component {
  border-color: #f93234;
}

.editmode .colorfulpicker .preview {
  cursor: pointer;
  height: 39px;
  width: 34px;
}

.editmode .edit-mode-block-config-editor {
  height: calc(80vh - 35px);
  flex-direction: column;
  display: flex;
}

.editmode .edit-mode-block-config-editor .edit-mode-yaml-editor {
  min-height: 50vh;
}

.editmode .edit-mode-block-config-editor .edit-mode-yaml-editor textarea {
  flex-grow: 1;
}

.editmode .edit-mode-block-config-editor .edit-mode-yaml-editor, .editmode .edit-mode-block-config-editor .edit-mode-yaml-form {
  height: 100%;
}

.editmode .edit-mode-block-config-editor .editmode-switch {
  height: 36px;
  z-index: 1;
  justify-content: flex-end;
  margin-bottom: -20px;
  padding: 10px 20px 0;
  display: flex;
  position: relative;
}

.editmode .p-accordion {
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.editmode .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 14px;
  border-radius: 0 !important;
}

.editmode .p-accordion .p-accordion-tab .p-accordion-header.p-highlight .p-accordion-header-link {
  color: #495057;
  background: #e9ecef;
  border-color: #dee2e6;
}

.editmode .p-accordion .p-toggleable-content-enter-active, .editmode .p-accordion .p-toggleable-content-exit-active {
  transition: none !important;
}

.editmode .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.editmode .editmode-list {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.editmode .editmode-list .list-item {
  cursor: pointer;
  border: 1px solid #7793af;
  border-radius: 4px;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transition: all .2s ease-in-out;
  display: flex;
}

.editmode .editmode-list .list-item:hover {
  border-color: #00a4e2;
}

.editmode .editmode-list .list-item:hover .item-title {
  color: #00a4e2;
}

.editmode .editmode-list .list-item.edit-mode-selected-block, .editmode .editmode-list .list-item.edit-mode-selected-template {
  border-color: #00a4e2;
  box-shadow: inset 0 0 0 2px #00a4e2;
}

.editmode .editmode-list .list-item.edit-mode-selected-block .item-title, .editmode .editmode-list .list-item.edit-mode-selected-template .item-title {
  color: #00a4e2;
}

.editmode .editmode-list .list-item.edit-mode-selected-block .item-image image, .editmode .editmode-list .list-item.edit-mode-selected-template .item-image image {
  filter: brightness(.5);
}

.editmode .editmode-list .item-image {
  transition: all .2s ease-in-out;
}

.editmode .editmode-list .item-title {
  color: #004563;
  font-size: 16px;
  font-weight: 600;
  transition: all .2s ease-in-out;
}

.edit-mode-resource-manager .buttons {
  align-items: center;
  display: flex;
}

.edit-mode-resource-manager .buttons .group {
  margin-right: 15px;
}

.edit-mode-resource-manager .buttons .group:last-child {
  margin-right: 0;
}

.edit-mode-resource-manager .buttons button {
  margin-right: 5px;
  padding: 0 3px;
}

.edit-mode-resource-manager .buttons button:last-child {
  margin-right: 0;
}

.edit-mode-resource-manager .resource-filter label {
  margin-left: 5px;
}

.edit-mode-resource-manager .resource-filter input, .edit-mode-resource-manager .resource-filter select {
  font-size: 12px;
}

.edit-mode-resource-manager .resource-list table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
}

.edit-mode-resource-manager .resource-list table td, .edit-mode-resource-manager .resource-list table th {
  border: 1px solid #ddd;
  padding: 3px;
}

.edit-mode-resource-manager .resource-list table td {
  text-align: left;
  vertical-align: top;
}

.edit-mode-resource-manager .resource-form .field {
  margin-bottom: 3px;
  display: flex;
}

.edit-mode-resource-manager .resource-form .field label {
  min-width: 100px;
  display: inline-block;
}

.editmode .edit-mode-block-menu {
  height: 67px;
  -webkit-user-select: none;
  user-select: none;
  width: 67px;
  z-index: 3;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.editmode .edit-mode-block-menu .editbutton {
  box-sizing: initial;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.editmode .edit-mode-block-menu:hover {
  width: 160px;
}

.editmode .edit-mode-block-menu:hover .edit-buttons-wrap {
  right: 0;
}

.editmode .edit-mode-block-menu:hover .edit-buttons-wrap .edit-buttons {
  transform: none;
  box-shadow: 0 3px 6px #00000029;
}

.editmode .edit-mode-block-menu .edit-buttons-wrap {
  height: 67px;
  pointer-events: none;
  z-index: 0;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  overflow-x: hidden;
  overflow-y: visible;
}

.editmode .edit-mode-block-menu .edit-buttons {
  height: 40px;
  pointer-events: auto;
  background: #888;
  border: 2px solid #fff;
  border-radius: 0 7px 7px 0;
  gap: 11px;
  padding: 4px 4px 4px 25px;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 13px;
  left: 20px;
  transform: translateX(-100%);
  box-shadow: 0 3px 6px #0000;
}

.editmode .edit-mode-block-menu .edit-buttons button {
  color: #fff;
  cursor: pointer;
  height: 28px;
  width: 28px;
  background: none;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
}

.editmode .edit-mode-block-menu .edit-buttons button:hover {
  color: #004563;
  background: #fff;
}

.editmode .edit-mode-block-menu .edit-buttons button span {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.editmode .edit-mode-block-menu .edit-buttons button + button {
  position: relative;
}

.editmode .edit-mode-block-menu .edit-buttons button + button:before {
  content: "";
  height: 18px;
  width: 1px;
  background: #fff;
  position: absolute;
  top: 5px;
  left: -6px;
}

.editmode .edit-mode-block-menu .edit-buttons button.button-delete:hover {
  color: #f93234;
}

.editmode .edit-mode-add-block {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.editmode .edit-mode-add-block .button-add-wrap {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.editmode .edit-mode-add-block .button-add {
  box-sizing: initial;
  color: #fff;
  cursor: pointer;
  height: 41px;
  width: 41px;
  z-index: 3;
  background: #004563;
  border: 3px solid #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 0 3px 6px #00000029;
}

.editmode .edit-mode-add-block .button-add:hover, .editmode .edit-mode-add-block:hover .button-add {
  background: #00a4e2;
}

.editmode .edit-mode-add-block .edit-modal-window {
  max-height: 700px;
}

.stacked-block .text-block {
  margin-bottom: 0;
}

.stacked-block.vertical {
  height: 100%;
  position: relative;
}

.stacked-block.vertical .stacked-block-child {
  position: relative;
}

.stacked-block.vertical .image-block, .stacked-block.vertical .map-block {
  min-height: 300px;
  position: relative;
}

.stacked-block.horizontal {
  height: 100%;
  position: relative;
}

.stacked-block.horizontal .stacked-block-inner.horizontal {
  width: 100%;
  justify-content: stretch;
  display: flex;
  position: relative;
}

.stacked-block.horizontal .stacked-block-inner.horizontal .stacked-block-child {
  flex-grow: 1;
  position: relative;
}

.stacked-block.horizontal .stacked-block-inner.horizontal .stacked-block-child-inner {
  height: 100%;
  position: relative;
}

.stacked-block .edit-mode-add-block {
  display: none;
}

.editmode .stacked-block .edit-mode-add-block {
  height: auto;
  width: auto;
  display: block;
}

.editmode .stacked-block .edit-mode-add-block.single {
  height: 100%;
  width: 100%;
  background: none;
}

.editmode .stacked-block .edit-mode-add-block .button-add {
  height: 31px;
  width: 31px;
  flex: none;
}

.editmode .stacked-block .edit-mode-add-block .button-add .icon {
  height: 10px !important;
  width: 10px !important;
}

.editmode .stacked-block .edit-mode-add-block.newblock {
  pointer-events: none;
  visibility: hidden;
  background: none;
}

.editmode .stacked-block .stacked-block-child .edit-mode-block-menu {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.editmode .stacked-block .stacked-block-child:hover > .stacked-block-child-inner > [data-ui-element="block"] {
  opacity: 1;
}

.editmode .stacked-block .stacked-block-child:hover > .stacked-block-child-inner > [data-ui-element="block"] > .edit-mode-block-menu {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.editmode .stacked-block .stacked-block-child:hover > .stacked-block-child-inner > [data-ui-element="block"]:before {
  opacity: 1;
  border-color: #00a4e2;
}

.editmode .stacked-block .stacked-block-inner {
  min-height: 100%;
  padding: 15px;
}

.editmode .stacked-block.vertical .simplebar-content {
  height: 100%;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical {
  position: relative;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .edit-mode-add-block {
  height: 15px;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .edit-mode-add-block.add-block-after, .editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .edit-mode-add-block.add-block-before {
  transform: translateY(-50%);
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .edit-mode-add-block {
  display: none;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical:hover {
  z-index: 1;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical:hover > .edit-mode-add-block {
  display: block;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .stacked-block-child-inner {
  z-index: 0;
  position: relative;
  padding: 0 !important;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .stacked-block-child-inner > [data-ui-element="block"] {
  padding: 15px 30px;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .stacked-block-child-inner > [data-ui-element="block"] > .text-block .inner {
  padding: 0 21px;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical > .stacked-block-child.vertical > .stacked-block-child-inner > [data-ui-element="block"] > .block-form-field {
  margin: 0;
}

.editmode .stacked-block.vertical .stacked-block-inner.vertical .stacked-block-inner.vertical {
  flex-direction: column;
  padding: 25px 15px;
  display: flex;
}

.editmode .stacked-block.vertical .stacked-block.horizontal, .editmode .stacked-block.vertical .stacked-block.vertical {
  margin: -15px -30px 30px;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal {
  z-index: 0;
  position: relative;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal:hover {
  z-index: 1;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal > .edit-mode-add-block {
  height: 100%;
  width: 15px;
  z-index: 1;
  position: absolute;
  top: 0;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal > .edit-mode-add-block.add-block-before {
  transform: translateX(-50%);
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal > .edit-mode-add-block.add-block-after {
  right: 0;
  transform: translateX(50%);
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal > .edit-mode-add-block {
  display: none;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal:hover > .edit-mode-add-block {
  display: block;
}

.editmode .stacked-block.horizontal .stacked-block-child.horizontal > .stacked-block-child-inner {
  height: 100%;
  z-index: 0;
  position: relative;
  padding: 0 !important;
}

.editmode .stacked-block.horizontal .stacked-block-inner.horizontal {
  padding: 15px 25px;
}

.editmode .stacked-block.horizontal .stacked-block-inner.horizontal > .stacked-block-child > .stacked-block-child-inner {
  height: 100%;
  z-index: 0;
  padding: 0;
  position: relative;
}

.editmode .stacked-block.horizontal .stacked-block-inner.horizontal > .stacked-block-child > .stacked-block-child-inner > [data-ui-element="block"] {
  padding: 15px 30px;
}

.editmode .stacked-block.horizontal .stacked-block-inner.horizontal > .stacked-block-child > .stacked-block-child-inner > [data-ui-element="block"] > .text-block .inner {
  padding: 20px 0;
}

.editmode .stacked-block.horizontal .stacked-block-inner.horizontal > .stacked-block-child > .stacked-block-child-inner > [data-ui-element="block"] > .block-form-field {
  margin: 0;
}

.editmode .stacked-block.horizontal .stacked-block.horizontal, .editmode .stacked-block.horizontal .stacked-block.vertical {
  height: auto;
  margin: -15px -30px 30px;
}

[data-ui-element="block"] {
  height: 100%;
  width: 100%;
  position: relative;
}

.editmode [data-ui-element="screens"] {
  pointer-events: none;
}

.editmode [data-ui-element="screens"]:hover [data-ui-element="block"]:not(.no-hover):not(:hover) {
  opacity: .5;
}

.editmode [data-ui-element="screens"]:hover [data-ui-element="block"]:not(.no-hover):hover {
  opacity: 1;
}

.editmode [data-ui-element="screens"]:hover [data-ui-element="block"]:not(.no-hover):hover:before {
  opacity: 1;
  border-color: #00a4e2;
}

.editmode [data-ui-element="screens"] [data-ui-element="block"] {
  height: 100%;
  pointer-events: auto;
  width: 100%;
  transition: opacity .2s ease-in-out;
  position: relative;
}

.editmode [data-ui-element="screens"] [data-ui-element="block"]:before {
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  background: #00a4e21a;
  border: 2px dashed #0000;
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 0;
}

.edit-mode-yaml-editor {
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.edit-mode-yaml-editor textarea {
  height: 100%;
  resize: none;
  border: 1px solid #ced4da;
  margin: 0;
  padding: 20px;
  font-family: Oxygen, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  transition: all .2s ease-in-out;
}

.edit-mode-yaml-editor textarea:focus, .edit-mode-yaml-editor textarea:hover {
  border-color: #2196f3;
}

.edit-mode-yaml-editor .yaml-error {
  color: red;
  padding: 5px 20px;
  font-family: Oxygen, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
}

.file-upload-container {
  font-size: 14px;
}

.file-upload-container.required-warning .file-upload-btn {
  background-color: red !important;
  border-color: red !important;
}

.file-upload-container.required-warning .file-upload-btn:hover {
  background-color: #db0000 !important;
}

.file-upload-container.required-warning .file-upload-btn.active, .file-upload-container.required-warning .file-upload-btn:active {
  background-color: #c20000 !important;
}

.file-upload-container .file-upload-head {
  display: block;
}

.file-upload-container .file-upload-head .file-upload-label {
  cursor: pointer;
}

.file-upload-container .file-upload-head .file-upload-label input {
  display: none;
}

.file-upload-container .file-upload-body {
  padding: 10px 0;
}

.file-upload-container .file-upload-body .file-upload-entry {
  box-sizing: border-box;
  align-items: center;
  gap: 20px;
  display: flex;
}

.file-upload-container .file-upload-body .file-upload-entry .file-upload-preview {
  height: 100px;
  object-fit: contain;
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.file-upload-container .file-upload-body .file-upload-entry .file-upload-size {
  flex: 1;
}

.file-upload-container .file-upload-body .file-upload-entry .file-upload-remove {
  height: 32px;
  width: 32px;
}

.file-upload-container .file-upload-body .file-upload-entry .file-upload-name {
  font-weight: 600;
}

.editmode-permissions-container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.editmode-permissions-container .editmode-principals {
  flex: 1;
  padding: 10px 0 0;
}

.editmode-permissions-container .editmode-principals .custom-scrollbars .simplebar-content {
  position: relative;
  padding-top: 0 !important;
}

.editmode-permissions-container .editmode-principals .custom-scrollbars .simplebar-track.simplebar-vertical {
  top: 30px;
}

.editmode-permissions-container .editmode-principals-title {
  padding: 10px 20px;
  font-weight: 600;
}

.editmode-permissions-container .editmode-principals .principals-list {
  width: 100%;
  display: table;
  position: relative;
}

.editmode-permissions-container .editmode-principals .principals-list-header {
  height: 29px;
  z-index: 1;
  background: #fff;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  display: table-row;
  position: sticky;
  top: 0;
  left: 20px;
  right: 20px;
}

.editmode-permissions-container .editmode-principals .principals-list-header div {
  border-bottom: 1px solid #ced4da;
  display: table-cell;
}

.editmode-permissions-container .editmode-principals .principals-list-header div.name {
  width: 80%;
}

.editmode-permissions-container .editmode-principals .principals-list-header div.deleteItem {
  text-align: right;
}

.editmode-permissions-container .editmode-principals .principals-list-body {
  display: table-row-group;
}

.editmode-permissions-container .principal {
  box-sizing: border-box;
  color: #004563;
  border-radius: 5px;
  font-size: 14px;
  transition: all .2s ease-in-out;
  display: table-row;
}

.editmode-permissions-container .principal:first-child > div {
  border-top: none;
}

.editmode-permissions-container .principal > div {
  vertical-align: middle;
  border-top: 1px solid #00456333;
  padding: 6px 0;
  display: table-cell;
}

.editmode-permissions-container .principal > div.name {
  width: 80%;
}

.editmode-permissions-container .principal > div.name .principal-title {
  font-size: 16px;
  font-weight: 600;
}

.editmode-permissions-container .principal > div.name .principal-email {
  font-size: 13px;
}

.editmode-permissions-container .principal > div.deleteItem {
  text-align: right;
}

.editmode-permissions-container .principal > div.deleteItem .editmode-button {
  padding: 6px;
  display: inline-flex;
}

.editmode-permissions-container .editmode-add-principal {
  border-bottom: 1px solid #ced4da;
  padding: 20px;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form {
  display: flex;
  position: relative;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .smartform-field-text {
  gap: 15px;
  margin: 0;
  display: flex;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .smartform-field-text label {
  white-space: nowrap;
  margin: 0;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .smartform-field-text input {
  width: 280px;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .permissions-input-wrap {
  position: relative;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-top: 0;
  font-size: 14px;
  position: absolute;
  top: 39px;
  left: 0;
  right: 0;
  box-shadow: 0 3px 5px #00000026;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul li {
  cursor: pointer;
  margin-bottom: 2px;
  padding: 4px 10px;
  transition: all .2s ease-in-out;
  display: flex;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul li:hover {
  background-color: #ccc;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul li:last-child {
  margin-bottom: 0;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul li .title {
  flex: 1;
  margin-right: 10px;
}

.editmode-permissions-container .editmode-add-principal .editmode-add-principal-form .suggestions ul li .type {
  color: #999;
  text-transform: lowercase;
}

.editmode-permissions-container .no-principals {
  padding: 0 20px;
  font-size: 14px;
}

.editmode .tab-bar {
  height: 45px;
  background: #e9ecef;
  border-bottom: 1px solid #ced4da;
  gap: 6px;
  padding: 5px 20px 0;
  font-size: 14px;
  display: flex;
}

.editmode .tab-bar .tab {
  color: #5f6a78;
  cursor: pointer;
  height: 35px;
  opacity: .5;
  background: #fff;
  border: 1px solid #ced4da;
  border-bottom: 0 #fff;
  border-radius: 6px 6px 0 0;
  align-items: center;
  gap: 5px;
  padding: 0 15px;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
  transform: translateY(4px);
}

.editmode .tab-bar .tab span {
  height: 18px;
  line-height: 18px;
}

.editmode .tab-bar .tab svg {
  height: 18px;
  width: 18px;
}

.editmode .tab-bar .tab:hover {
  height: 37px;
  opacity: .8;
  transform: translateY(2px);
}

.editmode .tab-bar .tab.active-tab {
  height: 40px;
  opacity: 1;
  border: 1px solid #ced4da;
  border-bottom-color: #fff;
  transform: translateY(0);
}

.editmode .editmode-screen-name-edit {
  align-items: center;
  display: flex;
}

.editmode .editmode-screen-name-edit .editbutton {
  cursor: pointer;
  height: 21px;
  width: 21px;
  margin-left: 10px;
}

.editmode .icon-spin {
  -webkit-filter: blur();
  animation: 2s linear infinite editmode-spin;
  line-height: 0 !important;
}

.editmode .rdw-link-modal {
  height: auto;
}

.editmode .rdw-link-modal .rdw-link-modal-btn {
  font-family: open_sans_editor, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 15px;
}

@keyframes editmode-spin {
  to {
    -moz-animation: rotate(1turn);
    transform: rotate(1turn);
  }
}

.appsettings .edit-mode-config-editor {
  height: calc(80vh - 35px);
  flex-direction: column;
  display: flex;
}

.appsettings .edit-mode-config-editor .edit-mode-yaml-form, .appsettings .edit-mode-config-editor .edit-mode-yaml-form.permissions-form {
  flex: 1;
}

.appsettings .edit-mode-config-editor .custom-scrollbars.settings {
  max-height: calc(80vh - 139px);
}

.appsettings .edit-mode-config-editor .custom-scrollbars.principals {
  max-height: calc(80vh - 228px);
}

.chart-block.invalid-data-config > p {
  margin: auto;
  padding: 20px;
}

.chart-block-table .livetable tbody tr:not(.active):hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.chart-block-table tbody tr.active {
  background-color: #ddd;
  transition: all .2s ease-in-out;
}

.chart-block-table tbody tr.active .item-title {
  cursor: pointer;
  font-weight: 700;
}

.chart-block > .graph {
  overflow: visible !important;
}

.chart-block > .graph > div {
  position: absolute;
}

.description-block {
  align-items: center;
  display: flex;
}

.description-block .inner {
  flex-grow: 1;
  padding: 20px;
}

.description-block .inner.align-left {
  text-align: left;
}

.description-block .inner.align-right {
  text-align: right;
}

.description-block .inner.align-center {
  text-align: center;
}

.description-block .inner a, .description-block .inner a:link, .description-block .inner a:visited {
  color: #add8e6;
}

.description-block .inner a:hover, .description-block .inner a:link:hover, .description-block .inner a:visited:hover {
  color: #96cddf;
}

.description-block .inner a:active, .description-block .inner a:link:active, .description-block .inner a:visited:active {
  color: #7ec2d8;
}

.image-block {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.image-block .image, .image-block .image-backdrop {
  position: absolute;
}

.image-block .image-backdrop {
  background-position: 50%;
}

@media (max-height: 700px) and (orientation: landscape), (max-height: 768px) and (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (max-height: 1024px) and (orientation: portrait), (max-width: 850px) and (orientation: portrait) {
  .image-block .image {
    position: relative;
  }
}

.image-block .image {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
}

.image-block .image img {
  pointer-events: none;
}

.image-block .image img.size-cover {
  height: 100%;
  object-fit: cover;
  width: 100%;
  background-size: cover;
}

.image-block .image img.size-contains {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

@media (max-height: 700px) and (orientation: landscape), (max-height: 768px) and (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (max-height: 1024px) and (orientation: portrait), (max-width: 850px) and (orientation: portrait) {
  .image-block .image img.size-contains {
    height: auto;
    width: 100%;
  }
}

.image-block .image img.size-vertical-stretch {
  height: 100%;
  width: auto;
}

@media (max-height: 700px) and (orientation: landscape), (max-height: 768px) and (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (max-height: 1024px) and (orientation: portrait), (max-width: 850px) and (orientation: portrait) {
  .image-block .image img.size-vertical-stretch {
    height: auto;
    width: 100%;
  }
}

.image-block .image img.size-horizontal-stretch {
  height: auto;
  width: 100%;
}

.image-block .image img.size-auto {
  background-size: initial;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.image-block .image-size-cover {
  background-size: cover;
}

.image-block .image-size-contain {
  background-size: contain;
}

.image-block .image-size-auto {
  background-size: initial;
}

.image-block .image-stretch-horizontal {
  background-size: 100%;
}

.image-block .image-stretch-vertical {
  background-size: auto 100%;
}

.image-block .image-align-left-top {
  background-position: 0 0;
}

.image-block .image-align-left-center {
  background-position: 0;
}

.image-block .image-align-left-bottom {
  background-position: 0 100%;
}

.image-block .image-align-right-top {
  background-position: 100% 0;
}

.image-block .image-align-right-center {
  background-position: 100%;
}

.image-block .image-align-center-top {
  background-position: top;
}

.image-block .image-align-center {
  background-position: 50%;
}

.image-block .image-align-center-bottom {
  background-position: bottom;
}

.image-block .image-backdrop {
  filter: blur(50px);
  height: calc(100% + 100px);
  opacity: .8;
  width: calc(100% + 100px);
  background-size: cover;
  inset: -50px;
}

.image-block .description {
  padding: 2px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.image-block .description.align-left {
  left: 0;
  right: auto;
}

.image-block .description.align-right {
  left: auto;
  right: 0;
}

.image-block .description:empty {
  display: none;
}

.text-block {
  height: 100%;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}

.text-block h1, .text-block h2, .text-block h3, .text-block h4, .text-block h5 {
  color: #00b0ff;
}

.text-block .horizontal-line {
  height: 0;
  width: 100px;
  border-bottom: 1px solid #00b0ff;
  margin: 30px 0;
}

.text-block .text-title h1 {
  margin: 0;
  font-size: clamp(20px, 3vw, 34px);
  line-height: 1.2;
}

.text-block .text {
  margin-bottom: 15px;
}

.text-block .text a, .text-block .text a:link, .text-block .text a:visited {
  color: #00b0ff;
}

.text-block .text a:hover, .text-block .text a:link:hover, .text-block .text a:visited:hover {
  color: #009be0;
}

.text-block .text a:active, .text-block .text a:link:active, .text-block .text a:visited:active {
  color: #0086c2;
}

.text-block .custom-scrollbars {
  height: 100%;
}

.text-block .inner {
  padding: clamp(20px, 4vw, 45px) clamp(20px, 4vw, 60px);
}

.map-block .geoapps-popup .popup-box .popup-header {
  background-color: var(--accentColor);
}

.map-block .geoapps-popup .popup-box .popup-tabs {
  background-color: var(--accentColor-lighter);
}

.map-block .geoapps-popup .popup-box .popup-tabs.active {
  background-color: #fff;
}

.map-block .geoapps-popup .popup-box .popup-content .image {
  width: 100%;
}

.map-block .geoapps-popup .popup-box .popup-content .image img {
  width: 100%;
  display: block;
}

.map-block .geoapps-popup .popup-arrow .skewed {
  background-color: var(--accentColor);
}

.information {
  padding: 2px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.information.align-left {
  left: 0;
  right: auto;
}

.information.align-right {
  left: auto;
  right: 0;
}

.information:empty {
  display: none;
}

:root {
  --accentColor: #00b0ff;
  --accentColor-dark: #009ee6;
  --accentColor-darker: #008dcc;
  --accentColor-darkest: #007bb3;
  --accentColor-light: #1ab8ff;
  --accentColor-lighter: #33c0ff;
  --accentColor-lightest: #4dc8ff;
}

.searchbar {
  pointer-events: none;
  width: 250px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.searchbar-input {
  border: 1px solid var(--accentColor);
  height: 40px;
  pointer-events: all;
  width: 250px;
  background: none;
  outline: 0;
  padding: 5px 10px 5px 35px;
  transition: all .2s ease-in-out;
}

.searchbar-input:hover {
  border-color: var(--accentColor-dark);
}

.searchbar-input:focus {
  border-color: var(--accentColor-darker);
  outline: none;
}

.searchbar-input::-webkit-search-cancel-button {
  cursor: pointer;
}

.searchbar-input-wrap {
  background: #fff;
  position: relative;
}

.searchbar-input-wrap:after, .searchbar-input-wrap:before {
  content: "";
  pointer-events: auto;
  transition: all .2s ease-in-out;
  position: absolute;
}

.searchbar-input-wrap:before {
  border: 2px solid var(--accentColor);
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  background: none;
  border-radius: 100%;
  top: 12px;
  left: 9px;
}

.searchbar-input-wrap:after {
  background: var(--accentColor);
  height: 2px;
  transform-origin: 0 0;
  width: 8px;
  top: 23px;
  left: 20px;
  transform: rotate(45deg);
}

.searchbar-input-wrap:hover:before {
  border-color: var(--accentColor-dark);
}

.searchbar-input-wrap:hover:after {
  background: var(--accentColor-dark);
}

.searchbar-input-wrap:focus-within:before {
  border-color: var(--accentColor-darker);
}

.searchbar-input-wrap:focus-within:after {
  background: var(--accentColor-darker);
}

.searchbar-list {
  pointer-events: all;
  background: #ffffffe6;
  margin: 0;
  padding: 0;
  list-style: none;
}

.searchbar-list .list-option {
  cursor: pointer;
  padding: 5px 10px;
  transition: all .2s ease-in-out;
}

.searchbar-list .list-option:hover {
  background-color: #e2e2e2;
}

.searchbar-list .list-option.selected {
  background-color: var(--accentColor-lightest);
}

.searchbar-position .topleft {
  inset: 5px auto auto 5px;
}

.searchbar-position .topright {
  inset: 5px 55px auto auto;
}

.searchbar-position .bottomleft {
  inset: auto auto 5px 5px;
}

.searchbar-position .bottomright {
  inset: auto 5px 5px auto;
}

.editmode .searchbar-position .bottomleft {
  bottom: 50px;
}

.youtube-block {
  height: 100%;
  width: 100%;
}

.youtube-block iframe {
  height: inherit;
  width: inherit;
}

.block-form-field-button button.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.block-form-field-simple-draw-question {
  flex-direction: unset;
  display: block;
}

.simple-draw-question-modal {
  height: 80vh;
  width: 65vw;
}

.simple-draw-question-modal .bottom-bar {
  width: 100%;
  z-index: 999;
  background-color: #ffffffb3;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.simple-draw-question-modal .bottom-bar .submit-btn {
  margin-left: auto;
}

.simple-draw-question-modal .remaining-draws {
  margin: 0 15px 0 0;
}

.carousel-root {
  height: 100%;
  width: 100%;
}

.carousel .slider, .carousel .slider .slide, .carousel-slider {
  height: 100%;
}

.carousel .horizontal-slide {
  height: 100%;
  display: flex;
  overflow: visible;
  transform: translate(-100%);
}

.carousel .horizontal-slide.slide-effect {
  transition: transform .3s;
}

.carousel .horizontal-slide.slide-effect.slide-next-effect {
  transform: translate(-200%);
}

.carousel .horizontal-slide.slide-effect.slide-prev-effect {
  transform: translate(0);
}

.carousel .template {
  min-width: 100%;
}

.screen {
  height: 100%;
}

/*# sourceMappingURL=index.88350f95.css.map */
